import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card } from "antd";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import tree from "../../assets/SCO_asset/ebill-buttons/tree.png"
import Tick from "../../assets/SCO_asset/ebill-buttons/tick.svg";
import _ from "lodash";
import Email from './Images/emailAlert.png'
import MobileText from './Images/msgAlert.png'
import EmailMobileText from './Images/emailSMS.png'
import PrintReceipt from './Images/preview.png'
import { useTranslation } from 'react-i18next';
import { dataSync } from "../websocket/dataSync";

const BillMode = () => {
  const navigate = useNavigate();
  const selectedValue = "";
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual")
  let customerFlag = localStorage.getItem("customerSignupFlag");
  let customerEmail = localStorage.getItem("email");
  let customerPhoneNo = localStorage.getItem("phoneNo");
  let email;
  let mobile;

  let userData =  JSON.parse(localStorage.getItem('userData'));
  if (userData !== undefined) {
    if (userData !== null) {
      email = userData.result.Email !== undefined ? userData.result.Email : customerEmail;
      mobile =  userData.result.Mobile !== undefined ?  userData.result.Mobile : customerPhoneNo;
    }
  }

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  useEffect(() => {
    let key = localStorage.getItem("dynamoDB");
    if (key === "true") {
      console.log('value:', key);
      dataSync();
    };
    const handleCustomEvent = (event) => {
      if (event.detail.key === 'dynamoDB') {
        const dynamoDBValue = JSON.parse(event.detail.newValue);
        console.log('Parsed value:', dynamoDBValue);
        if (dynamoDBValue) {
          dataSync();
        }
      }
    };
    window.addEventListener('customStorageChange', handleCustomEvent);
    return () => {
      window.removeEventListener('customStorageChange', handleCustomEvent);
    };
  }, []);

  const billModesList = [
    {
      title: "Email",
      img: Email,
      flag: navigator.onLine ? customerFlag === "false" ? (email !== "" && email !== undefined && email !== null) ? "Y" : "N" : customerEmail !== "" ? "Y" : "N" : "N"
    },
    {
      title: "SMS",
      img: MobileText,
      flag: navigator.onLine ? customerFlag === "false" ? (mobile !== "" && mobile !== undefined && mobile !== null) ? "Y" : "N" : customerPhoneNo !== "" ? "Y" : "N" : "N"
    },
    {
      title: "Both Email and SMS",
      img: EmailMobileText,
      flag: customerFlag === "false" ? (mobile !== "" && mobile !== undefined && mobile !== null) && (email !== "" && email !== undefined && email !== null) ? "Y" : "N" : customerPhoneNo !== "" && customerEmail !== "" ? "Y" : "N"
    },
    {
      title: "Printed Receipt",
      img: PrintReceipt,
      flag: "Y"
    }
  ];

  const handleBillModeSelection = (value, cardIndex, item) => {
    const selectedBillModeIndex = _.findIndex(billModesList, ["title", value]);
    if (selectedBillModeIndex === cardIndex && item.flag === "Y") {
      localStorage.setItem("billMode", value)
      navigate("/feedback-steptwo");
    }
  }

  return (
    <div>
      <Row align='middle' style={{ backgroundColor: "#0072AF", padding: "5px 50px 5px 50px", height: "10vh" }} >
        <Col span={6}>
          <img src={Logo} alt="Logo" style={{ height: "50px" }} />
        </Col>
        <Col offset={14} span={4} >
        </Col>
      </Row>
      <div className="payment-method-block">
       <Row gutter={[0,16]}>
       <Col span={24} className="where-would" style={{ fontSize: "25px", fontFamily: "Roboto Condensed", fontWeight: 600,  }}>
          How would you like to receive your bill?
        </Col>
        {multilingual === "true" && (
          <Col span={24} className="where-would" style={{ fontSize: "25px", fontFamily: "Roboto Condensed", fontWeight: 600, }}>{t('how would you like to receive your bill?')}</Col>
        )}
       </Row>
        <Row justify="space-between" style={{ marginTop: "9vh" }} >
          <Col span={2} />
          <Col
            span={6}
            style={{ cursor: billModesList[0].flag === "Y" ? "pointer" : "not-allowed", display: "flex", alignItems: "center", justifyContent: "center", }}
            onClick={() => handleBillModeSelection(billModesList[0].title, 0, billModesList[0])}
          >
            <div style={{ padding: "8px", border: "2px dashed", borderColor: "green", borderRadius: "15px", position: "absolute", left: "-6%" }}>
              <div style={{ width: "20vw" }}>
                <img
                  src={Tick}
                  alt=""
                  className="tick-icon"
                  style={{ visibility: selectedValue === billModesList[0].title ? "visible" : "hidden", bottom: window.innerWidth <= 1024 && window.innerHeight <= 768 ? "192px" : "205px", left: window.innerWidth <= 1024 && window.innerHeight <= 768 ? "180px" : "170px" }}
                />
                <Card
                  className={
                    selectedValue === billModesList[0].title ? "billmode-card-active" : "billmode-card"
                  }
                >
                  <div className="billmode-img-div">
                    <img src={billModesList[0].img} alt="" className="bill-mode-img" />
                  </div>
                  <span className="bill-mode-text">{billModesList[0].title}</span>
                  {multilingual === "true" && (
                    <span className="bill-mode-text" style={{ marginTop: "0.3em", display: "block" }}>{t('email')}</span>
                  )}
                </Card>
              </div>
            </div>
          </Col>
          <Col
            span={6}
            style={{ cursor: billModesList[1].flag === "Y" ? "pointer" : "not-allowed", display: "flex", alignItems: "center", justifyContent: "center", }}
            onClick={() => handleBillModeSelection(billModesList[1].title, 1, billModesList[1])}
          >
            <div style={{ padding: "8px", border: "2px dashed", borderColor: "green", borderRadius: "15px" }}>
              <div style={{ width: "20vw" }}>
                <img
                  src={Tick}
                  alt=""
                  className="tick-icon"
                  style={{ visibility: selectedValue === billModesList[1].title ? "visible" : "hidden", bottom: window.innerWidth <= 1024 && window.innerHeight <= 768 ? "192px" : "205px", left: window.innerWidth <= 1024 && window.innerHeight <= 768 ? "180px" : "170px" }}
                />
                <Card
                  className={
                    selectedValue === billModesList[1].title ? "billmode-card-active" : "billmode-card"
                  }
                >
                  <div className="billmode-img-div">
                    <img src={billModesList[1].img} alt="" className="bill-mode-img" />
                  </div>
                  <span className="bill-mode-text">{billModesList[1].title}</span>
                  {multilingual === "true" && (
                    <span className="bill-mode-text" style={{ marginTop: "0.3em", display: "block" }}>{t('mobile-text')}</span>
                  )}
                </Card>
              </div>
            </div>
          </Col>
          <Col
            span={6}
            style={{ cursor: billModesList[3].flag === "Y" ? "pointer" : "not-allowed", display: "flex", alignItems: "center", justifyContent: "center", }}
            onClick={() => handleBillModeSelection(billModesList[3].title, 3, billModesList[3])}
          >
            <div style={{ width: "20vw", position: "absolute", right: "-6%" }}>
              <img
                src={Tick}
                alt=""
                className="tick-icon"
                style={{ visibility: selectedValue === billModesList[3].title ? "visible" : "hidden", bottom: window.innerWidth <= 1024 && window.innerHeight <= 768 ? "192px" : "205px", left: window.innerWidth <= 1024 && window.innerHeight <= 768 ? "180px" : "170px" }}
              />
              <Card
                className={
                  selectedValue === billModesList[3].title ? "billmode-card-active" : "billmode-card"
                }
              >
                <div className="billmode-img-div">
                  <img src={billModesList[3].img} alt="" className="bill-mode-img" />
                </div>
                <span className="bill-mode-text">{billModesList[3].title}</span>
                {multilingual === "true" && (
                  <span className="bill-mode-text" style={{ marginTop: "0.3em", display: "block" }}>{t('printed-receipt')}</span>
                )}
              </Card>
            </div>
          </Col>
          <Col span={2} />
        </Row>
        <Row>
          <Col span={4} offset={3}>
            <img src={tree} style={{ height: "55px", marginTop: "10px", float: "right", marginRight: "14px" }} />
          </Col>
          <Col span={15}>
            <p style={{ alignSelf: "center", float: "left", fontSize: "3.3vh", color: "green", marginTop: "24px" }}>
              Save Tree Save Planet
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BillMode;