import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Spin, Modal, message } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import { LoadingOutlined } from "@ant-design/icons";
import "../../antd.css";
import "../styles.css";
import Logo1 from "../../assets/SCO_asset/home/decathlon-logo.png";
import Axios from "axios";
import moment from "moment";
import URL from "../../app/server.json";
import backgroungImage from "../../assets/SCO_asset/signup/backgroundImage.png";
import email from "../../assets/SCO_asset/login-gif/email.png"
import mobile from "../../assets/SCO_asset/login-gif/mobile.png"
import EmailComponent from "./emailComponent";
import MobileComponent from "./mobileComponent";
import { useTranslation } from 'react-i18next';
import { dataSync } from "../websocket/dataSync";

const CustomerLogin = () => {
  const keyboard = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [layoutName, setLayoutName] = useState("shift");
  const [value, setValue] = useState(0);
  const [input, setInput] = useState(0);
  const [routeFlag, setRouteFlag] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mobileError, setMobileError] = useState({ flag: false, msg: "" });
  const [emailError, setEmailError] = useState({ flag: false, msg: "" });
  const [cursorPosition, setCursorPosition] = useState(null);
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual")
  const apiKey = URL.GRAVITEE_API_KEY;

  useEffect(() => {
    localStorage.removeItem("orderObj");
    localStorage.removeItem("giftCardPayment");
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  useEffect(() => {
    let key = localStorage.getItem("dynamoDB");
    if (key === "true") {
      console.log('value:', key);
      dataSync();
    };
    const handleCustomEvent = (event) => {
      if (event.detail.key === 'dynamoDB') {
        const dynamoDBValue = JSON.parse(event.detail.newValue);
        console.log('Parsed value:', dynamoDBValue);
        if (dynamoDBValue) {
          dataSync();
        }
      }
    };
    window.addEventListener('customStorageChange', handleCustomEvent);
    return () => {
      window.removeEventListener('customStorageChange', handleCustomEvent);
    };
  }, []);

  const handleLogin = async () => {
    localStorage.setItem("customerSignupFlag", false);
    if (input !== "") {
      let phno = value === 1 ? input : "";
      let email = value === 2 ? input : ""
      let emailDecode = email !== null ? window.btoa(email) : "";
      let phonDecode = phno !== null ? window.btoa(phno) : "";
      let tempEmail = !email || /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)?\.[a-zA-Z]{2,}$/.test(email)
      if (phno.length === 10 && /^[6789]/.test(phno) || tempEmail && email!=="") {
        setLoading({ status: true, message: "" });
        let headers = {
          "Content-Type": "application/json",
        }
        headers[URL.key_NAME] = apiKey;
        Axios({
          url: `${URL.GRAVITEE_BASE_URL}/customerLogin`,
          method: "GET",
          headers: headers,
          params: {
            remainingUrl: `?appCode=SCO&email=${emailDecode}&mobile=${phonDecode}`
          }
        }).then(async (response) => {
          if (response.data.statusCode === 200) {
            setLoading({ status: false, message: "" });
            let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
            selectedProducts = selectedProducts === null ? {} : selectedProducts;
            selectedProducts.productsData = [];
            localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
            let customerData = { phoneNo: phno, email: email }
            if (response.data.data.result.message === "Customer details are not found!!!") {
              localStorage.setItem('customerData', JSON.stringify(customerData));
              navigate("/customer-signup");
            } else {
              let currentDate = moment(new Date()).format("YYYY-MM-DD");
              let currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
              selectedProducts.customerData = response.data.data;
              localStorage.setItem('userData', JSON.stringify(response.data.data));
              localStorage.setItem('surname', response.data.data.result.Firstname);
              localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
              localStorage.setItem('customerData', JSON.stringify(customerData));
              localStorage.setItem('orderDate', currentDate);
              localStorage.setItem('salebegintime', currentDateTime);
              navigate("/home");
            }
          } else {
            setLoading({ status: false, message: "" });
          }
        }).catch((error) => {
          if (error.response.status !== 200 || error.message === "Network Error") {
            let customerData = { phoneNo: phno, email: email }
            localStorage.setItem('customerData', JSON.stringify(customerData));
            navigate("/customer-signup");
          }
        })
      } else {
        if (value === 1) {
          setLoading({ status: false, message: "" });
          console.log("Number should not be less than 10 digits");
          if(mobileError.flag === false){
            if (phno.length !== 10) {
              setMobileError({ flag: true, msg: "Number should not be less than 10 digits" });
            }
            else if (!/^[6789]/.test(phno)) {
              setMobileError({ flag: true, msg: "Mobile Number Should Start with 6/7/8/9" });
            }
           }
        } else {
          setLoading({ status: false, message: "" });
          setEmailError({ flag: true, msg: "Please enter valid email" });
          console.log("Email contains invalid characters");
        }
        setLoading({ status: false, message: "" });
      }
    } else {
      console.log("Please input your phone number or email");
      setMobileError({ flag: true, msg: "Please input your phone number or email" });
      setEmailError({ flag: true, msg: "Please input your phone number or email" });
      setLoading({ status: false, message: "" });
    };
  };

  const onChange = (inp) => {
    setInput(inp);
  };

  const onKeyPressNumber = (button) => {
    if (button === "{bksp}") {
      if (cursorPosition !== 0) {
        setInput(input.slice(0, -1));
        setCursorPosition(cursorPosition - 1)
      }
      else {
        setInput(input)
      }
    }
    else {
      let value = `${input}${button}`
      if (value.length <= 10 && /^[6789]/.test(value)) {
        setMobileError({ flag: false, msg: "" });
        setInput(value.replace(/\D/g, ""));
      } else if (!/^[6789]/.test(value) && value === 0) {
        setInput("");
        setMobileError({ flag: false, msg: "" });
      } else if (input.length < 1) {
        setMobileError({ flag: true, msg: "Mobile Number Should Start with 6/7/8/9" });
      } else {
        setMobileError({ flag: true, msg: "Number should not be great than 10 digits" });
      }
    }
  };
  const onClickEmailInput = (e) => {
    const newCursorPosition = e.target.selectionStart;
    setCursorPosition(newCursorPosition)
  }
  const onClickMobileInput = (e) => {
    const newCursorPosition = e.target.selectionStart;
    setCursorPosition(newCursorPosition)
  }

  const onKeyPress = (button) => {
    setEmailError({ flag: false, msg: "" });
    if (button === "{backspace}") {
      if (cursorPosition !== 0) {
        setInput(input.slice(0, -1));
        setCursorPosition(cursorPosition - 1)
      }
    };
    if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" & button !== "{tab}" && button !== "{enter}" && button !== "{space}") {
      let text = `${input}${button}`
      if (input.includes(".com")) {
        let tempEmail = input.split(".com");
        text = `${tempEmail[0]}.com`
      }
      setInput(text.trim());
    }
    if (button === "{number}" || button === "{small}") handleNumber();
    if (button === "{shift}") setInput(input);
    if (button === "{back}") setInput(input);
    if (button === "{space}") setInput(input + ' ');
    if (button === "{mic}") setInput(input)
  };

  const handleNumber = () => {
    const layout1 = layoutName;
    setLayoutName(layout1 === "shift" ? "number" : "shift");
    setInput(input)
  };

  const onChangeRadio = (e) => {
    setValue(e);
    setInput("");
  };

  const handleInputChange = event => {
    const inp = event.target.value;
    if (value === 1) {
      if (event.target.value.length <= 10 && /^[6789]/.test(event.target.value)) {
        setInput(inp.replace(/\D/g, ""));
        setMobileError({ flag: false, msg: "" });
      } else if (!/^[6789]/.test(event.target.value) && event.target.value.length === 0) {
        setMobileError({ flag: false, msg: "" });
        setInput("");
      } else if (inp < 1) {
        message.error("Mobile Number Should Start with 6/7/8/9");
        setMobileError({ flag: true, msg: "Mobile Number Should Start with 6/7/8/9" });
      } else {
        setMobileError({ flag: true, msg: "Number should not be great than 10 digits" });
      }
    } else if (value === 2) {
      setInput(inp);
      keyboard.current.setInput(inp);
    }
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  const sectionStyle = {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${backgroungImage})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "fixed",
  }

  return (
    <div>
      <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
        {value === 0 ?
          <div style={sectionStyle}>
            <Row >
              <Col span={24}
              >
                <div >
                  <img className="cl-logo" src={Logo1} alt="" style={{ position: "relative", top: "25px", left: "25px", width: "20%" }} onDoubleClick={() => { setRouteFlag(true); }} />
                </div>
              </Col>
              <Col span={2}></Col>
              <Col span={20} style={{ marginTop: multilingual === "true" ? "1vh" : "10vh", }}>
                {multilingual === "true" && (
                  <p className="cl-heading" style={{ marginBottom: "2vh" }} >{t('express checkout | welcome')}</p>
                )}
                <p className="cl-heading" >EXPRESS CHECKOUT</p>
                {multilingual === "true" && (
                  <p className="cl-sub-heading">{t('please enter your details to sign-in and proceed with your order')}</p>
                )}
                <p className="cl-sub-heading">Please enter your details to sign-in and proceed with your order</p>
              </Col>
              <Col span={2}></Col>
              <Col xl={5} span={4}></Col>
              <Col xl={14} span={16} style={{ paddingTop: "15vh", display: "flex", justifyContent: "center" }}>
                <div className="cl-mobile-icon" onClick={() => onChangeRadio(1)}>
                  <img src={mobile} alt="mobile" style={{ marginBottom: "2vh" }} />
                  {multilingual === "true" && (
                    <div style={{ fontSize: "20px" }}>{t('mobile number')}</div>
                  )}
                  <div style={{ fontSize: "20px", marginBottom: "0.3rem" }}>Mobile Number</div>
                </div>
                <div className="cl-or-txt">
                  <p style={{ fontSize: "27px", fontFamily: "Roboto Condensed", fontWeight: "500", color: "white" }}>Or</p>
                </div>
                <div className="cl-email-icon" onClick={() => onChangeRadio(2)}>
                  <img src={email} alt="email" style={{ marginBottom: "2vh" }} />
                  {multilingual === "true" && (
                    <div style={{ fontSize: "20px" }}>{t('email address')}</div>
                  )}
                  <div style={{ fontSize: "20px", marginBottom: "0.3rem" }}>Email Address</div>
                </div>
              </Col>
              <Col span={4}></Col>
            </Row>
          </div>
          :
          value === 1 ?
            <MobileComponent keyboardRef={keyboard} setMobileError={setMobileError} onKeyPressNumber={onKeyPressNumber} handleLogin={handleLogin} input={input} setInput={setInput} onClickMobileInput={onClickMobileInput} handleInputChange={handleInputChange} setIsModalOpen={setIsModalOpen} mobileError={mobileError} />
            :
            value === 2 ?
              <EmailComponent layoutName={layoutName} onChange={onChange} onClickEmailInput={onClickEmailInput} onKeyPress={onKeyPress} handleLogin={handleLogin} input={input} setInput={setInput} handleInputChange={handleInputChange} setIsModalOpen={setIsModalOpen} emailError={emailError} />
              :
              null
        }
      </Spin>
      <Modal okText="Yes" cancelText="No"  open={routeFlag} onOk={()=>{navigate("/admin-login")}} onCancel={()=>{setRouteFlag(false);}}>
      <p style={{ fontWeight: 600, fontSize: "3vh",paddingTop:"1em" }}>Are you sure you want to cancel the transaction ?</p>
      </Modal>
      <Modal okText="Yes" cancelText="No" open={isModalOpen} onOk={() => { setIsModalOpen(false); setValue(0); setMobileError({ flag: false, msg: "" }) }} onCancel={() => setIsModalOpen(false)}>
        <p style={{ fontWeight: 600, fontSize: "3vh" }}>Do you want to go back</p>
      </Modal>
    </div>
  );
};

export default CustomerLogin;