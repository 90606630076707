import Dexie from "dexie";

const db = new Dexie("sco");
db.version(1)
  .stores({
    productData: "++id, ean",
    comboData: "OfferId",
    multiBuyData: "OfferId",
    tillData: "++id",
    cottonData: "++id",
    paperData: "++id",
    nonRfidData: "++id",
    incrementalData: "++id",
    selectedData: "++id",
    syncData: "++id",
    tempData: "++id"
  })
  .upgrade((tx) => {
    tx.table("productData").createIndex("uniqueEan", "ean", { unique: true });
  });

export default db;