import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import telTranslations from './locales/telugu.json';
import hinTranslations from './locales/hindi.json';
import kannadaTranslations from './locales/kannada.json';
import tamilTranslations from './locales/tamil.json';
import bengaliTranslations from './locales/bengali.json';
import malayalamTranslations from './locales/malayalam.json';

const languageSelected = localStorage.getItem("languageSelected");

i18n.use(initReactI18next).init({
    lng: languageSelected, // Provide the language code as a string
    fallbackLng: languageSelected,
    resources: {
        Telugu: { translation: telTranslations },
        Hindi: { translation: hinTranslations },
        Kannada: { translation: kannadaTranslations },
        Tamil: { translation: tamilTranslations },
        Bengali: { translation: bengaliTranslations },
        Malayalam: { translation: malayalamTranslations }
    },
});

export default i18n;
