import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/app-router';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker
      .register('/sw.js')
      .then(() => {
      })
      .catch(() => {
      });
  });
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);