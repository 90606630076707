import React, { useEffect } from "react";
import { Row, Col, Button } from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "../../antd.css";
import "../styles.css";
import "./email.css"
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import mobile1 from "../../assets/SCO_asset/login-gif/email1.png"
import ellipse from "../../assets/SCO_asset/login-gif/Ellipse.png"
import { useTranslation } from 'react-i18next';

const EmailComponent = ({ handleLogin, input, setInput, onClickEmailInput, handleInputChange, layoutName, onKeyPress, setIsModalOpen, emailError }) => {
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual")

  useEffect(() => {
    localStorage.removeItem('pincodeNo');
    localStorage.removeItem("email");
    localStorage.removeItem("phoneNo");
    localStorage.removeItem("surname");
    localStorage.removeItem("userData"); 
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  return (
    <div>
      <Row align='middle' style={{ backgroundColor: "#0072AF", height: "10vh" }}>
        <Col span={6}>
          <img src={Logo} alt="Logo" style={{ position: "relative", left: "20%", height: "50px" }} />
        </Col>
        <Col span={12}>
        </Col>
        <Col span={6} >
          <div style={{ display: "flex", float: "right", alignItems: "center" }}>
            <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
            </span>
            <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
              <LeftCircleOutlined style={{ fontSize: "24px" }} onClick={() => setIsModalOpen(true)} />
              <span style={{ fontSize: "15px" }}>Back</span>
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={1} />
        <Col span={22} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ position: "relative", top: "8%" }}>
            <div style={{ position: "relative" }}>
              <img src={ellipse} className="email-icon-bg" />
              <img src={mobile1} alt="mobile" className="email-icon" />
            </div>
            <input inputMode="none" className="email-input" style={{ borderColor: emailError.flag === false ? "#0580BD" : "red" }} autoFocus={true} onPressEnter={handleLogin} value={input} onChange={handleInputChange} onClick={onClickEmailInput} />
            <div className="email-lable" style={{ color: emailError.flag === false ? "#A0A8B2" : "red" }}>
              Email Address
              {multilingual === "true" && (
                <>
                  <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                  <span style={{ marginLeft: "0.3rem" }}>{t('email address')}</span>
                </>              )}
            </div>
            <div style={{ height: "1vh", position: "relative", top: "13%", textAlign: "center", fontSize: "2.5vh", color: "red" }}>{emailError.flag === false ? "" : emailError.msg}</div>
          </div>
          <Button className="email-continue-btn" onClick={handleLogin} >
            CONTINUE
            {multilingual === "true" && (
              <>
                  <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                  <span style={{ marginLeft: "0.3rem" }}>{t('continue')}</span>
                </>            )}
          </Button>
          <div className="email-keyboard-div">
            <Keyboard
              buttonTheme={[
                {
                  class: "hg-yellow",
                  buttons: "{space}"
                },
                {
                  class: "hg-space",
                  buttons: "{spaces}"
                },
                {
                  class: "hg-mail",
                  buttons: "@yahoo.com @gmail.com"
                },
              ]}
              layoutName={layoutName}
              onChange={(val) => { setInput(val) }}
              onKeyPress={onKeyPress}
              theme={"hg-theme-default login"}
              display={{
                "{abc}": "ABC",
                "{smileys}": "\uD83D\uDE03",
                "{numbers}": "123",
                "{number}": "123",
                '{bksp}': 'backspace',
                "{backspace}": "⌫",
                "{shift}": "⇧",
                '{tab}': 'tab',
                '{lock}': 'lock',
                '{enter}': 'enter',
                '{space}': "Space",
                '{spaces}': "Space",
                "{caps}": "ABC",
                "{small}": "abc",
                "{back}": "Enter"
              }}
              layout={{
                shift: [
                  "q w e r t y u i o p",
                  "a s d f g h j k l",
                  "{shift} z x c v b n m {backspace}",
                  "{number} {space} @yahoo.com @gmail.com"
                ],
                number: [
                  "1 2 3 4 5 6 7 8 9 0",
                  '- / : ; ( ) $ & @ "',
                  '# . , _ ! ` {backspace}',
                  "{small} {space} {back}"
                ],
              }}
            />
          </div>
        </Col>
        <Col span={1} />
      </Row>
    </div>
  );
};

export default EmailComponent;