import React, { useEffect } from "react";
import { Row, Col, Button } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "../../antd.css";
import "../styles.css";
import "./mobile.css"
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import mobile1 from "../../assets/SCO_asset/login-gif/mobile1.png"
import ellipse from "../../assets/SCO_asset/login-gif/Ellipse.png"
import { LeftCircleOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const MobileComponent = ({ handleLogin, input, setInput, setMobileError, keyboardRef, onClickMobileInput, handleInputChange, onKeyPressNumber, setIsModalOpen, mobileError }) => {
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual")

  useEffect(() => {
    localStorage.removeItem('pincodeNo');
    localStorage.removeItem("email");
    localStorage.removeItem("phoneNo");
    localStorage.removeItem("surname");
    localStorage.removeItem("userData"); 
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  const onChangeNumber = (value) => {
    if (value.length > 10) {
      setMobileError({ flag: true, msg: "Number should not be great than 10 digits" });
      setInput(input)
      keyboardRef.current.setInput(input);
    }
    else if (!/^[6789]/.test(value) && value !== "") {
      setMobileError({ flag: true, msg: "Mobile Number Should Start with 6/7/8/9" });
      setInput(value)
      keyboardRef.current.setInput(value);
    }
    else {
      keyboardRef.current.setInput(value);
      setInput(value)
      setMobileError({ flag: false, msg: "" });
    }
  }

  return (
    <div>
      <Row align='middle' style={{ backgroundColor: "#0072AF", height: "10vh" }}>
        <Col span={6}>
          <img src={Logo} alt="Logo" style={{ position: "relative", left: "20%", height: "50px" }} />
        </Col>
        <Col span={12}>
        </Col>
        <Col span={6} >
          <div style={{ display: "flex", float: "right", alignItems: "center" }}>
            <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
            </span>
            <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
              <LeftCircleOutlined style={{ fontSize: "24px" }} onClick={() => setIsModalOpen(true)} />
              <span style={{ fontSize: "15px" }}>Back</span>
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={1} />
        <Col span={11} className="mc-main-div">
          <div style={{ position: "relative" }}>
            <div className="mc-left-div">
              <img src={ellipse} className="email-icon-bg" />
              <img src={mobile1} alt="mobile" className="email-icon" />
            </div>
            <input inputMode="none" className="mc-input-field" style={{ borderColor: mobileError.flag === false ? "#0580BD" : "red" }} autoFocus={true} onPressEnter={handleLogin} value={input} onChange={handleInputChange} onClick={onClickMobileInput} />
            <div className="mc-input-lable" style={{ color: mobileError.flag === false ? "#A0A8B2" : "red" }}>
              Mobile Number
              {multilingual === "true" && (
                <>
                  <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                  <span style={{ marginLeft: "0.3rem" }}>{t('mobile number')}</span>
                </>              )}
            </div>
            <div style={{ height: "1vh", fontSize: "2.5vh", color: "red" }}>{mobileError.flag === false ? "" : mobileError.msg}</div>
          </div>
        </Col>
        <Col span={11} className="mc-kb-col">
          <div className="mc-kb-div">
            <Keyboard
              keyboardRef={(r) => (keyboardRef.current = r)}
              onChange={onChangeNumber}
              theme={"hg-theme-default mobile"}
              buttonTheme={[
                {
                  class: "hg-bksp",
                  buttons: "{bksp}"
                },
              ]}
              layout={{
                default: [
                  "1 2 3",
                  "4 5 6",
                  "7 8 9",
                  " 0 {bksp}",
                ]
              }}
              display={{
                '{bksp}': "⌫"
              }} />
            <Button className="mc-continue-btn" onClick={handleLogin}>
              CONTINUE
              {multilingual === "true" && (
                <>
                  <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                  <span style={{ marginLeft: "0.3rem" }}>{t('continue')}</span>
                </>              )}
            </Button>
          </div>
        </Col>
        <Col span={1} />
      </Row>
    </div>
  );
}

export default MobileComponent