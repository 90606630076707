import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, } from "antd";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import SuccessGif from "../../assets/SCO_asset/payment/success.gif";
import { useTranslation } from 'react-i18next';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual")

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  useEffect(() => {
    setTimeout(() => {
      let ebill = localStorage.getItem("ebill");
      if (ebill === "true") {
        navigate("/bill-mode");
      } else {
        localStorage.setItem("billMode", "Printed Receipt");
        navigate("/feedback-steptwo");
      };
    }, 3000)
  }, []);

  return (
    <div style={{ background: "#f5f9fb", height: "100vh" }}>
      <Row align='middle' style={{ backgroundColor: "#0072AF", padding: "5px 50px 5px 50px", height: "10vh" }} >
        <Col span={6}>
          <img src={Logo} alt="Logo" style={{ height: "50px" }} />
        </Col>
        <Col offset={14} span={4} >
        </Col>
      </Row>
      <Row style={{ paddingTop: "1rem" }}>
        <Col span={24} className="payment-success-block">
          <img
            className="card-payment-img"
            src={SuccessGif}
            width="340"
            height="309"
            alt=""
          />
          <span className="payment-successfull" style={{ marginBottom: "0" }}>Payment Successful</span>
          {multilingual === "true" && (
            <span className="payment-successfull">{t('payment-success')}</span>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PaymentSuccess;
