import Axios from "axios";
import moment from "moment";
import URL from "../../app/server.json";

const scoLog = async (name) => {
  let obj = JSON.parse(localStorage.getItem("orderObj"));
  let orderId = localStorage.getItem("order_lastassignednum");
  let terminalId = localStorage.getItem("terminalNumber");
  const verify =(order) => {
    let taxDetails = order[0].taxDetails;
    for (let i =0 ; i < taxDetails.length ; i++){
      if(Number(taxDetails[i].CGST) !== Number(taxDetails[i].TCGST)){
          taxDetails[i].verify = false;
      }
          else if(Number(taxDetails[i].SGST) !== Number(taxDetails[i].TSGST)){
                  taxDetails[i].verify = false;
          }
          else {
              taxDetails[i].verify = true;
          }
      }
      taxDetails.forEach(ele => {
        if(!ele.verify){
          let chk=false;
          let reAdjustment = true;
         let taxCgstamt=Number(ele.CGST.toFixed(2));
         let taxSgstamt=Number(ele.SGST.toFixed(2));
        let taxCatagoryInfo=ele.taxRate;
        let lineCgst=0;
        let lineSgst=0;
        let j=order[0].lines.length;
        let count=0;
                //swap func
                while(!chk){
                  let i=0;
                   let test=false; 
                   count++;
                   lineCgst=0;
                   lineSgst=0;
                 order[0].lines.forEach(element => {
                   i++;
                if(taxCatagoryInfo===element.taxRate ){
                  if(Number(element.ICGST)!==Number(element.ISGST) && !test && !element.swap){
                    let temp=Number(order[0].lines[i-1].ICGST);
                    order[0].lines[i-1].ICGST=Number(order[0].lines[i-1].ISGST);
                    order[0].lines[i-1].ISGST=temp;
                    order[0].lines[i-1].swap=true;
                    test=true;
                  }
                  lineCgst=(Number(lineCgst)+Number(element.ICGST)).toFixed(2);
                  lineSgst=(Number(lineSgst)+Number(element.ISGST)).toFixed(2);
                  
             }
              });
              if(Number(lineCgst)===Number(taxCgstamt) && count>=j ){
               chk=true;
             }
             else if(Number(lineSgst)===Number(taxSgstamt) && count>=j ){
                chk=true;
             }
             else if(count>=j){
               chk=true;
             }
             else{
               chk=false;
               test=false;
             }
             }
             //adjustment function
      lineCgst=Number(lineCgst);
      lineSgst=Number(lineSgst);
      if(lineCgst!==taxCgstamt || lineSgst!== taxSgstamt){
        let adjustment=false;
        let j=0;
        let cgstAdj=false;
        let sgstAdj=false;
        let adjstCgstAmt;
        let adjstSgstAmt;
        if(lineCgst!== taxCgstamt){
          cgstAdj=true;
           adjstCgstAmt=(Number(lineCgst)-Number(taxCgstamt)).toFixed(2);
        }
        else{
          adjstCgstAmt=0;
        }
        if(lineSgst!==taxSgstamt){
          sgstAdj=true;
          adjstSgstAmt=(Number(lineSgst)-Number(taxSgstamt)).toFixed(2);
        }
        else{
          adjstSgstAmt=0;
        }
        while(!adjustment){
          order[0].lines.forEach(adjele => {
            j++;     
         if(taxCatagoryInfo===adjele.taxRate && !adjustment){
          if(adjstCgstAmt!==0 || adjstSgstAmt!==0){
          if(Number(adjele.ICGST)!==Number(adjele.ISGST)) {
            if(cgstAdj){
              if(adjstCgstAmt>0){
             let temp=(Number(adjele.ICGST)-0.01).toFixed(2);
             order[0].lines[j-1].ICGST=temp;
             let netamt=(Number(adjele.netAmount)+0.01).toFixed(2);
             order[0].lines[j-1].netAmount=netamt;
              let taxAmt=(Number(adjele.taxableAmt)-0.01).toFixed(2);
              order[0].lines[j-1].taxableAmt=taxAmt;
              adjstCgstAmt=(Number(adjstCgstAmt)-0.01).toFixed(2);   
              adjstCgstAmt=Number(adjstCgstAmt);
              }
              else if(adjstCgstAmt<0){
                let temp=(Number(adjele.ICGST)+0.01).toFixed(2);
                order[0].lines[j-1].ICGST=temp;
                let netamt=(Number(adjele.netAmount)-0.01).toFixed(2);
                order[0].lines[j-1].netAmount=netamt;
                 let taxAmt=(Number(adjele.taxableAmt)+0.01).toFixed(2);
                 order[0].lines[j-1].taxableAmt=taxAmt;
                 adjstCgstAmt=(Number(adjstCgstAmt)+0.01).toFixed(2);
                 adjstCgstAmt=Number(adjstCgstAmt);
              }
             }           
             if(sgstAdj){
                if(adjstSgstAmt>0){
               let temp1=(Number(adjele.ISGST)-0.01).toFixed(2);
               order[0].lines[j-1].ISGST=temp1;
               let netamt=(Number(adjele.netAmount)+0.01).toFixed(2);
               order[0].lines[j-1].netAmount=netamt;
                let taxAmt=(Number(adjele.taxableAmt)-0.01).toFixed(2);
                order[0].lines[j-1].taxableAmt=taxAmt;
                adjstSgstAmt=(Number(adjstSgstAmt)-0.01).toFixed(2);
                adjstSgstAmt=Number(adjstSgstAmt);
                }
                else if(adjstSgstAmt<0){
                  let temp2=(Number(adjele.ISGST)+0.01).toFixed(2);
                  order[0].lines[j-1].ISGST=temp2;
                  let netamt=(Number(adjele.netAmount)-0.01).toFixed(2);
                  order[0].lines[j-1].netAmount=netamt;
                   let taxAmt=(Number(adjele.taxableAmt)+0.01).toFixed(2);
                   order[0].lines[j-1].taxableAmt=taxAmt;
                   adjstSgstAmt=(Number(adjstSgstAmt)+0.01).toFixed(2);
                   adjstSgstAmt=Number(adjstSgstAmt);
                }
             }
             if(adjstCgstAmt===0 && adjstSgstAmt===0){
              adjustment=true;
             }
            }
            if((j >= order[0].lines.length) && (adjstCgstAmt !==0 || adjstSgstAmt !==0 )){
              adjustment = true;
              reAdjustment = false;
             }
         }
        }
       });
        }
        while(!reAdjustment){
          j = 0;
          order[0].lines.forEach(adjele => {
            j++;  
            if(taxCatagoryInfo===adjele.taxRate && !reAdjustment){
              if(adjstCgstAmt!==0 || adjstSgstAmt!==0){
              if(Number(adjele.ICGST)===Number(adjele.ISGST)) {
                if(cgstAdj){
                  if(adjstCgstAmt>0){
                 let temp=(Number(adjele.ICGST)-0.01).toFixed(2);
                 order[0].lines[j-1].ICGST=temp;
                 let netamt=(Number(adjele.netAmount)+0.01).toFixed(2);
                 order[0].lines[j-1].netAmount=netamt;
                  let taxAmt=(Number(adjele.taxableAmt)-0.01).toFixed(2);
                  order[0].lines[j-1].taxableAmt=taxAmt;
                  adjstCgstAmt=(Number(adjstCgstAmt)-0.01).toFixed(2);   
                  adjstCgstAmt=Number(adjstCgstAmt);
                  }
                  else if(adjstCgstAmt<0){
                    let temp=(Number(adjele.ICGST)+0.01).toFixed(2);
                    order[0].lines[j-1].ICGST=temp;
                    let netamt=(Number(adjele.netAmount)-0.01).toFixed(2);
                    order[0].lines[j-1].netAmount=netamt;
                     let taxAmt=(Number(adjele.taxableAmt)+0.01).toFixed(2);
                     order[0].lines[j-1].taxableAmt=taxAmt;
                     adjstCgstAmt=(Number(adjstCgstAmt)+0.01).toFixed(2);
                     adjstCgstAmt=Number(adjstCgstAmt);
                  }
                 }           
                 if(sgstAdj){
                    if(adjstSgstAmt>0){
                   let temp1=(Number(adjele.ISGST)-0.01).toFixed(2);
                   order[0].lines[j-1].ISGST=temp1;
                   let netamt=(Number(adjele.netAmount)+0.01).toFixed(2);
                   order[0].lines[j-1].netAmount=netamt;
                    let taxAmt=(Number(adjele.taxableAmt)-0.01).toFixed(2);
                    order[0].lines[j-1].taxableAmt=taxAmt;
                    adjstSgstAmt=(Number(adjstSgstAmt)-0.01).toFixed(2);
                    adjstSgstAmt=Number(adjstSgstAmt);
                    }
                    else if(adjstSgstAmt<0){
                      let temp2=(Number(adjele.ISGST)+0.01).toFixed(2);
                      order[0].lines[j-1].ISGST=temp2;
                      let netamt=(Number(adjele.netAmount)-0.01).toFixed(2);
                      order[0].lines[j-1].netAmount=netamt;
                       let taxAmt=(Number(adjele.taxableAmt)+0.01).toFixed(2);
                       order[0].lines[j-1].taxableAmt=taxAmt;
                       adjstSgstAmt=(Number(adjstSgstAmt)+0.01).toFixed(2);
                       adjstSgstAmt=Number(adjstSgstAmt);
                    }
                 }
                 if(adjstCgstAmt===0 && adjstSgstAmt===0){
                  reAdjustment=true;
                 }
                }
    
             }
            }
       });
        }
      }
        }
      });
      return order[0]
  }
  await verify([obj]);
  
  Object.entries(obj.address[0]).forEach(([key, value]) => {
    obj.address[0][key] = value.replace(/[\!\$]/g, '');
  });

  let body = {
    terminalId: terminalId,
    orderNumber: `${terminalId}/${Number(orderId)}`,
    log: obj,
  };
  const config = {
    headers: {},
  };
  config.headers[URL.key_NAME] = URL.GRAVITEE_API_KEY;

  let params = {
    terminalId: terminalId,
    orderNumber: `${terminalId}/${Number(orderId) + 1}`,
    log: obj,
  };

  try {
    await Axios.post(`${URL.GRAVITEE_BASE_URL}/logs`, body,config);
  } catch (error) {
    console.log(error);
  }
};

export { scoLog };
