import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, message, Spin, Modal, Radio } from "antd";
import { LoadingOutlined, LeftCircleOutlined } from "@ant-design/icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "../../antd.css";
import "../styles.css";
import Axios from "axios";
import db from "../../database";
import URL from "../../app/server.json";
import moment from "moment";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import './index.css'
import backgroungImage from "../../assets/SCO_asset/signup/backgroundImage.png";
import { useTranslation } from 'react-i18next';
import { dataSync } from "../websocket/dataSync";
import { isEmpty } from "lodash";

const CustomerSignup = () => {
  const keyboardRef1 = useRef(null);
  const keyboardRef2 = useRef(null);
  const keyboardRef3 = useRef(null);
  const keyboardRef4 = useRef(null);
  const navigate = useNavigate();
  const [tillData, setTilldata] = useState([]);
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [userName, setUserName] = useState("");
  const [pincodeNo, setPincodeNo] = useState("");
  const [keyboard, setKeyboard] = useState(3);
  const [layout, setlayout] = useState("shift");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkPhone, setCheckPhone] = useState(false);
  const [radioError, setRadioError] = useState(false);
  const [mobileError, setMobileError] = useState({ flag: false, msg: "" });
  const [emailError, setEmailError] = useState({ flag: false, msg: "" });
  const [pincodeError, setPincodeError] = useState({ flag: false, msg: "" });
  const [noFlag, setNoFlag] = useState(false);
  const [emailFlag, setEmailFlag] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [checkedStatus, setCheckedStatus] = useState();
  const tempData = JSON.parse(localStorage.getItem("customerData"));
  const [keyboardVisible, setKeyboardVisible] = useState(true);
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual")
  const [isShiftDoubleClick, setIsShiftDoubleClick] = useState(false);
  const [shiftDoubleClick, setShiftDoubleClick] = useState(false);
  const apiKey = URL.GRAVITEE_API_KEY;

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  useEffect(() => {
    let key = localStorage.getItem("dynamoDB");
    if (key === "true") {
      console.log('value:', key);
      dataSync();
    };
    const handleCustomEvent = (event) => {
      if (event.detail.key === 'dynamoDB') {
        const dynamoDBValue = JSON.parse(event.detail.newValue);
        console.log('Parsed value:', dynamoDBValue);
        if (dynamoDBValue) {
          dataSync();
        }
      }
    };
    window.addEventListener('customStorageChange', handleCustomEvent);
    return () => {
      window.removeEventListener('customStorageChange', handleCustomEvent);
    };
  }, []);

  useEffect(() => {
    (async () => {
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      selectedProducts = selectedProducts === null ? {} : selectedProducts;
      selectedProducts.productsData = [];
      localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
      const data = await db.tillData.toArray();
      setTilldata(data[0]);
      if (tempData !== undefined || tempData !== null || tempData !== "") {
        setPhoneNo(tempData.phoneNo);
        setEmail(tempData.email);
        if (tempData.email !== "") {
          setEmailFlag(true);
        };
        if (tempData.phoneNo !== "") {
          setNoFlag(true);
        };
      }
    })();
  }, []);

  useEffect(() => {
    if (keyboard === 4) {
      setlayout("number")
    }
    if (keyboard === 3) {
      setlayout("default")
    }
    if (keyboard === 2) {
      setlayout("shift")
    }
    if (keyboard === 1) {
      setlayout("number")
    }
  }, [keyboard])


  const offlineNavigate = () => {
    setLoading({ status: false, message: "" });
    let currentDate = moment(new Date()).format("YYYY-MM-DD");
    let currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    localStorage.setItem('salebegintime', currentDateTime);
    localStorage.setItem('surname', userName);
    localStorage.setItem('email', email.trim());
    localStorage.setItem('phoneNo', phoneNo.trim());
    localStorage.setItem('pincodeNo', pincodeNo);
    localStorage.setItem('orderDate', currentDate);
    localStorage.setItem('userData', null);
    navigate("/home");
  }

  const handleHome = async () => {
    let terminalId = localStorage.getItem("terminalNumber");
    let tempUserName = userName[userName.length - 1] === " " ? userName.slice(0, -1) : userName;
    let tempEmail = !email || /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)?\.[a-zA-Z]{2,}$/.test(email);
    let city_geo_id = "";
    let postal_code_geo_id = "";
    if (`IN-${JSON.parse(tillData.storeDetail).storeAddress.storePostalCode}-${JSON.parse(tillData.storeDetail).storeAddress.storeCityName}`.length < 20) {
      city_geo_id = `IN-${JSON.parse(tillData.storeDetail).storeAddress.storePostalCode}-${JSON.parse(tillData.storeDetail).storeAddress.storeCityName}`
    } else {
      if (JSON.parse(tillData.storeDetail).storeAddress.storeCityName.length < 20) {
        city_geo_id = JSON.parse(tillData.storeDetail).storeAddress.storeCityName;
      } else {
        city_geo_id = JSON.parse(tillData.storeDetail).storeAddress.storePostalCode;
      }
    };
    if (`IN-${pincodeNo}-${JSON.parse(tillData.storeDetail).storeAddress.storeCityName}`.length < 20) {
      postal_code_geo_id = `IN-${pincodeNo}-${JSON.parse(tillData.storeDetail).storeAddress.storeCityName}`
    } else {
      if (JSON.parse(tillData.storeDetail).storeAddress.storeCityName.length < 20) {
        postal_code_geo_id = JSON.parse(tillData.storeDetail).storeAddress.storeCityName;
      } else {
        postal_code_geo_id = pincodeNo;
      }
    };

    if (checkPhone === true && phoneNo !== "") {
      if (phoneNo.length === 10 && /^[6789]/.test(phoneNo) && tempEmail) {
        setLoading({ status: true, message: "" });
        let headers = {
          "Content-Type": "application/json",
        }
        headers[URL.key_NAME] = apiKey;
        let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
        selectedProducts = selectedProducts === null ? {} : selectedProducts;
        if (pincodeNo.length === 6 && checkedStatus !== undefined) {
          setLoading({ status: true, message: "" });
          let obj = {
            ...(phoneNo !== "" ? { phone: `${phoneNo.trim()}` } : {}),
            ...(email !== "" ? { email: `${email.trim()}` } : {}),
            firstname: tempUserName,
            zipcode: `${pincodeNo}`,
            optin: checkedStatus,
            store: terminalId.substring(2, 6)
          }
          Axios({
            url: `${URL.GRAVITEE_BASE_URL}/customerSignup`,
            method: "GET",
            data: {
              obj: obj
            },
            params: {
              obj: obj
            },
            headers: headers,
          }).then((data) => {
            if (data.data.statusCode === 200) {
              let currentDate = moment(new Date()).format("YYYY-MM-DD");
              let customerData = { phoneNo: phoneNo.trim(), email: email.trim() }
              localStorage.setItem('customerData', JSON.stringify(customerData))
              let currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
              localStorage.setItem("signupFlag", true);
              selectedProducts.customerSignupData = data.data;
              selectedProducts.productsData = [];
              localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
              localStorage.setItem('orderDate', currentDate);
              localStorage.setItem('salebegintime', currentDateTime);
              localStorage.setItem('surname', userName);
              localStorage.setItem('email', email.trim());
              localStorage.setItem('phoneNo', phoneNo.trim());
              localStorage.setItem('pincodeNo', pincodeNo);
              localStorage.setItem('customerSignupFlag', true);
              setLoading({ status: false, message: "" });
              navigate("/home");
            } else {
              setLoading({ status: false, message: "" });
              message.error("something is wrong");
            }
          }).catch(error => {
            if (!isEmpty(error?.response?.data?.message?.result?.message)) {
              if (error.response.data.message.result.message === "This phone number is already linked with another account!!!" ||
                error.response.data.message.result.message === "This email is already linked with another account!!!"
              ) {
                setLoading({ status: false, message: "" });
                if (emailFlag === true) {
                  setPhoneNo("");
                  setNoFlag(true)
                  setMobileError({ flag: true, msg: "Number already exists" });
                  setKeyboardVisible(false);
                  setTimeout(() => {
                    setKeyboardVisible(true);
                  }, 0);
                }
                else {
                  setLoading({ status: false, message: "" });
                  setEmail("");
                  setEmailError({ flag: true, msg: "Email already exists" });
                  setKeyboardVisible(false);
                  setTimeout(() => {
                    setKeyboardVisible(true);
                  }, 0);
                }
              }
              else {
                offlineNavigate();
              }
            }
            else {
              offlineNavigate();
            }
          });
        } else {
          if (checkedStatus === undefined && pincodeNo.length !== 6) {
            setLoading({ status: false, message: "" });
            setPincodeError({ flag: true, msg: "Kindly Provide 6 Digits Postal Code" });
            setRadioError(true)
          }
          else if (checkedStatus === undefined) {
            setLoading({ status: false, message: "" });
            setRadioError(true)
          }
          else {
            setLoading({ status: false, message: "" });
            setPincodeError({ flag: true, msg: "Kindly Provide 6 Digits Postal Code" });
          }
        }
      } else {
        if(mobileError.flag === false){
          if(phoneNo.length !== 10){
            setMobileError({ flag: true, msg: "Number should not be less than 10 digits" });
          }
          else if(!/^[6789]/.test(phoneNo)){
            setMobileError({ flag: true, msg: "Mobile Number Should Start with 6/7/8/9" });
          }
        }      }
    } else {
      if (email.trim() === "" || email.trim().length === 0 || tempEmail) {
        setLoading({ status: true, message: "" });
        let headers = {
          "Content-Type": "application/json",
        }
        headers[URL.key_NAME] = apiKey;
        let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
        selectedProducts = selectedProducts === null ? {} : selectedProducts;
        if (pincodeNo.length === 6 && checkedStatus !== undefined) {
          setLoading({ status: true, message: "" });
          let obj = {
            ...(phoneNo !== "" ? { mobileno: `${phoneNo.trim()}` } : {}),
            ...(email !== "" ? { email: `${email.trim()}` } : {}),
            firstname: tempUserName,
            zipcode: `${pincodeNo}`,
            optin: checkedStatus,
            store: terminalId.substring(2, 6)
          }
          Axios({
            url: `${URL.GRAVITEE_BASE_URL}/customerSignup`,
            method: "GET",
            data: {
              obj: obj
            },
            params: {
              obj: obj
            },
            headers: headers,
          }).then((data) => {
            if (data.data.statusCode === 200) {
              let currentDate = moment(new Date()).format("YYYY-MM-DD");
              let customerData = { phoneNo: phoneNo.trim(), email: email.trim() }
              localStorage.setItem('customerData', JSON.stringify(customerData))
              let currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
              localStorage.setItem("signupFlag", true);
              selectedProducts.customerSignupData = data.data;
              localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
              localStorage.setItem('orderDate', currentDate);
              localStorage.setItem('salebegintime', currentDateTime);
              localStorage.setItem('surname', userName);
              localStorage.setItem('email', email.trim());
              localStorage.setItem('phoneNo', phoneNo.trim());
              localStorage.setItem('pincodeNo', pincodeNo);
              localStorage.setItem('customerSignupFlag', true);
              setLoading({ status: false, message: "" });
              navigate("/home");
            } else {
              setLoading({ status: false, message: "" });
              message.error("something is wrong");
            }
          }).catch(error => {
            if (!isEmpty(error?.response?.data?.message?.result?.message)) {
              if (error.response.data.message.result.message === "This phone number is already linked with another account!!!" ||
                error.response.data.message.result.message === "This email is already linked with another account!!!"
              ) {
                setLoading({ status: false, message: "" });
                if (emailFlag === true) {
                  setPhoneNo("");
                  setNoFlag(true)
                  setMobileError({ flag: true, msg: "Number already exists" });
                  setKeyboardVisible(false);
                  setTimeout(() => {
                    setKeyboardVisible(true);
                  }, 0);
                }
                else {
                  setLoading({ status: false, message: "" });
                  setEmail("");
                  setEmailError({ flag: true, msg: "Email already exists" });
                  setKeyboardVisible(false);
                  setTimeout(() => {
                    setKeyboardVisible(true);
                  }, 0);
                }
              }
              else {
                offlineNavigate();
              }
            }
            else {
              offlineNavigate();
            }
          });
        } else {
          if (checkedStatus === undefined && pincodeNo.length !== 6) {
            setLoading({ status: false, message: "" });
            setPincodeError({ flag: true, msg: "Kindly Provide 6 Digits Postal Code" });
            setRadioError(true)
          }
          else if (checkedStatus === undefined) {
            setLoading({ status: false, message: "" });
            setRadioError(true)
          }
          else {
            setLoading({ status: false, message: "" });
            setPincodeError({ flag: true, msg: "Kindly Provide 6 Digits Postal Code" });
          }
        }
      } else {
        setEmailError({ flag: true, msg: "Email contains invalid characters" });
      }
    };
  }


  const onClickMobile = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(1)
    setShiftDoubleClick(false)
  }

  const onClickEmail = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(2)
    setShiftDoubleClick(false)
  }

  const onClickUserName = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(3)
    setShiftDoubleClick(false)
  }

  const onClickPincode = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(4)
    setShiftDoubleClick(false)
  }

  const onChangeCheckBox = (e) => {
    if (e.target.value === "Yes") {
      setCheckedStatus(true)
    }
    else if (e.target.value === "No") {
      setCheckedStatus(false)
    }
  };

  const onchangePhoneNo = (value) => {
    if(value.length < 1){
      setCheckPhone(false)
    }
    if (value.length > 10) {
      setMobileError({ flag: true, msg: "Number should not be great than 10 digits" });
      setPhoneNo(phoneNo)
      keyboardRef1.current.setInput(phoneNo)
    }
    else if (!/^[6789]/.test(value) && value !== "") {
      setMobileError({ flag: true, msg: "Mobile Number Should Start with 6/7/8/9" });
      setPhoneNo(value)
      keyboardRef1.current.setInput(value);
    }
    else {
      setMobileError({ flag: false, msg: "" });
      setPhoneNo(value)
      keyboardRef1.current.setInput(value);
    }
  };

  const onChnagePincode = (value) => {
    if (value.length > 6) {
      setPincodeError({ flag: true, msg: "Postal Code should not be great than 6 digits" });
      setPincodeNo(pincodeNo)
      keyboardRef4.current.setInput(pincodeNo);
    }
    else if (!/^[0-9]*$/.test(value) && value !== "") {
      setPincodeError({ flag: true, msg: "Enter valid number" });
      setPincodeNo(value)
      keyboardRef4.current.setInput(value);
    }
    else {
      setPincodeError({ flag: false, msg: "" });
      setPincodeNo(value)
      keyboardRef4.current.setInput(value);
    }
  }

  const onKeyPress = (button) => {
    if (keyboard === 1) {
      setCheckPhone(true);
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{small}" && button !== "{lock}" && button !== "{backspace}" & button !== "{tab}" && button !== "{enter}" & button !== "{space}") {
        if (layout === "default") setlayout("shift");
        let value = `${phoneNo}${button}`
        if (value.length <= 10 && /^[6789]/.test(value)) {
          setPhoneNo(value.replace(/\D/g, ""));
        }
        else if (!/^[6789]/.test(value)) {
          setMobileError({ flag: true, msg: "Mobile Number Should Start with 6/7/8/9" });
        }
        else if (value.length > 10) {
          setMobileError({ flag: true, msg: "Number should not be great than 10 digits" });
          setPhoneNo(phoneNo)
          keyboardRef1.current.setPhoneNo(value);
        }
        else if (value.length <= 1) {
          setMobileError({ flag: true, msg: "Mobile Number Should Start with 6/7/8/9" });
        }
      };
      // if (phoneNo.length <= 1) {
      //   setCheckPhone(false);
      // }
    }
    else if (keyboard === 2) {
      setEmailError({ flag: false, msg: "" });
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setEmail(email.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setEmail(email)
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" && button !== "{tab}" && button !== "{enter}" && button !== "{space}" && button !== "{numbers}") {
        if (!shiftDoubleClick) {
          if (layout === "default") setlayout("shift");

        }
        let text = `${email}${button}`
        if (email.includes(".com")) {
          let tempEmail = email.split(".com")
          text = `${tempEmail[0]}.com`
        }
        setEmail(text.trim());
      }
    }
    else if (keyboard === 3) {
      keyboardRef3.current.setInput(userName)
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setUserName(userName.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setUserName(userName)
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" && button !== "{tab}" && button !== "{enter}" && button !== "{space}" && button !== "{numbers}" && /^[a-zA-Z]+$/.test(button)) {
        if (!shiftDoubleClick) {
          if (layout === "default") setlayout("shift");

        }
        let text = `${userName}${button}`
        setUserName(text);
      }
    }
    else if (keyboard === 4) {
      setPincodeError({ flag: false, msg: "" });
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setPincodeNo(pincodeNo.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setPincodeNo(pincodeNo);
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" && button !== "{tab}" && button !== "{enter}" && button !== "{space}") {
        if (layout === "default") setlayout("shift");
        if (pincodeNo.length < 6) {
          let text = `${pincodeNo}${button}`
          if (/^[0-9]*$/.test(text)) {
            setPincodeNo(text.replace(/\D/g, "").trim());
          } else {
            keyboardRef4.current.setPincodeNo(pincodeNo);
          }
        } else {
          setPincodeNo(pincodeNo);
          setPincodeError({ flag: true, msg: "Postal Code should not be great than 6 digits" });
          keyboardRef4.current.setPincodeNo(pincodeNo)
        }
      }
    }
    if (button === "{numbers}" || button === "{caps}") handleNumbers();
    if (button === "{number}" || button === "{small}") handleNumber();
    if (button === "{back}") handleReturn();
    if (button === "{space}") handleSpace();
    if (button === "{mic}") handleMic();
  };

  const handleMic = () => {
    setUserName(userName);
    setPhoneNo(phoneNo);
    setEmail(email);
    setPincodeNo(pincodeNo);
    setlayout(layout)
  };

  const handleSpace = () => {
    if (keyboard === 1) {
      setPhoneNo(phoneNo);
    }
    else if (keyboard === 2) {
      setEmail(email);
    }
    else if (keyboard === 3) {
      setUserName(userName + ' ');
    }
    else if (keyboard === 4) {
      setPincodeNo(pincodeNo + ' ');
    }
  }

  const handleReturn = () => {
    if (keyboard === 3) {
      keyboardRef1.current.focus();
      setKeyboard(1)
    }
    else if (keyboard === 1) {
      keyboardRef2.current.focus();
      setKeyboard(2)
    }
    else if (keyboard === 2) {
      keyboardRef4.current.focus();
      setKeyboard(4)
    }
    else if (keyboard === 4) {
      keyboardRef3.current.focus();
      setKeyboard(3)
    }
    setEmail(email);
    setPhoneNo(phoneNo);
    setPincodeNo(pincodeNo);
    setUserName(userName);
  }

  const handleShift = () => {
    const layout1 = layout;
    setlayout(layout1 === "default" ? "shift" : "default");
    setEmail(email);
    setPhoneNo(phoneNo);
    setPincodeNo(pincodeNo);
    setUserName(userName);
    if (isShiftDoubleClick) {
      setlayout('default')
      setShiftDoubleClick(true)
    } else {
      setIsShiftDoubleClick(true);

      setTimeout(() => {
        setIsShiftDoubleClick(false);
      }, 500);
    }
  };

  const handleNumbers = () => {
    const layout1 = layout;
    keyboard !== 3 ? setlayout(layout1 === "default" ? "numbers" : "default") : setlayout(layout)
    setEmail(email);
    setPhoneNo(phoneNo);
    setPincodeNo(pincodeNo);
    setUserName(userName);
  }

  const handleNumber = () => {
    const layout1 = layout;
    keyboard !== 3 && keyboard !== 1 ? setlayout(layout1 === "shift" ? "number" : "shift") : setlayout(layout)
    setEmail(email);
    setPhoneNo(phoneNo);
    setPincodeNo(pincodeNo);
    setUserName(userName);
  }

  const handlePhone = (event) => {
    const inp = event.target.value;
    setCheckPhone(true);
    if (inp.length <= 10 && /^[6789]/.test(inp)) {
      setMobileError({ flag: false, msg: "" });
      setPhoneNo(inp.replace(/\D/g, ""));
    } else if (!/^[6789]/.test(inp) && inp.length === 0) {
      setPhoneNo("");
    } else {
      setMobileError({ flag: true, msg: "Mobile Number Should Start with 6/7/8/9" });
    }
  };

  const handleEmail = (ev) => {
    setEmail(ev.target.value);
  };

  const handleUserName = (ev) => {
    if (ev.target.value.match(/^[A-Za-z]+$/)) {
      setUserName(ev.target.value);
    }
  };

  const handlePincode = (event) => {
    const inp = event.target.value;
    setPincodeError({ flag: false, msg: "" });
    if (pincodeNo.length < 6) {
      setPincodeNo(inp.replace(/\D/g, ""));
    } else {
      setPincodeError({ flag: true, msg: "Postal Code should not be great than 6 digits" });
    }
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  const sectionStyle = {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${backgroungImage})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "fixed",
  }

  const onChangeUserName = (val) => {
    const modifiedVal = val.replace(/@yahoo\.com|@gmail\.com/g, '');
    setUserName(modifiedVal)
  };

  return (
    <div style={sectionStyle}>
      <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
        <Row >
          <Col span={22}>
          </Col>
          <Col span={2}>
            <LeftCircleOutlined className="cs-back-btn" onClick={() => setIsModalOpen(true)} />
          </Col>
          <Col span={24} className="cs-main-heading">
            WELCOME TO DECATHLON
          </Col>
          <Col span={24} className="cs-sub-heading">Please create your account with us to proceed with your order</Col>
          <Col span={24} className="cs-sub-heading">
            {multilingual === "true" && (
              <>
                {t('please create your account with us to proceed with your order')}
              </>
            )}
          </Col>
        </Row>
        <Row justify="space-between" style={{ marginTop: "3vh" }}>
          <Col span={1}></Col>
          <Col span={10} >
            <Form layout="vertical">
              <Form.Item style={{ marginBottom: "0px" }}>
                <Row style={{ padding: "0 2vw 0 0.2vw", marginBottom: "-14px" }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: "0 0 20px", height: "3px", backgroundColor: "#fff" }} />
                      <div style={{ margin: "0 10px", fontSize: "1rem", color: "white" }}>
                        Name
                        {multilingual === "true" && (
                          <>
                            <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                            <span style={{ marginLeft: "0.3rem" }}>{t('name')}</span>
                          </>                        )}
                      </div>
                      <div style={{ flex: "1", height: "3px", backgroundColor: "#fff" }} />
                    </div>
                  </Col>
                </Row>
                <input inputMode="none" autoFocus={true} className="cs-input-field" ref={keyboardRef3} value={userName} onChange={handleUserName} onClick={onClickUserName} onFocus={() => { setKeyboard(3) }} />
                <div style={{ fontSize: "1em", color: mobileError.flag === false ? "#fff" : "red", visibility: "hidden" }}>Hidden</div>
              </Form.Item>
              <Form.Item style={{ marginBottom: "0px" }}>
                <Row style={{ padding: "0 2vw 0 0.2vw", marginBottom: "-14px" }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: "0 0 20px", height: "3px", backgroundColor: mobileError.flag === false ? "#fff" : "red" }} />
                      <div style={{ margin: "0 10px", fontSize: "1rem", color: mobileError.flag === false ? "#fff" : "red" }}>
                        Mobile Number
                        {multilingual === "true" && (
                          <>
                            <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                            <span style={{ marginLeft: "0.3rem" }}>{t('mobile number')}</span>
                          </>                        )}
                      </div>
                      <div style={{ flex: "1", height: "3px", backgroundColor: mobileError.flag === false ? "#fff" : "red" }} />
                    </div>
                  </Col>
                </Row>
                <input inputMode="none" className="cs-input-field" style={{ borderColor: mobileError.flag === false ? "#fff" : "red" }} ref={keyboardRef1} value={tempData.phoneNo !== "" ? tempData.phoneNo : phoneNo.trim()} onChange={handlePhone} onClick={onClickMobile} onFocus={() => { setKeyboard(1) }} />
                <div style={{ fontSize: "1em", color: mobileError.flag === false ? "#fff" : "red", visibility: mobileError.flag === false ? "hidden" : "visible" }}>{mobileError.flag === false ? "Hidden" : mobileError.msg}</div>
              </Form.Item>
              <Form.Item style={{ marginBottom: "0px" }}>
                <Row style={{ padding: "0 2vw 0 0.2vw", marginBottom: "-14px" }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: "0 0 20px", height: "3px", backgroundColor: emailError.flag === false ? "#fff" : "red" }} />
                      <div style={{ margin: "0 10px", fontSize: "1rem", color: emailError.flag === false ? "#fff" : "red" }}>
                        Email
                        {multilingual === "true" && (
                          <>
                            <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                            <span style={{ marginLeft: "0.3rem" }}>{t('email')}</span>
                          </>                        )}
                      </div>
                      <div style={{ flex: "1", height: "3px", backgroundColor: emailError.flag === false ? "#fff" : "red" }} />
                    </div>
                  </Col>
                </Row>
                <input inputMode="none" className="cs-input-field" style={{ borderColor: emailError.flag === false ? "#fff" : "red" }} ref={keyboardRef2} value={tempData.email !== "" ? tempData.email : email} onChange={handleEmail} onClick={onClickEmail} onFocus={() => { setKeyboard(2) }} />
                <div style={{ fontSize: "1em", color: emailError.flag === false ? "#fff" : "red", visibility: emailError.flag === false ? "hidden" : "visible" }}>{emailError.flag === false ? "Hidden" : emailError.msg}</div>
              </Form.Item>
              <Form.Item style={{ marginBottom: "0px" }}>
                <Row style={{ padding: "0 2vw 0 0.2vw", marginBottom: "-14px" }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: "0 0 20px", height: "3px", backgroundColor: pincodeError.flag === false ? "#fff" : "red" }} />
                      <div style={{ margin: "0 10px", fontSize: "1rem", color: pincodeError.flag === false ? "#fff" : "red" }}>
                      <span style={{ marginRight: 4, color: "red", alignSelf: "baseline" }}>*</span>
                        Postal Code
                        {multilingual === "true" && (
                          <>
                            <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                            <span style={{ marginLeft: "0.3rem" }}>{t('pincode')}</span>
                          </>                        )}
                      </div>
                      <div style={{ flex: "1", height: "3px", backgroundColor: pincodeError.flag === false ? "#fff" : "red" }} />
                    </div>
                  </Col>
                </Row>
                <input inputMode="none" className="cs-input-field" style={{ borderColor: pincodeError.flag === false ? "#fff" : "red" }} ref={keyboardRef4} value={pincodeNo} onChange={handlePincode} onClick={onClickPincode} onFocus={() => { setKeyboard(4) }} />
                <div style={{ fontSize: "1em", color: pincodeError.flag === false ? "#fff" : "red", visibility: pincodeError.flag === false ? "hidden" : "visible" }}>{pincodeError.flag === false ? "Hidden" : pincodeError.msg}</div>
              </Form.Item>
              <Form.Item
                name="communication"
                label={
                  <>
                    <span style={{ display: 'flex', alignItems: 'center', color: radioError ? "red" : "#fff", fontSize: "1rem" }}>
                      <span style={{ marginRight: 4, color: "red", alignSelf: "baseline" }}>*</span>
                      <span>Would you like to receive communication on exciting offers, services & more?</span>
                    </span>
                    {multilingual === "true" && (
                      <span style={{ display: 'flex', alignItems: 'center', color: radioError ? "red" : "#fff", fontSize: "1rem" }}>
                        <span style={{ marginRight: 4, color: "red", alignSelf: "baseline" }}>*</span>
                        <span>{t('would you like to receive communication on exciting offers, services & more?')}</span>
                      </span>
                    )}

                  </>
                }
                rules={[{ required: true, message: 'Please select an option', },]}
              >
                <Radio.Group onChange={onChangeCheckBox} style={{ display: "flex", justifyContent: "center" }}>
                  <Radio style={{ color: "#fff", fontSize: "20px" }} value="Yes">
                    Yes
                    {multilingual === "true" && (
<>
                        <span style={{ marginLeft: "0.3em" }}>{"/"}</span>
                        <span style={{ marginLeft: "0.3rem", color: "#fff", fontSize: "20px" }}>{t('yes')}</span>
                      </>                    )}
                  </Radio>
                  <Radio style={{ color: "#fff", fontSize: "20px" }} value="No">
                    No
                    {multilingual === "true" && (
<>
                        <span style={{ marginLeft: "0.3em" }}>{"/"}</span>
                        <span style={{ marginLeft: "0.3rem", color: "#fff", fontSize: "20px" }}>{t('no')}</span>
                      </>                    )}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          </Col>
          <Col span={1}></Col>
          <Col span={11} style={{ marginTop: "5vh", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div className="cs-kb-div">
              {keyboardVisible && (
                <Keyboard
                  buttonTheme={[
                    {
                      class: "hg-yellow",
                      buttons: "{space}"
                    },
                    {
                      class: "hg-mail",
                      buttons: "@yahoo.com @gmail.com"
                    },
                    {
                      class: "hg-number",
                      buttons: "{number} {numbers}",
                    }
                  ]}
                  layoutName={layout}
                  keyboardRef={keyboard === 1 ? (r) => (keyboardRef1.current = r) : keyboard === 2 ? (r) => (keyboardRef2.current = r) : keyboard === 3 ? (r) => (keyboardRef3.current = r) : (r) => (keyboardRef4.current = r)}
                  inputName={keyboard === 1 ? "mobile" : keyboard === 2 ? "email" : keyboard === 3 ? "name" : "pincode"}
                  onChange={(val) => { keyboard === 1 ? onchangePhoneNo(val) : keyboard === 2 ? setEmail(val) : keyboard === 3 ? onChangeUserName(val) : onChnagePincode(val) }}
                  onKeyPress={onKeyPress}
                  theme={"hg-theme-default signUp "}
                  display={{
                    "{abc}": "ABC",
                    "{smileys}": "\uD83D\uDE03",
                    "{back}": "Enter",
                    "{numbers}": "123",
                    "{number}": "123",
                    '{bksp}': 'backspace',
                    "{backspace}": "⌫",
                    "{shift}": "⇧",
                    '{tab}': 'tab',
                    '{lock}': 'lock',
                    '{enter}': 'enter',
                    '{space}': "Space",
                    "{caps}": "ABC",
                    "{small}": "abc",
                  }}
                  layout={{
                    shift: [
                      "q w e r t y u i o p",
                      "a s d f g h j k l",
                      "{shift} z x c v b n m {backspace}",
                      "{number} {space} @yahoo.com @gmail.com"
                    ],
                    numbers: [
                      "1 2 3 4 5 6 7 8 9 0",
                      '- / : ; ( ) $ & @ "',
                      '# . , _ ! ` {backspace}',
                      "{caps} {space} {back}"
                    ],
                    number: [
                      "1 2 3 4 5 6 7 8 9 0",
                      '- / : ; ( ) $ & @ "',
                      '# . , _ ! ` {backspace}',
                      "{small} {space} {back}"
                    ],
                    default: [
                      "Q W E R T Y U I O P",
                      "A S D F G H J K L",
                      "{shift} Z X C V B N M {backspace}",
                      "{numbers} {space} @yahoo.com @gmail.com"
                    ],
                  }}
                />
              )}
            </div>
            <Button className="cs-continue-btn" onClick={handleHome} >
              CONTINUE
              {multilingual === "true" && (
                <>
                  <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                  <span style={{ marginLeft: "0.3rem" }}>{t('continue')}</span>
                </>              )}
            </Button>
          </Col>
          <Col span={1}></Col>
        </Row>
      </Spin>
      <Modal okText="Yes" cancelText="No" open={isModalOpen} onOk={() => navigate("/customer-login")} onCancel={() => setIsModalOpen(false)}>
        <p style={{ fontWeight: 600, fontSize: "3vh" }}>Do you want to go back</p>
      </Modal>
    </div>
  );
};

export default CustomerSignup;
