import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Form, message, Modal, Input, Spin, Select, Card } from "antd";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "../../antd.css";
import "../styles.css";
import "./style.css";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { LeftCircleOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png"
import { useTranslation } from "react-i18next";
import Axios from "axios";
import URL from "../../app/server.json";
import moment from "moment";
import { async } from "rxjs/internal/scheduler/async";
import { isEmpty, set, valuesIn } from "lodash";
import CryptoJS from 'crypto-js';
import CardPayment from "../../assets/SCO_asset/payment/card-swipe.gif";
import UpiPayment from "../../assets/SCO_asset/payment/upi.gif";
import GiftCard from "../../assets/SCO_asset/payment/gicard.gif";
import select from "../../assets/SCO_asset/payment/select.png";
import PaymentSelection from "./payment-selection";
import { v4 as uuidv4 } from 'uuid';


const GiftCardPayment = (props) => {
  const { handlePaymentSelection, setShowGiftcardDetails, setSelectedValue } = props;
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const keyboardRef = useRef(null);
  const navigate = useNavigate();
  const [inputName, setInputName] = useState("default");
  const [availableBalance, setAvailableBalance] = useState(null);
  const [utilizedBalance, setUtilizedBalance] = useState(null);
  const [giftCradPaymentTranSactionId, setGiftCradPaymentTranSactionId] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [remainigDue, setRemainigDue] = useState(null);
  const [giftcardData, setGiftcardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(localStorage.getItem("surname"))
  const [finalArray, setFinalArray] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const [isAmountEdit, setIsAmountEdit] = useState(false)
  const [giftCardBalacnce, setGiftCardBalance] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal1Open, setIsModal1Open] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);

  const [checkBalanceData, setCheckBalanceData] = useState("");
  const [cardErrorMsg, setCardErrorMsg] = useState({ flag: false, message: "" });
  const [pinErrorMsg, setPinErrorMsg] = useState({ flag: false, message: "" });
  const [selectedItems, setSelectedItems] = useState(() => {
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    if (selectedProducts.productsData !== undefined || selectedProducts.productData !== null) {
      return selectedProducts.productsData;
    } else {
      return [];
    }
  });
  let token = localStorage.getItem("token");
  const config = {
    headers: {
      'X-Gravitee-Api-Key': URL.GRAVITEE_API_KEY,
    },
  };
  const cashierData = JSON.parse(localStorage.getItem("cashierData"));;
  let cashierUsername = CryptoJS.AES.decrypt(cashierData.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
  let cashierPassword = CryptoJS.AES.decrypt(cashierData.Password, 'secret-key').toString(CryptoJS.enc.Utf8);
  let basicAuth = `${cashierUsername}:${cashierPassword}`;
  const [summaryObj, setSummaryObj] = useState({ items: 0, count: 0, discount: 0, total: 0 });
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual")

  useEffect(() => {
    let items = 0;
    let count = 0;
    let discount = 0;
    let total = 0;
    let arr = selectedItems?.length > 0 ? [...selectedItems] : [];
    arr.map((item, ind) => {
      items = items + ind + 1;
      count = count + item.count;
      discount = discount + (Number(item.sale_price) * item.percentage) / 100;
      total = total + Number(item.sale_price);
    });
    setSummaryObj({ items: items, count: count, discount: discount, total: total });
    let counts = {};
    selectedItems.forEach(item => {
      let id = item.id;
      counts[id] = (counts[id] || 0) + 1;
    });
    const newArray = [];
    const seenIds = new Set();
    selectedItems.forEach(obj => {
      if (!seenIds.has(obj.id)) {
        obj[`discountSalePrice`] = Number((Number(obj.sale_price) - (Number(obj.sale_price) * obj.percentage / 100)).toFixed(2))
        newArray.push(obj);
        seenIds.add(obj.id);
      } else {
        newArray.map((item, ind) => {
          if (obj.id === item.id) {
            item[`discountSalePrice`] = item[`discountSalePrice`] + Number((Number(obj.sale_price) - (Number(obj.sale_price) * obj.percentage / 100)).toFixed(2))
          }
        })
      }
    });
    Object.entries(counts).map((key) => {
      newArray.map((item) => {
        if (key[0] === item.id) {
          if (key[1] > 0) {
            item.count = key[1];
          }
        }
      })
    });
    setFinalArray(newArray);
    // inputFields.amount = summaryObj?.total - summaryObj?.discount
    // console.log(inputFields)
  }, [selectedItems, isAmountEdit]);


  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);


  // Order Obj creation Start
  let selectedProducts = JSON.parse(localStorage.getItem("selectedProducts"));
  let orderDate = localStorage.getItem("orderDate");
  let salebegintime = localStorage.getItem("salebegintime");
  let doorNo;
  let street;
  let zipcode;
  let city;
  let country;
  let state;
  let email;
  let mobile;
  let total = 0;
  let discount = 0;
  let decathlonId;
  let orderId = Number(localStorage.getItem("order_lastassignednum")) + 1;
  let terminalId = localStorage.getItem("terminalNumber");
  let customerFlag = localStorage.getItem("customerSignupFlag");
  let customerEmail = localStorage.getItem("email");
  let customerPhoneNo = localStorage.getItem("phoneNo");
  let customerName = localStorage.getItem("surname");
  let customerPincodeNo = localStorage.getItem("pincodeNo");
  let userData = JSON.parse(localStorage.getItem('userData'));
  let linesArray = [];
  let taxDetails = [];
  selectedProducts = selectedProducts === null ? {} : selectedProducts;
  if (selectedProducts.tillData !== undefined || selectedProducts.tillData !== null
  ) {
    selectedProducts.productsData.map((item, ind) => {
      total = total + item.sale_price;
      discount = discount + (item.sale_price * item.percentage) / 100;
    });
    let taxData = JSON.parse(localStorage.getItem("taxData"));
    taxData.newArray.map((item) => {
      JSON.parse(item.tax).map((e, ind) => {
        if (e.name[0] === "C") {
          let taxableAmount = Number(item.taxableAmount * item.count);
          let sellingAmount = item.discountSalePrice;
          let netAmount = sellingAmount - taxableAmount;
          let obj = {
            "itemCode": item.id,
            "unitPrice": item.discountPrice,
            "ean": item.ean,
            "serialNumber": item.ean,
            "rfidscancode": "",
            "epcCode": item.epcCode,
            "offer": false,
            "offerName": item.discountName === undefined ? "" : item.discountName === undefined,
            "type": "takeaway",
            "unitDiscount": item.discountValue,
            "qty": item.count,
            "taxCategory": e.name,
            "taxRate": e.rate,
            "sellingAmount": sellingAmount,
            "taxableAmt": taxableAmount.toFixed(2),
            "ISGST": item.isgst.toFixed(2),
            "ICGST": item.icgst,
            "netAmount": netAmount.toFixed(2),
            "taxId": e.taxId,
            "swap": item.swap,
            'tax': item.tax,
          };
          linesArray.push(obj);
        };
      });
    });
    taxData.taxArray.map((element) => {
      if (JSON.parse(element.gst).length > 0) {
        let index = 0;
        let taxCategoryName = JSON.parse(element.gst)?.findIndex(item => item.name.charAt(0) === "S");
        if (taxCategoryName !== -1) {
          index = taxCategoryName;
        };
        JSON.parse(element.gst).map((item, index) => {
          if (item.name === "CGST-LS" || item.name === "CGST-LY" || item.name === "CGST-LP") {
            if (index === 0) {
              if (JSON.parse(element.gst)[1].name.charAt(0) === "S") {
                let taxObj = {
                  "taxCategory": JSON.parse(element.gst)[1].name,
                  "taxRate": element.key / 2,
                  "TSGST": element.isgst.toFixed(2),
                  "TCGST": element.icgst.toFixed(2),
                  "CGST": element.GroupCGST,
                  "SGST": element.GroupSGST,
                  "sellingAmount": element.discountSalePrice,
                  "taxedAmount": element.taxPrice,
                  "netAmount": (element.basePrice).toFixed(2),
                  "taxId": JSON.parse(element.gst)[1].taxId,
                  "verify": element.verify,
                  "gst": JSON.parse(element.gst),
                };
                taxDetails.push(taxObj);
              }
            } else {
              if (JSON.parse(element.gst)[0].name.charAt(0) === "S") {
                let taxObj = {
                  "taxCategory": JSON.parse(element.gst)[0].name,
                  "taxRate": element.key / 2,
                  "TSGST": element.isgst.toFixed(2),
                  "TCGST": element.icgst.toFixed(2),
                  "CGST": element.GroupCGST,
                  "SGST": element.GroupSGST,
                  "sellingAmount": element.discountSalePrice,
                  "taxedAmount": element.taxPrice,
                  "netAmount": (element.basePrice).toFixed(2),
                  "taxId": JSON.parse(element.gst)[0].taxId,
                  "verify": element.verify,
                  "gst": JSON.parse(element.gst),
                };
                taxDetails.push(taxObj);
              }
            }
          } else if (item.name !== "CGST-LS" || item.name !== "CGST-LY" || item.name !== "CGST-LP" || item.name !== "SGST-LS" || item.name !== "SGST-LY" || item.name !== "SGST-LP") {
            if (item.name.charAt(0) === "C") {
              let taxObj = {
                "taxCategory": item.name,
                "taxRate": element.key / 2,
                "TSGST": element.isgst.toFixed(2),
                "TCGST": element.icgst.toFixed(2),
                "CGST": element.GroupCGST,
                "SGST": element.GroupSGST,
                "sellingAmount": element.discountSalePrice,
                "taxedAmount": element.taxPrice,
                "netAmount": (element.basePrice).toFixed(2),
                "taxId": item.taxId,
                "verify": element.verify,
                "gst": JSON.parse(element.gst),
              };
              taxDetails.push(taxObj);
            }
          }
        });
      };
    });
    doorNo = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeAddressline1;
    street = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeAddressline2;
    zipcode = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storePostalCode;
    city = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeCityName;
    country = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeCountry;
    state = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeRegion;
    if (userData !== undefined) {
      if (userData !== null) {
        email = userData.result.Email !== undefined ? userData.result.Email : customerEmail;
        mobile = userData.result.Mobile !== undefined ? userData.result.Mobile : customerPhoneNo;
        decathlonId = userData.result.CardNumber;
      }
    }
  }

  let obj = {
    address: [
      {
        doorNo: `${doorNo}`,
        street: `${street}`,
        zipcode: `${zipcode}`,
        city: `${city}`,
        country: `${country}`,
        state: `${state}`,
        type: "billing",
      },
    ],
    MyDecathlonId: decathlonId !== undefined ? `${decathlonId}` : "",
    email: !isEmpty(email) ? CryptoJS.AES.encrypt(email, 'secret-key').toString() : isEmpty(customerEmail) ? "" : CryptoJS.AES.encrypt(customerEmail, 'secret-key').toString(),
    mobile: !isEmpty(mobile) ? CryptoJS.AES.encrypt(mobile, 'secret-key').toString() : isEmpty(customerPhoneNo) ? "" : CryptoJS.AES.encrypt(customerPhoneNo, 'secret-key').toString(),
    zipcode: customerPincodeNo || "",
    paymentMethod: "",
    name: customerFlag === "false" ? name : customerName,
    salebegintime: `${salebegintime}`,
    orderDate: `${moment(orderDate).format("DD-MM-YYYY")}`,
    totalGrossAmount: Number((total - discount).toFixed(2)),
    totalDiscount: 0,
    lines: linesArray.reverse(),
    store: `${"7" + terminalId.toString().substring(2, 6) + terminalId.toString().substring(2, 6)}`,
    terminal: `${terminalId}`,
    orderNumber: `${terminalId}/${Number(orderId)}`,
    payment: [],
    saleendtime: `${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}`,
    PaymentDone: "true",
    cashiername: `${cashierData.Username}`,
    ebillMode: "",
    taxDetails: taxDetails,
  };


  //   Order Obj creation End
  const onChangeValues = (inputs) => {
    setInputFields({ ...inputs });
    form.setFieldsValue(inputs);
  };

  const onChangeInput = (event, field) => {
    const inputVal = event.target.value;

    if (field === "cardNumber") {
      const firstDigit = inputVal.charAt(0);
      const isValidFirstDigit = ['1', '4', '6', '7', ''].includes(firstDigit);

      if (!isValidFirstDigit) {
        setCardErrorMsg({
          flag: true,
          message: "Card Number should start with 1, 4, 6, or 7."
        });
      }
      else if (inputVal.length > 16) {
        setCardErrorMsg({
          flag: true,
          message: "Gift Card number should be 16 digits."
        });
      }

      else {
        setCardErrorMsg({
          flag: false,
          message: ""
        });
      }
      setInputFields({
        ...inputFields,
        [inputName]: inputVal,
      });
    }
    else {
      setInputFields({
        ...inputFields,
        [inputName]: inputVal,
      });
    }
  };

  const handleSubmit = async (value) => {
    const formData = form.getFieldsValue()
    if (!formData.cardNumber && !formData.pinNumber) {
      setCardErrorMsg({
        flag: true,
        message: "Card Number is Mandatory."
      })
      setPinErrorMsg({
        flag: true,
        message: "PIN Number is Mandatory."
      })
    }
    else if (!formData.cardNumber) {
      setCardErrorMsg({
        flag: true,
        message: "Card Number is Mandatory."
      })

    }
    else if (!formData.pinNumber) {
      setPinErrorMsg({
        flag: true,
        message: "PIN number is Mandatory."
      })
    }
    else {
      const tokenDetails = JSON.parse(localStorage.getItem("giftCardToken"))
      setLoading(true)
      setIsModalOpen(false)
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      selectedProducts = selectedProducts === null ? {} : selectedProducts;
      let object = {
        "TransactionTypeId": "306",
        "InputType": "1",
        "Cards": [
          {
            "TrackData": "",
            "CardNumber": inputFields.cardNumber,
            "CardPin": inputFields.pinNumber
          }
        ],
        "Notes": `{ValType~GCRDM|Amount~${isAmountEdit ? inputFields.amount : (summaryObj?.total - summaryObj?.discount)}|BillAmount~${isAmountEdit ? inputFields.amount : (summaryObj?.total - summaryObj?.discount)}}`
      };
      const transactionId = Math.floor(Math.random() * 10000);
      setGiftCradPaymentTranSactionId(transactionId)
      let body = {
        "body": object,
        "Authorization": tokenDetails?.AuthToken,
        "TransactionId": transactionId,
        "MerchantOutletNameToOverride": "DSI-710581058"
      };
      try {
        const res = await Axios.post(`${URL.GRAVITEE_BASE_URL}/validateGiftCard`, body, config);
        if (res.data.statusCode === 200) {
          setLoading(false)
          if (res.data.data.ResponseMessage === "Transaction successful.") {
            const dateObject = new Date(res.data.data.Cards[0].ExpiryDate);
            const formattedDate = dateObject.toISOString().split('T')[0];
            const giftCardPaymentAmount = JSON.parse(localStorage.getItem("giftCardPayment"))?.amountPaid ?? 0
            if (res.data.data.Cards[0].Balance > 0 && res.data.data.Cards[0].Balance >= (summaryObj?.total - summaryObj?.discount - giftCardPaymentAmount)) {
              setIsModal1Open(true)
              setGiftcardData(res.data.data)
              setAvailableBalance(res.data.data.Cards[0].Balance)
              setUtilizedBalance(
                (summaryObj?.total ?? 0) -
                (summaryObj?.discount ?? 0) -
                (JSON.parse(localStorage.getItem("giftCardPayment"))?.amountPaid ?? 0)
              );
              setExpiryDate(formattedDate)
            } else if (res.data.data.Cards[0].Balance === 0) {
              setIsModalOpen(true);
              setGiftCardBalance(res.data.data.Cards[0].Balance);
              setLoading(false);
            } else if (res.data.data.Cards[0].Balance < (summaryObj?.total - summaryObj?.discount - giftCardPaymentAmount) && res.data.data.Cards[0].Balance > 0) {
              setIsModal2Open(true)
              setGiftcardData(res.data.data)
              setAvailableBalance(res.data.data.Cards[0].Balance)
              setUtilizedBalance(res.data.data.Cards[0].Balance);
              setExpiryDate(formattedDate)
              setRemainigDue((summaryObj?.total - summaryObj?.discount)-(JSON.parse(localStorage.getItem("giftCardPayment"))?.amountPaid ?? 0) - res.data.data.Cards[0].Balance)
              setGiftCardBalance(res.data.data.Cards[0].Balance);

            };
            let orderId = localStorage.getItem("order_lastassignednum");
            let terminalId = localStorage.getItem("terminalNumber");
            const formattedDateTime = `d_ikey_${moment().format('YYYY-MM-DD')}T${moment().format('HH:mm:ss')}`;
            let logsObject = {
              "store": JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.value,
              "terminal": terminalId,
              "apiType": "Check Balance",
              "documentno": `${terminalId}/${Number(orderId)}`,
              "amount": summaryObj?.total - summaryObj?.discount,
              "cardNumber": inputFields.cardNumber,
              "idempotencyKey": formattedDateTime,
              "transactionId": transactionId,
              "approvalCode": "",
              "qwikcilverRequest": object,
              "qwikcilverResponse": res.data.data
            }
            let bodyData = {
              "logs": logsObject,
              "remainingUrl": `/ws/com.openbravo.decathlon.retail.giftcards.qwikCilverLog`,
              "Authorization": `Basic ${window.btoa(basicAuth)}, ${token}`
            };
            await Axios.post(`${URL.GRAVITEE_BASE_URL}/qwikCilverLog`, bodyData, config);
          }
          else if(res.data.data.Cards[0].ResponseMessage === "Card not activated."){
            setLoading(false);
            setCardErrorMsg({ flag: true, message: "Gift Card is not activated" })

          }
          else {
            setLoading(false);
            setCardErrorMsg({ flag: true, message: "Please give proper valid card details" })
            // setErrorMsg(true)
          };
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const redeemGiftCard = async (title, index) => {
    setIsModal1Open(false)
    const tokenDetails = JSON.parse(localStorage.getItem("giftCardToken"))
    const formattedDateTime = `d_ikey_${moment().format('YYYY-MM-DD')}T${moment().format('HH:mm:ss')}`;
    let object = {
      "TransactionTypeId": "302",
      "IdempotencyKey": formattedDateTime,
      "InvoiceNumber": `${terminalId}/${Number(orderId)}`,
      "InvoiceAmount": `${utilizedBalance}`,
      "Notes": `{ValType~GCRDM|Amount~${utilizedBalance}}`,
      "InputType": "1",
      "NumberOfCards": "1",
      "Cards": [
        {
          "TrackData": "",
          "CardNumber": inputFields.cardNumber,
          "CardPin": inputFields.pinNumber,
          "Amount": utilizedBalance
        }
      ]
    };
    let body = {
      "body": object,
      "Authorization": tokenDetails?.AuthToken,
      "TransactionId": `${giftcardData?.TransactionId + 1}`,
      "MerchantOutletNameToOverride": "DSI-710581058"
    };

    try {
      const resp = await Axios.post(`${URL.GRAVITEE_BASE_URL}/redeemGiftCard`, body, config);
      if (resp.data.statusCode === 200) {
        if (resp.data.data.ResponseMessage === "Transaction successful.") {
          localStorage.setItem("paymentMode", "Gift Card");
          let existingOrderObj = JSON.parse(localStorage.getItem("orderObj"))?.payment || [];
          let newPaymentObj = {
            paymentRefId: `${terminalId}${Number(orderId)}`,
            paymentMethod: "Gift Card",
            totalamountpaid: utilizedBalance
          };
          existingOrderObj.push(newPaymentObj)       

          obj.paymentMethod = "Gift Card";
          obj.payment =existingOrderObj;
          localStorage.setItem("orderObj", JSON.stringify(obj));
          let giftCardPayment = JSON.parse(localStorage.getItem("giftCardPayment"));
          if (giftCardPayment) {
            let newAmountPaid = giftCardPayment.amountPaid + giftcardData.Cards[0].Balance;
            giftCardPayment.amountPaid = newAmountPaid;
            giftCardPayment.payment = existingOrderObj;
            localStorage.setItem("giftCardPayment", JSON.stringify(giftCardPayment));
        }
          else{
            localStorage.setItem("giftCardPayment", JSON.stringify({
              amountPaid: giftcardData.Cards[0].Balance,
              giftCardNumber: giftcardData.Cards[0].CardNumber,
              payment: existingOrderObj,
              giftCard: true,
              response: resp.data.data
            }))
          }
          const formattedDateTime = `d_ikey_${moment().format('YYYY-MM-DD')}T${moment().format('HH:mm:ss')}`;
          let logsObject = {
            "store": JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.value,
            "terminal": terminalId,
            "apiType": "Redemption Gift Card",
            "documentno": `${terminalId}/${Number(orderId)}`,
            "amount": summaryObj?.total - summaryObj?.discount,
            "cardNumber": inputFields.cardNumber,
            "idempotencyKey": formattedDateTime,
            "transactionId": giftCradPaymentTranSactionId,
            "approvalCode": "",
            "qwikcilverRequest": object,
            "qwikcilverResponse": giftcardData
          }
          let bodyData = {
            "logs": logsObject,
            "remainingUrl": `/ws/com.openbravo.decathlon.retail.giftcards.qwikCilverLog`,
            "Authorization": `Basic ${window.btoa(basicAuth)}, ${token}`
          };
          await Axios.post(`${URL.GRAVITEE_BASE_URL}/qwikCilverLog`, bodyData, config);
          setLoading(false);
          if (title !== undefined && index !== undefined) {
            setIsModal2Open(false)
            handlePaymentSelection(title, index);
          }
          else {
            localStorage.setItem("order_lastassignednum", Number(orderId));
            navigate("/bill-mode");
          }


        } else {
          setLoading(false);
          message.error(resp.data.data.ResponseMessage);
        }
      }
    } catch (error) {
      setLoading(false);
      navigate("/payment-selection");
      console.log(error);
    }
  }

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;
  const paymentSelectionList = [
    {
      title: "Card",
      img: CardPayment,
      description:
        "This will enable the payment machine and you can insert your card to proceed",
    },
    {
      title: "UPI",
      img: UpiPayment,
      description: "This will enable the QR code and you can scan to pay",
    },
    {
      title: "Gift Card",
      img: GiftCard,
      description: "",
    },
  ];

  const onCancelGiftCard = async () => {
    const tokenDetails = JSON.parse(localStorage.getItem("giftCardToken"))
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    let giftCardData = JSON.parse(localStorage.getItem("giftCardPayment"));
    if (giftCardData !== undefined && giftCardData !== null) {
      let body = {
        "inputType": "1",
        "numberOfCards": 1,
        "Cards": [
          {
            "CardNumber": giftCardData.response.Cards[0].CardNumber,
            "OriginalRequest":
            {
              "OriginalBatchNumber": giftCardData.response.CurrentBatchNumber,
              "OriginalTransactionId": giftCardData.response.TransactionId,
              "OriginalAmount":giftCardData.TotalAmount,
              "OriginalInvoiceNumber": giftCardData.response.Cards[0].InvoiceNumber
            },
            "Reason": giftCardData.response.Notes.split("|")[0].replace(/{|}/g, "")
          }
        ],
      };
      let headers={
        "DateatClient":"2021-09-22T13:17:00",
        "Authorization":`Bearer ${tokenDetails?.AuthToken}` ,
        "TransactionId": giftCardData.response.TransactionId,
        "MerchantOutletNameToOverride": "Decathlon-Test-01",
      }
      try {
        const res = await Axios.post(`${URL.CANCEL_GIFTCARD_URL}`, body, { headers });
        if (res.status === 200) {
          localStorage.removeItem("giftCardPayment");
          setShowGiftcardDetails(false)
        }
      } catch (error) {
        console.log(error);
      }
    }

  }
  return (
    <div>
      <Spin indicator={antIcon} spinning={loading}>
        <Row align='middle' style={{ backgroundColor: "#0072AF", height: "10vh" }}>
          <Col span={6}>
            <img src={Logo} alt="Logo" style={{ position: "relative", left: "20%", height: "50px" }} />
          </Col>
          <Col span={12}>
          </Col>
          <Col span={6} >
            <div style={{ display: "flex", float: "right", alignItems: "center" }}>
              <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
              </span>
              <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
                <LeftCircleOutlined style={{ fontSize: "24px" }} onClick={() => {localStorage.getItem("giftCardPayment") === null ? setShowGiftcardDetails(false) :onCancelGiftCard() }} />
                <span style={{ fontSize: "15px" }}>Back</span>
              </span>
            </div>
          </Col>
        </Row>
        <Row style={{ height: "90vh" }}>
          <Col span={1} />
          <Col span={11} className="mc-main-div">
            <Form layout="vertical" form={form} style={{ paddingBottom: "5vh" }} >
              <Form.Item style={{ paddingBottom: "1.5vh" }} name="cardNumber">
                <div style={{ position: "relative" }} >
                  <Input inputMode="none" className="mc-input-field-gift" style={{ borderColor: "#0580BD" }} autoFocus={true}
                    value={inputFields.cardNumber}
                    onChange={(e) => { onChangeInput(e, "cardNumber") }}
                    onFocus={() => {
                      setInputName("cardNumber");
                    }}
                    autoComplete="off"
                  />
                  <div className="mc-input-lable-gift" style={{ color: "#000" }}>
                    Card Number
                  </div>
                  <div style={{ height: "1vh", fontSize: "2.5vh", color: "red" }}>{cardErrorMsg.flag === false ? "" : cardErrorMsg.message}</div>
                </div>
              </Form.Item>
              <Form.Item style={{ paddingBottom: "1.5vh" }} name="pinNumber">
                <div style={{ position: "relative" }} >
                  <input inputMode="none" className="mc-input-field-gift" style={{ borderColor: "#0580BD" }} autoFocus={true}
                    value={inputFields.pinNumber}
                    type="password"
                    onChange={(e) => { onChangeInput(e, "pinNumber") }}
                    onFocus={() => {
                      setInputName("pinNumber");
                    }} />
                  <div className="mc-input-lable-gift" style={{ color: "#000" }}>
                    Pin Number
                  </div>
                  <div style={{ height: "1vh", fontSize: "2.5vh", color: "red" }}>{pinErrorMsg.flag === false ? "" : pinErrorMsg.message}</div>
                </div>
              </Form.Item>
              <Form.Item style={{ paddingBottom: "1.5vh", position: "relative" }} name="amount">
                <div style={{ position: "relative" }} >
                  <Input inputMode="none" className="mc-input-field-gift" style={{ borderColor: "#0580BD" }} autoFocus={true}
                    value={
                      (summaryObj?.total ?? 0) -
                      (summaryObj?.discount ?? 0) -
                      (JSON.parse(localStorage.getItem("giftCardPayment"))?.amountPaid ?? 0)
                    }
                    onChange={onChangeInput}
                    onFocus={() => {
                      setInputName("amount");
                    }}
                  />
                  <div className="mc-input-lable-gift" style={{ color: "#000" }}>
                    Amount
                  </div>
                </div>
              </Form.Item>
            </Form>
          </Col>
          <Col span={11} className="mc-kb-col" style={{ display: "flex", flexDirection: "column" }}>
            <div className="mc-kb-div">
              <Keyboard
                keyboardRef={(r) => (keyboardRef.current = r)}
                //   onChange={onChangeNumber}
                inputName={inputName}
                onChangeAll={onChangeValues}
                theme={"hg-theme-default mobile"}
                buttonTheme={[
                  {
                    class: "hg-bksp",
                    buttons: "{bksp}",
                  },
                ]}
                layout={{
                  default: ["1 2 3", "4 5 6", "7 8 9", " 0 {bksp}"],
                }}
                display={{
                  "{bksp}": "⌫",
                }}
              />
            </div>
            <Button className="mc-continue-btn" style={{ top: "0px" }} onClick={handleSubmit}>
              Proceed
            </Button>
          </Col>
          <Col span={1} />
        </Row>
        <Modal
          open={isModal1Open}
          onCancel={() => setIsModal1Open(false)}
          closable={false}
          footer={null}
          width={450}
          style={{ top: 170 }}
        >
          <Form form={form1} style={{ padding: "3vh 0" }}>
            <Form.Item name="availableBalance">
              <div style={{ position: "relative" }} >
                <Input style={{ border: "1px solid #545454", borderRadius: "6px", height: "8vh",fontSize: "13px", }} value={availableBalance} />
                <div className="mc-input-lable-gift" style={{ color: "#000", fontSize: "13px",padding:"2.5% 3%" }}>
                  Available Balance
                </div>
              </div>
            </Form.Item>
            <Form.Item name="utilizedBalance">
              <div style={{ position: "relative" }} >
                <Input style={{ border: "1px solid #545454", borderRadius: "6px", height: "8vh",fontSize: "13px", }} value={utilizedBalance} />
                <div className="mc-input-lable-gift" style={{ color: "#000", fontSize: "13px",padding:"2.5% 3%"  }}>
                  Utilized Balance
                </div>
              </div>
            </Form.Item>
            <Form.Item name="expiryDate">
              <div style={{ position: "relative" }} >
                <Input style={{ border: "1px solid #545454", borderRadius: "6px", height: "8vh",fontSize: "13px" }} value={expiryDate} />
                <div className="mc-input-lable-gift" style={{ color: "#000", fontSize: "13px",padding:"2.5% 3%"  }}>
                  Expiry Date
                </div>
              </div>
            </Form.Item>
          </Form>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => { setIsModal1Open(false) }} style={{ border: "none", color: "#0079BA" }}>
              No
            </Button>
            <Button style={{ borderRadius: "6px", background: "#0079BA", fontFamily: "Roboto Condensed", fontWeight: 500 }} type="primary" onClick={() => redeemGiftCard()}>
              Yes
            </Button>
          </div>
        </Modal>
        <Modal
          open={isModal2Open}
          onCancel={() => setIsModal2Open(false)}
          closable={false}
          footer={null}
          style={{ top: 70 }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <span className="ps-main-heading" style={{ fontWeight: 600, marginBottom: "2em", fontSize: "20px" }}>Would you like to pay remaining amount using another payment method?</span>
            <Form form={form2} style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
              <Form.Item name="availableBalance" style={{ width: "70%" }}>
                <div style={{ position: "relative" }} >
                  <Input style={{ border: "1px solid #545454", borderRadius: "6px", height: "7vh",fontSize: "13px", width: "100%" }} value={availableBalance} />
                  <div className="mc-input-lable-gift" style={{ color: "#000", fontSize: "13px",padding:"2.5% 3%" }}>
                    Available Balance
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="utilizedBalance" style={{ width: "70%" }}>
                <div style={{ position: "relative" }} >
                  <Input style={{ border: "1px solid #545454", borderRadius: "6px", height: "7vh",fontSize: "13px" }} value={utilizedBalance} />
                  <div className="mc-input-lable-gift" style={{ color: "#000", fontSize: "13px",padding:"2.5% 3%" }}>
                    Utilized Balance
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="expiryDate" style={{ width: "70%" }}>
                <div style={{ position: "relative" }} >
                  <Input style={{ border: "1px solid #545454", borderRadius: "6px", height: "7vh",fontSize: "13px" }} value={expiryDate} />
                  <div className="mc-input-lable-gift" style={{ color: "#000", fontSize: "13px",padding:"2.5% 3%" }}>
                    Expiry Date
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="remainigDue" style={{ width: "70%" }}>
                <div style={{ position: "relative" }} >
                  <Input style={{ border: "1px solid #545454", borderRadius: "6px", height: "7vh",fontSize: "13px" }} value={remainigDue} />
                  <div className="mc-input-lable-gift" style={{ color: "#000", fontSize: "13px",padding:"2.5% 3%" }}>
                    Remaining Amount To Pay
                  </div>
                </div>
              </Form.Item>
            </Form>
            <Row>
              {paymentSelectionList.map((item, index) => (
                <Col key={index} span={8} onClick={() => redeemGiftCard(item.title, index)} style={{ height: "20vh", border: "1px solid rgba(0, 0, 0, 0.06)", borderRadius: "1vh", padding: "1.5vh 0" }}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <span style={{ fontSize: "1.5vw", fontWeight: "600", alignSelf: "center" }}>{item.title}</span>
                    {multilingual === "true" && (
                      <span style={{ fontSize: "15px", fontWeight: "600", }}>
                        {item.title === "Card" ? t('with-card') : t('with-upi')}
                      </span>
                    )}
                    <img src={item.img} alt="" style={{ height: "9vh", width: "100%", padding: "0 1em" }} />
                    {index === 0 ?
                      <span style={{ fontSize: "0.7vw", textAlign: "center", fontWeight: 600, color: "#000000 63%", marginTop: "1vh" }}>This will enable the payment machine. Insert card to proceed with your payment.</span> : index === 1 ?
                        <span style={{ fontSize: "0.7vw", textAlign: "center", fontWeight: 600, color: "#000000 63%", marginTop: "1vh" }}>This will enable QR CODE. Scan to proceed with your paymemt</span> :
                        <div> <span style={{ fontSize: "0.7vw", textAlign: "center", fontWeight: 600, color: "#000000 63%", marginTop: "1vh" }}>Redeem Amount</span> <span style={{ fontSize: "1vw", textAlign: "center", paddingTop: "0vh", fontWeight: 600, color: "#000000 63%" }}>{JSON.parse(localStorage.getItem("giftCardPayment"))?.amountPaid > 0 ? `₹${JSON.parse(localStorage.getItem("giftCardPayment"))?.amountPaid }`: ""}</span></div>
                    }
                  </div>
                </Col>
              ))}
            </Row>
          </div>

        </Modal>
        <Modal
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={null} // Remove the default footer
        >
          <div>
            <p style={{ fontWeight: 600, fontSize: '3vh' }}>
              Your Gift Card balance is ₹ {giftCardBalacnce}. This is less than the bill amount of ₹{
                (summaryObj?.total ?? 0) -
                (summaryObj?.discount ?? 0) -
                (JSON.parse(localStorage.getItem("giftCardPayment"))?.amountPaid ?? 0)
              }. Please pay the remaining amount using Card or UPI.
            </p>
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => { setIsModalOpen(false) }} style={{ marginRight: "2vw" }}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => checkBalanceData > 0 ? setIsModalOpen(false) : setShowGiftcardDetails(false) && setSelectedValue("")}>
              Okay
            </Button>
          </div>
        </Modal>
      </Spin>
    </div >
  );
};

export default GiftCardPayment;
