import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Input, Card, Button, message, Modal } from "antd";
import { SearchOutlined, PlusOutlined, LeftCircleOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import CloseIcon from "../../assets/SCO_asset/home/close.svg";
import leftArrow from "../../assets/SCO_asset/home/leftArrow.png";
import rightArrow from "../../assets/SCO_asset/home/rightArrow.png";
import db from "../../database";
import ScannerComponent from "../scanner";
import loadingGif from "../../assets/SCO_asset/home/loading.gif";
import bag1 from "../../assets/SCO_asset/home/bag1.png";
import bag2 from "../../assets/SCO_asset/home/bag2.png";
import cartGif from "../../assets/SCO_asset/home/Kart Droping.gif";
import noImage from "../../assets/SCO_asset/home/No Image.png";
import { v4 as uuid } from "uuid";
import moment from "moment";
import Axios from "axios";
import URL from "../../app/server.json";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./home.css";
import { useTranslation } from 'react-i18next';
import { isEmpty } from "lodash";

const Home = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [tempDup, setTempDup] = useState([]);
  const [selectedItems, setSelectedItems] = useState(() => {
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null || selectedProducts === undefined ? {} : selectedProducts;
    if (selectedProducts.productsData !== undefined || selectedProducts.productData !== null) {
      return selectedProducts.productsData;
    } else {
      return [];
    }
  });
  const [search, setSearch] = useState("");
  const [cotton, setCotton] = useState([]);
  const [paper, setPaper] = useState([]);
  const [modalFlag, setModalFlag] = useState(false);
  const [modalItem, setModalItem] = useState({});
  const [summaryObj, setSummaryObj] = useState({ items: 0, count: 0, discount: 0, total: 0 });
  const [comboDiscountData, setComboDiscountData] = useState([]);
  const [gifFlag, setGifFlag] = useState(true);
  const [multiBuyData, setMultiBuyData] = useState([]);
  const [routeFlag, setRouteFlag] = useState(false);
  const [name, setName] = useState("");
  const [paperFlag, setPaperFlag] = useState(true);
  const [cottonFlag, setCottonFlag] = useState(true);
  const [timeoutFlag, setTimeoutFlag] = useState(false);
  const [time, setTime] = useState(300);
  const [keyboardvisible, setKeyboardvisible] = useState(false);
  const [finalArray, setFinalArray] = useState([]);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [isScrolledToStart, setIsScrolledToStart] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [scan, setScan] = useState(false);
  const [showProducts, setShowProducts] = useState([]);
  const [removeFlag, setRemoveFlag] = useState(true);
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual")
  let index = 0;
  const apiKey = URL.GRAVITEE_API_KEY;

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setGifFlag(false);
    }, 2000);
    if (scan === false && index === 0) {
      callStart();
      setScan(true);
      index++;
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(time - 1);
    }, 1000);
    if (time === 30) {
      setTimeoutFlag(true);
    }
    if (time === 0) {
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      selectedProducts = selectedProducts === null ? {} : selectedProducts;
      selectedProducts.productsData = [];
      localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
      callStop();
      navigate("/customer-login");
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    let tempName = localStorage.getItem("surname");
    let paperflag = localStorage.getItem("paperBag");
    let cottonflag = localStorage.getItem("cottonBag");
    setPaperFlag(paperflag);
    setCottonFlag(cottonflag);
    setName(tempName);
    (async () => {
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      selectedProducts = selectedProducts === null ? {} : selectedProducts;
      selectedProducts.productsData = selectedItems;
      localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
      const comboDiscount = await db.comboData.toArray();
      const multiBuy = await db.multiBuyData.toArray();
      const nonRfidData = await db.nonRfidData.toArray();
      const cotton = await db.cottonData.toArray();
      const paper = await db.paperData.toArray();
      setComboDiscountData(comboDiscount);
      setMultiBuyData(multiBuy);
      setTempDup(nonRfidData[0]);
      setCotton(cotton[0]);
      setPaper(paper[0]);
    })();
  }, []);

  useEffect(() => {
    const handleCustomEvent = (event) => {
      if (event.detail.key === 'dynamoDB') {
        const dynamoDBValue = JSON.parse(event.detail.newValue);
        console.log('Parsed value:', dynamoDBValue);
        if (!dynamoDBValue) {
          setGifFlag(true);
          (async () => {
            console.log(dynamoDBValue)
            const nonRfidData = await db.nonRfidData.toArray();
            console.log(nonRfidData)
            setTempDup(nonRfidData[0]);
            setGifFlag(false);
          })();
        }
      }
    };
    window.addEventListener('customStorageChange', handleCustomEvent);
    return () => {
      window.removeEventListener('customStorageChange', handleCustomEvent);
    };
  }, []);

  useEffect(() => {
    let items = 0;
    let count = 0;
    let discount = 0;
    let total = 0;
    let arr = selectedItems?.length > 0 ? [...selectedItems] : [];
    arr.map((item, ind) => {
      items = items + ind + 1;
      count = count + item.count;
      discount = discount + ((item.sale_price) * item.percentage) / 100;
      total = total + ((item.sale_price));
      if (item.id === "2365103") {
        item.imageUrl = bag1;
      } else if (item.id === "2991796") {
        item.imageUrl = bag2
      }
      if (item.percentage > 0) {
        item.discountPrice = Number((Number(item.sale_price) - (Number(item.sale_price) * item.percentage / 100)).toFixed(2));
        item.discountValue = Number((Number(item.sale_price) * item.percentage / 100).toFixed(2));
        item.offer = true
      } else {
        item.discountPrice = Number(item.sale_price);
        item.discountValue = 0;
        item.offer = false;
      }
      let taxTotal = 0;
      let itemKey = 0;
      JSON.parse(item.tax).map((e) => {
        itemKey = e.rate + itemKey
      });
      const baseAmount = item.discountPrice / (1 + itemKey / 100);
      const taxableAmount = item.discountPrice - baseAmount;
      item.baseAmount = baseAmount;
      item.taxableAmount = Number(taxableAmount.toFixed(6));
      item.isgst = Number((baseAmount * item.tax[0].rate / 100).toFixed(6));
      item.icgst = (item.taxableAmount - item.isgst).toFixed(2);
      taxTotal = taxTotal + (item.discountPrice - (item.discountPrice / (1 + itemKey / 100)))
      taxTotal = Math.round(taxTotal * 100) / 100;
      item.taxKey = itemKey;
      item.taxValue = taxTotal;
      item.epcCode = isEmpty(item.epcCode) ? "" : item.epcCode;
    });
    setSummaryObj({ items: items, count: count, discount: discount, total: total });
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    selectedProducts.productsData = arr;
    localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
    let counts = {};
    selectedItems.forEach(item => {
      let id = item.id;
      counts[id] = (counts[id] || 0) + 1;
    });
    const newArray = [];
    const seenIds = new Set();
    selectedItems.forEach(obj => {
      if (!seenIds.has(obj.id)) {
        obj[`discountSalePrice`] = Number(((obj.sale_price) - ((obj.sale_price) * obj.percentage / 100)).toFixed(2))
        newArray.push(obj);
        seenIds.add(obj.id);
      } else {
        newArray.map((item) => {
          if (obj.id === item.id) {
            item[`discountSalePrice`] = item[`discountSalePrice`] + Number(((obj.sale_price) - ((obj.sale_price) * obj.percentage / 100)).toFixed(2))
          }
        })
      }
    });
    Object.entries(counts).map((key) => {
      newArray.map((item) => {
        if (key[0] === item.id) {
          if (key[1] > 0) {
            item.count = key[1];
          }
        }
      })
    });
    let tempArray = [...newArray];
    let taxArray = [];
    let uniqueRates = new Set();
    tempArray.forEach(obj => {
      JSON.parse(obj.tax).forEach(taxObj => {
        if (!uniqueRates.has(taxObj.rate)) {
          uniqueRates.add(taxObj.rate);
          taxArray.push({ key: taxObj.rate * 2, count: 0, price: 0, gst: [], taxPrice: 0, icgst: 0, isgst: 0, discountSalePrice: 0, taxableAmount: 0, basePrice: 0 });
        }
      });
    });
    taxArray.map(item => {
      tempArray.map(e => {
        if (e.taxKey === item.key) {
          const baseAmount = e.discountSalePrice / (1 + e.taxKey / 100);
          const taxableAmount = (e.discountSalePrice - baseAmount).toFixed(2);
          e.taxPrice = Number(taxableAmount).toFixed(6);
          e.isgst = Number((baseAmount * e.taxKey / 2 / 100).toFixed(2));
          e.icgst = Number(e?.taxPrice - e.isgst).toFixed(2);
          item.count = e.count;
          item.basePrice = item.basePrice + (e.discountSalePrice - e.taxableAmount * e.count);
          item.price = item.price + e.discountSalePrice;
          item.gst = e.tax;
          item.taxPrice = Number((item.taxPrice + e.taxableAmount * e.count).toFixed(2));
          item.isgst = Number((item.isgst + e.isgst));
          item.icgst = Number((item.taxPrice - item.isgst).toFixed(2));
          item.discountSalePrice = item.discountSalePrice + e.discountSalePrice;
          const baseAmount1 = item.discountSalePrice / (1 + (e.taxKey / 100));
          item.GroupTax = Number((item.discountSalePrice - baseAmount1.toFixed(6)).toFixed(2));
          item.GroupSGST = Number((baseAmount1 * (e.taxKey / 2 / 100)).toFixed(2));
          item.GroupCGST = Number((item.GroupTax - item.GroupSGST).toFixed(2));
          item.taxableAmount = item.taxableAmount + e.taxableAmount;
          item.taxDifference = (parseFloat(item.taxPrice).toFixed(2) - parseFloat(item.GroupTax)).toFixed(2);
          item.totolTaxDiff = (parseFloat(item.GroupCGST) - parseFloat(item.GroupSGST)).toFixed(2)
          item.taxDifferenceCGST = Number(item.GroupSGST - item.GroupCGST).toFixed(2);
          e.swap = false;
          item.verify = true;
        };
      });
    });
    let obj = {};
    obj.newArray = newArray;
    obj.taxArray = taxArray;
    localStorage.setItem("taxData", JSON.stringify(obj));
    setFinalArray(newArray);
    if (removeFlag === false) {
      setTimeout(() => {
        setShowProducts(arr);
      }, 1);
      setRemoveFlag(true);
    } else {
      setTimeout(() => {
        setShowProducts(arr);
      }, 500);
    };
  }, [selectedItems]);

  const handleSubmit = () => {
    const token = localStorage.getItem("crm_token");
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    const tempData = JSON.parse(localStorage.getItem("customerData"));
    let emailDecode = tempData?.email !== null ? window.btoa(tempData?.email) : "";
    let phonDecode = tempData?.phoneNo !== null ? window.btoa(tempData?.phoneNo) : "";
    let headers = {
      "Content-Type": "application/json",
    }
    headers[URL.key_NAME] = apiKey;
    Axios({
      url: `${URL.GRAVITEE_BASE_URL}/customerLogin`,
      method: "GET",
      headers: headers,
      params: {
        remainingUrl: `?appCode=SCO&email=${emailDecode}&mobile=${phonDecode}`
      }
    }).then(async (response) => {
      if (response.data.statusCode === 200) {
        let customerData = { phoneNo: tempData?.phoneNo, email: tempData?.email }
        localStorage.setItem('customerData', JSON.stringify(customerData));
        let currentDate = moment(new Date()).format("YYYY-MM-DD");
        let currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        selectedProducts.customerData = response.data.data;
        localStorage.setItem('userData', JSON.stringify(response.data.data));
        localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
        localStorage.setItem('customerData', JSON.stringify(customerData));
        localStorage.setItem('orderDate', currentDate);
        localStorage.setItem('salebegintime', currentDateTime);
      };
    });
    callStop();
    navigate('/payment-selection');
  };

  const callStart = () => {
    return new Promise(async (callStartSuccess, callStartFailure) => {
      let timeStamp = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
      try {
        const response = await Axios({
          url: "http://localhost:1234/StartReading",
          method: "POST",
          data: { StartReading: "true", timeStamp: timeStamp }
        });
        callStartSuccess(response);
      } catch (error) {
        callStartFailure(error);
      }
    });
  };

  const callStop = () => {
    return new Promise(async (callStopSuccess, callStopFailure) => {
      let timeStamp = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
      try {
        const response = await Axios({
          url: "http://localhost:1234/StopReading",
          method: "POST",
          data: { StopReading: "true", timeStamp: timeStamp }
        });
        callStopSuccess(response);
      } catch (error) {
        callStopFailure(error);
      }
    });
  };

  const padleft = (nr, n, str) => {
    return Array(n - String(nr).length + 1).join(str || '0') + nr;
  }

  const decTobin = (dec) => {
    return (dec >>> 0).toString(2)
  }

  const convertEpcToItemCode = (inputString) => {
    setTime(300);
    inputString = inputString.toString();
    let alreadyScanned = "N";
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    if (selectedProducts.productsData !== undefined || selectedProducts.productData !== null) {
      selectedProducts?.productsData?.map(item => {
        for (let ind = 1; ind < item?.count + 1; ind++) {
          if (item[`epcCode`] === inputString) {
            alreadyScanned = "Y";
            message.info("Product Already Scanned");
          }
        };
      })
    };
    if (alreadyScanned !== "Y") {
      let binaryString = new String();
      for (let i = 0; i < inputString.length; i++) {
        const hexadigit = inputString.substring(i, i + 1);
        const decimal = parseInt(hexadigit, 16);
        const binary = decTobin(decimal);
        const fourDigitBinary = padleft(binary, 4, '0');
        binaryString = binaryString.concat(fourDigitBinary);
      };
      binaryString = binaryString.toString();
      const binaryPartition = binaryString.substring(11, 14);
      const partition = parseInt(binaryPartition, 2);
      const numberOfBitsOfItemReference = partition * 4;
      const binaryCompanyPrefix = binaryString.substring(14, 58 - numberOfBitsOfItemReference);
      const binaryItemReference = binaryString.substring(58 - numberOfBitsOfItemReference, 58);
      const binarySerialNumber = binaryString.substring(58, 96);
      const companyPrefix = padleft(parseInt(binaryCompanyPrefix, 2).toString(), 13 - partition, '0');
      const itemReference = padleft(parseInt(binaryItemReference, 2).toString(), partition, '0');
      let serialNumber = parseInt(binarySerialNumber, 2).toString();
      serialNumber = serialNumber.toString().padStart(12, '0');
      const eanWithoutCheckDigit = companyPrefix + itemReference;
      let j = eanWithoutCheckDigit.length - 1, oddSum = 0, evenSum = 0;
      while (j > 0) {
        oddSum = oddSum + parseInt(eanWithoutCheckDigit.charAt(j).toString());
        evenSum = evenSum + parseInt(eanWithoutCheckDigit.charAt(j - 1).toString());
        j = j - 2;
      }
      const totalSum = oddSum * 3 + evenSum;
      const nearestTenMultiple = Math.ceil(parseFloat((totalSum / 10).toString())) * 10;
      const checkDigit = parseInt((nearestTenMultiple - totalSum).toString());
      const finalEanNumber = eanWithoutCheckDigit.startsWith('0') ?
        eanWithoutCheckDigit.substring(1, eanWithoutCheckDigit.length) + checkDigit
        : eanWithoutCheckDigit + checkDigit;
      rfidDiscountCalculation(finalEanNumber, inputString);
    }
  };

  const consvertUpcToItemCode = (upc) => {
    setTime(300);
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    rfidDiscountCalculation(upc);
  };

  const onScannerInput = (data) => {
    setTime(300);
    if (data.length === 24) {
      convertEpcToItemCode(data.toString())
    } else {
      consvertUpcToItemCode(data);
    }
  };

  const handleSelectedItems = (item) => () => {
    setTime(300);
    setModalFlag(true);
    discountCalculation(item.id)
  };

  const handleRemoveItems = (ean) => () => {
    setTime(300);
    let array = selectedItems?.length > 0 ? [...selectedItems] : [];
    let arr = array.filter(item => item.orderId !== ean);
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    let popArr = selectedProducts?.productsData?.filter(item => item.orderId !== ean);
    selectedProducts.productsData = popArr;
    let currentDate = moment(new Date()).format("YYYY-MM-DD");
    comboDiscountData.map((item) => {
      if (item.StartingDate <= currentDate && (currentDate <= item.EndingDate || item.EndingDate === "" || item.EndingDate === null)) {
        let newArray = []
        let index = []
        if (item.ComboFamily !== null) {
          JSON.parse(item.ComboFamily).map((ele, ind) => {
            let array = [];
            ele.comboProducts?.map(x => {
              if (x.isActive === "Y") {
                let tempData1 = []
                arr.map((e) => {
                  if (x.combProduct === e.id) {
                    tempData1.push(ind)
                  }
                })
                if (tempData1.length >= ele.quantity) {
                  arr.map((e) => {
                    if (x.combProduct === e.id) {
                      x.sale_price = Number(e.sale_price)
                      x.count = e.count
                      e.percentage = ele.percentage
                      index.push(ind)
                      array.push({
                        key: ind,
                        value: e
                      })
                    }
                  })
                }
              }
            })
            if (array.length > 0) {
              newArray.push(array)
            }
          });
        };
        let arrayLength = 0;
        newArray.map((et) => {
          if (arrayLength < et.length) {
            arrayLength = et.length
          }
        });
        let mainArray = [];
        for (let i = 0; i < arrayLength; i++) {
          let arr1 = []
          newArray.map((item, ind) => {
            if (i < item.length) {
              let orderId = uuid().replace(/-/g, "").toUpperCase();
              item[i].orderId = orderId
              return arr1.push(item[i])
            }
          });
          let dummy1 = new Set(arr1)
          let dummy = [...dummy1]
          if (JSON.parse(item.ComboFamily).length === dummy.length) {
            let tempArray = [...arr1];
            tempArray.filter((item, index) => arr.indexOf(item) === index);
            tempArray.map(ele => { ele.value.orderId = ele.orderId });
          }
          mainArray.push(...arr1);
        }
        const newData = [...mainArray]
        newData.map((e) => {
          if (e.orderId === e.value.orderId) {
            e.discountName = "Scov2";
            e.value.discountApply = "Y";
            e.value.priority = item.priority;
            e.value.discount = (Number(e.value?.sale_price) * e.value.percentage * e.value.count) / 100;
          } else {
            e.value.percentage = 0;
          }
        })
      }
    });
    multiBuyData.map((item) => {
      if (item.StartingDate <= currentDate && (currentDate <= item.EndingDate || item.EndingDate === "" || item.EndingDate === null)) {
        let total = 0;
        if (item.OfferProduct !== null) {
          JSON.parse(item.OfferProduct).map((ele) => {
            arr.map((e) => {
              if (ele.isActive === "Y") {
                if (ele.productId === e.id) {
                  total = total + Number(e.sale_price)
                }
              }
            })
          });
        };
        if (total >= Number(item.discountTotalReceipt)) {
          JSON.parse(item.OfferProduct).map((ele) => {
            arr.map((e) => {
              if (ele.isActive === "Y") {
                if (ele.productId === e.id) {
                  if (e.percentage === 0 || e.percentage === undefined) {
                    let orderId = uuid().replace(/-/g, "").toUpperCase();
                    e.orderId = orderId
                    e.discount = (Number(e.sale_price) * Number(item.totalPercentageDiscount) * e.count) / 100
                    if (item.ApplyNext === true) {
                      e.percentage = Number(item.totalPercentageDiscount);
                    } else {
                      e.percentage = Number(item.totalPercentageDiscount);
                      e.priority = Number(item.priority);
                    }
                    e.discountName = "Multi Buy";
                    e.discountApply = "Y"
                  }
                }
              }
            })
          })
        } else {
          if (item.OfferProduct !== null) {
            JSON.parse(item.OfferProduct).map((ele) => {
              arr.map((e) => {
                if (ele.isActive === "Y") {
                  if (ele.productId === e.id) {
                    if (e.percentage === 0 || e.percentage === undefined) {
                    } else if (e.discountName === "Multi Buy" && total <= item.discountTotalReceipt) {
                      e.percentage = 0;
                    }
                  }
                }
              })
            })
          };
        }
      }
    });
    arr.map((item) => {
      if (item?.discountApply !== "Y") {
        let orderId = uuid().replace(/-/g, "").toUpperCase();
        item.orderId = orderId
        item.percentage = 0
      }
      item.count = 1;
    });
    localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
    setRemoveFlag(false);
    setSelectedItems(arr);
  };

  const rfidDiscountCalculation = (search, inputString) => {
    let arr = selectedItems?.length > 0 ? [...selectedItems] : [];
    db.productData
      .where("ean")
      .equals(search)
      .toArray()
      .then((response) => {
        response[0].epcCode = inputString;
        setModalFlag(true);
        setModalItem(response[0]);
        arr.unshift(response[0]);
        let currentDate = moment(new Date()).format("YYYY-MM-DD");
        comboDiscountData.map((item) => {
          if (item.StartingDate <= currentDate && (currentDate <= item.EndingDate || item.EndingDate === "" || item.EndingDate === null)) {
            let newArray = []
            let index = []
            JSON.parse(item.ComboFamily).map((ele, ind) => {
              let array = [];
              ele.comboProducts.map(x => {
                if (x.isActive === "Y") {
                  let tempData1 = []
                  arr.map((e) => {
                    if (x.combProduct === e.id) {
                      tempData1.push(ind)
                    }
                  })
                  if (tempData1.length >= ele.quantity) {
                    arr.map((e) => {
                      if (x.combProduct === e.id) {
                        x.sale_price = Number(e.sale_price)
                        x.count = e.count
                        e.percentage = ele.percentage
                        index.push(ind)
                        array.push({
                          key: ind,
                          value: e
                        })
                      }
                    })
                  }
                }
              })
              if (array.length > 0) {
                newArray.push(array)
              }
            });
            let arrayLength = 0;
            newArray.map((et) => {
              if (arrayLength < et.length) {
                arrayLength = et.length
              }
            });
            let mainArray = [];
            for (let i = 0; i < arrayLength; i++) {
              let arr1 = []
              newArray.map((item, ind) => {
                if (i < item.length) {
                  let orderId = uuid().replace(/-/g, "").toUpperCase();
                  item[i].orderId = orderId
                  return arr1.push(item[i])
                }
              });
              let dummy1 = new Set(arr1)
              let dummy = [...dummy1]
              if (JSON.parse(item.ComboFamily).length === dummy.length) {
                let tempArray = [...arr1];
                tempArray.filter((item, index) => arr.indexOf(item) === index);
                tempArray.map(ele => { ele.value.orderId = ele.orderId });
              }
              mainArray.push(...arr1);
            }
            const newData = [...mainArray]
            newData.map((e) => {
              if (e.orderId === e.value.orderId) {
                e.discountName = "Scov2";
                e.value.discountApply = "Y";
                e.value.priority = item.priority;
                e.value.discount = (Number(e.value?.sale_price) * e.value.percentage * e.value.count) / 100;
              } else {
                e.value.percentage = 0;
              }
            })
          };
        });
        multiBuyData.map((item) => {
          if (item.StartingDate <= currentDate && (currentDate <= item.EndingDate || item.EndingDate === "" || item.EndingDate === null)) {
            let total = 0;
            if (item.OfferProduct !== null) {
              JSON.parse(item.OfferProduct).map((ele) => {
                arr.map((e) => {
                  if (ele.isActive === "Y") {
                    if (ele.productId === e.id) {
                      total = total + Number(e.sale_price)
                    }
                  }
                })
              });
            };
            if (total >= Number(item.discountTotalReceipt)) {
              JSON.parse(item.OfferProduct).map((ele) => {
                arr.map((e) => {
                  if (ele.isActive === "Y") {
                    if (ele.productId === e.id) {
                      if (e.percentage === 0 || e.percentage === undefined) {
                        let orderId = uuid().replace(/-/g, "").toUpperCase();
                        e.orderId = orderId
                        e.discount = (Number(e.sale_price) * Number(item.totalPercentageDiscount) * e.count) / 100
                        if (item.ApplyNext === "Y") {
                          e.percentage = Number(item.totalPercentageDiscount);
                        } else {
                          e.percentage = Number(item.totalPercentageDiscount);
                          e.priority = Number(item.priority);
                        }
                        e.discountName = "Multi Buy";
                        e.discountApply = "Y"
                      }
                    }
                  }
                })
              })
            }
          }
        });
        arr.map((item) => {
          if (item?.discountApply !== "Y") {
            let orderId = uuid().replace(/-/g, "").toUpperCase();
            item.orderId = orderId
            item.percentage = 0
          }
          item.count = 1;
        });
        setSelectedItems(arr);
        setTimeout(() => {
          setModalItem({})
          setModalFlag(false);
        }, 500);
      });
  };

  const discountCalculation = (search) => {
    let arr = selectedItems?.length > 0 ? [...selectedItems] : [];
    let productsWithoutEAN = {};
    db.productData
      .where("id")
      .equals(search)
      .toArray()
      .then((response) => {
        if (response.length > 0) {
          productsWithoutEAN = response.map((product) => {
            return {
              ...product, ean: "", //removing the ean value
            }
          });
          setModalFlag(true);
        };
        setModalItem(productsWithoutEAN[0]);
        arr.unshift(productsWithoutEAN[0]);
        let currentDate = moment(new Date()).format("YYYY-MM-DD");
        comboDiscountData.map((item) => {
          if (item.StartingDate <= currentDate && (currentDate <= item.EndingDate || item.EndingDate === "" || item.EndingDate === null)) {
            let newArray = []
            let index = []
            if (item.ComboFamily !== null) {
              JSON.parse(item.ComboFamily)?.map((ele, ind) => {
                let array = [];
                ele.comboProducts?.map(x => {
                  if (x.isActive === "Y") {
                    let tempData1 = []
                    arr.map((e) => {
                      if (x.combProduct === e.id) {
                        tempData1.push(ind)
                      }
                    })
                    if (tempData1.length >= ele.quantity) {
                      arr.map((e) => {
                        if (x.combProduct === e.id) {
                          x.sale_price = Number(e.sale_price)
                          x.count = e.count
                          e.percentage = ele.percentage
                          index.push(ind)
                          array.push({
                            key: ind,
                            value: e
                          })
                        }
                      })
                    }
                  }
                })
                if (array.length > 0) {
                  newArray.push(array)
                }
              });
            };
            let arrayLength = 0;
            newArray.map((et) => {
              if (arrayLength < et.length) {
                arrayLength = et.length
              }
            });
            let mainArray = [];
            for (let i = 0; i < arrayLength; i++) {
              let arr1 = []
              newArray.map((item, ind) => {
                if (i < item.length) {
                  let orderId = uuid().replace(/-/g, "").toUpperCase();
                  item[i].orderId = orderId
                  return arr1.push(item[i])
                }
              });
              let dummy1 = new Set(arr1)
              let dummy = [...dummy1]
              if (JSON.parse(item.ComboFamily).length === dummy.length) {
                let tempArray = [...arr1];
                tempArray.filter((item, index) => arr.indexOf(item) === index);
                tempArray.map(ele => { ele.value.orderId = ele.orderId });
              }
              mainArray.push(...arr1);
            }
            const newData = [...mainArray]
            newData?.map((e) => {
              if (e.orderId === e.value.orderId) {
                e.value.discountName = "Scov2";
                e.value.discountApply = "Y";
                e.value.priority = Number(item.priority);
                e.value.discount = (Number(e.value?.sale_price) * e.value.percentage * e.value.count) / 100;
              } else {
                e.value.percentage = 0;
              }
            })
          };
        });
        multiBuyData.map((item) => {
          if (item.StartingDate <= currentDate && (currentDate <= item.EndingDate || item.EndingDate === "" || item.EndingDate === null)) {
            let total = 0;
            if (item.OfferProduct !== null) {
              JSON.parse(item.OfferProduct).map((ele) => {
                arr.map((e) => {
                  if (ele.isActive === "Y") {
                    if (ele.productId === e.id) {
                      total = total + Number(e.sale_price)
                    }
                  }
                })
              });
            };
            if (total >= Number(item.discountTotalReceipt)) {
              JSON.parse(item.OfferProduct).map((ele) => {
                arr.map((e) => {
                  if (ele.isActive === "Y") {
                    if (ele.productId === e.id) {
                      if (e.percentage === 0 || e.percentage === undefined) {
                        let orderId = uuid().replace(/-/g, "").toUpperCase();
                        e.orderId = orderId
                        e.discount = (Number(e.sale_price) * Number(item.totalPercentageDiscount) * e.count) / 100
                        if (item.ApplyNext === "Y") {
                          e.percentage = Number(item.totalPercentageDiscount);
                        } else {
                          e.percentage = Number(item.totalPercentageDiscount);
                          e.priority = Number(item.priority);
                        }
                        e.discountName = "Multi Buy";
                        e.discountApply = "Y"
                      }
                    }
                  }
                })
              })
            }
          }
        });
        arr.map((item) => {
          if (item?.discountApply !== "Y") {
            let orderId = uuid().replace(/-/g, "").toUpperCase();
            item.orderId = orderId
            item.percentage = 0
          }
          item.count = 1;
        });
        setSelectedItems(arr);
        setTimeout(() => {
          setModalItem({})
          setModalFlag(false);
          setKeyboardvisible(false);
        }, 500);
      }).catch(err => {
        message.warning("Item not found");
        setTimeout(() => {
          setKeyboardvisible(false);
        }, 500);
      });
  };

  const handleSearchItems = () => {
    setTime(300);
    discountCalculation(search)
    setSearch("");
  };

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft = element.scrollLeft + step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const onKeyPressNumber = (button) => {
    if (button === "{enter}") {
      handleSearchItems()
    }
    else if (button === "{backspace}") {
      if (cursorPosition !== 0) {
        setSearch(search.slice(0, -1));
        setCursorPosition(cursorPosition - 1)
      }
      else {
        setSearch(search)
      }
    }
    else if (button === "{number}" || button === "#+=") {
      setSearch(search);
    }
    else if (button !== "{enter}" || button !== "{bksp}" || button !== "{number}") {
      let value = `${search}${button}`
      setSearch(value)
    }
    if (button === "{space}") handleSpace();
  };

  const handleSpace = () => {
    setSearch(search + ' ');
  };

  const hanldeCloseKeyboard = (ev) => {
    const newCursorPosition = ev.target.selectionStart;
    setCursorPosition(newCursorPosition)
    if (ev.target.id === "2") {
      setKeyboardvisible(true);
    } else {
      setKeyboardvisible(false);
    };
  };

  const handleScroll = (event) => {
    setIsScrolledToStart(true)
    const element = event.target;
    const tolerance = 1;

    if (element.scrollWidth - element.scrollLeft <= element.clientWidth + tolerance) {
      setIsScrolledToEnd(true);
    } else if (element.scrollLeft === 0) {
      setIsScrolledToStart(false);
    } else {
      setIsScrolledToEnd(false);
    }
  };

  return (
    <div>
      {scan === true ? <ScannerComponent onScan={onScannerInput} /> : ""}
      {selectedItems === undefined || selectedItems?.length === 0 ?
        <Modal open={gifFlag} type="dashed" footer={null} closable={false} width={300} >
          <img src={loadingGif} alt="" textAlign="center" width="100%" />
        </Modal> :
        ""
      }
      <Row id="1" onClick={hanldeCloseKeyboard} align='middle' style={{ backgroundColor: "#0072AF", padding: "1em 0" }}>
        <Col span={6}>
          <img src={Logo} alt="Logo" style={{ position: "relative", left: "11%", height: "50px" }} onDoubleClick={() => { setRouteFlag(true); setTime(300); }} />
        </Col>
        <Col span={12} >
          <Input
            inputMode="none"
            id="2"
            autoComplete="off"
            placeholder="Search 70 sports and 5000 products"
            onChange={(e) => setSearch(e.target.value)}
            onPressEnter={handleSearchItems}
            value={search}
            onClick={hanldeCloseKeyboard}
            suffix={<SearchOutlined onClick={handleSearchItems} />}
            style={{ boxShadow: "2px 2px 4px -3px black", width: "100%", height: "40px", borderRadius: "6px", fontWeight: "400" }}
          />
        </Col>
        <Col span={6} >
          <div style={{ display: "flex", float: "right", alignItems: "center" }}>
            <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
            </span>
            <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
              <LeftCircleOutlined style={{ fontSize: "24px" }} onClick={() => { setRouteFlag(true); setTime(300); }} />
              <span style={{ fontSize: "15px" }}>Back</span>
            </span>
          </div>
        </Col>
      </Row>
      {showProducts?.length > 0 ?
        <div id="3" onClick={hanldeCloseKeyboard}>
          <div style={{ height: "5vh", padding: "2vh 0 0 3vw", fontSize: "2vh", fontWeight: 700, backgroundColor: "#F7F8FA" }}>Welcome {name}!</div>
          <div style={{ backgroundColor: "#F7F8FA", textAlign: "center", height: "55.5vh", padding: "0 2.4rem 0rem 2.4rem" }}>
            <Row style={{ height: "100%" }} >
              <Col span={16}>
                <Scrollbars autoHide >
                  <Row style={{ marginBottom: 5 }}>
                    {
                      finalArray?.map(item => {
                        return (
                          <Col flex="inherit" style={{ position: "relative", marginRight: "1.5rem", marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                            <img src={CloseIcon} alt="" className="close-icon" style={{ cursor: "pointer", left: "23vh", position: "absolute", top: "-2vh" }} onClick={handleRemoveItems(item.orderId)} />
                            <Card bodyStyle={{ paddingTop: "0.5vh", paddingBottom: "0", padding: "0" }} style={{ width: "24vh" }} className="suggest-item-card-home">
                              <div style={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex", padding: "1.5vh 0" }}>
                                <img src={item.imageUrl === "UNAVAILABLE" ? noImage : item.imageUrl} alt="" style={{ height: "7rem", width: "8rem", marginTop: "1.8vh", paddingBottom: "0.8vh", }} />
                              </div>
                              <span style={{ fontSize: "14px", fontWeight: 700, textAlign: "left", }}>{window.innerWidth <= 1024 && window.innerHeight <= 768 ? item?.name?.slice(0, 21).concat("...") : item?.name?.slice(0, 21).concat("...")}</span>
                              <br />
                              Item code: <span style={{ fontSize: "14px", fontWeight: 700, textAlign: "left" }}>{item.id}</span>
                              <Row style={{ height: "4vh", display: "flex", alignItems: "center" }}>
                                <Col span={16} style={{ backgroundColor: "yellow", fontSize: "2vh", fontWeight: 600, height: "100%", borderBottomLeftRadius: "0.8vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  {item.percentage > 0 ?
                                    <><span>₹ {item?.discountSalePrice.toFixed(2)}</span>{" "}<span style={{ fontSize: "12px", bottom: "10px", textDecorationLine: "line-through" }}>₹ {item.sale_price * item.count}</span></> :
                                    <span>₹ {item.sale_price * item.count}</span>
                                  }
                                </Col>
                                <Col flex="auto" style={{ marginLeft: "-17px" }}>
                                  <div style={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 20% 100%)", position: "absolute", height: "4.3vh", backgroundColor: "#367BB6", position: "relative", borderBottomRightRadius: "0.8vh" }}><p style={{ fontSize: "2vh", color: "white", fontWeight: 700, display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>QTY {item.count}</p></div>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        )
                      })
                    }
                  </Row>
                </Scrollbars>
              </Col>
              <Col span={6} style={{ paddingLeft: 20 }}>
                <Card bodyStyle={{ padding: 15 }} style={{ width: "30vw" }} className="totalCard-home">
                  <p className="current-summary" style={{ fontSize: "0.8rem" }}>
                    Current Summary
                    {multilingual === "true" && (
                      <span style={{ marginLeft: "1rem" }}>{t('current-summary')}</span>
                    )}
                  </p>
                  <div className="subtotal-details-div-home" style={{ backgroundColor: "#fff", padding: "3px 0px", marginBottom: "0" }}>
                    <Row>
                      <Col span={12} style={{ textAlign: "left" }}>
                        <p className="subtotal" style={{ fontSize: "2vh", lineHeight: "3.8vh" }}>Items:</p>
                      </Col>
                      <Col span={12}>
                        <p className="subtotal-price" style={{ fontSize: "2vh", lineHeight: "3.8vh", fontWeight: 700 }}>{summaryObj?.count}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12} style={{ textAlign: "left" }}>
                        <p className="subtotal" style={{ fontSize: "2vh", lineHeight: "3.8vh" }}>Subtotal:</p>
                      </Col>
                      <Col span={12}>
                        <p className="subtotal-price" style={{ fontSize: "2vh", lineHeight: "3.8vh", fontWeight: 700 }}>₹ {summaryObj?.total?.toFixed(2)}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12} style={{ textAlign: "left" }}>
                        <p className="discount-label" style={{ fontSize: "2vh", lineHeight: "3.8vh" }}>Discount:</p>
                      </Col>
                      <Col span={12}>
                        <p className="discount-price-home" style={{ fontSize: "2vh", lineHeight: "3.8vh", fontWeight: 700 }}>₹ {summaryObj?.discount?.toFixed(2)} </p>
                      </Col>
                    </Row>
                  </div>
                  <div className="total-details-div-home" style={{ backgroundColor: "#fff", marginBottom: "0.7rem", padding: "3px 0px" }}>
                    <div style={{ height: "1px", width: "100%", backgroundColor: "#665E5E" }} />
                    <Row>
                      <Col span={12} style={{ textAlign: "left" }}>
                        <p className="total" style={{ fontSize: "2vh", lineHeight: "3.8vh", fontWeight: 600 }}>Total</p>
                      </Col>
                      <Col span={12}>
                        <p className="total-price" style={{ fontSize: "2vh", lineHeight: "3.8vh", fontWeight: 700 }}>₹ {(summaryObj?.total - summaryObj?.discount).toFixed(2)} </p>
                      </Col>
                    </Row>
                  </div>
                  <div style={{ backgroundColor: "#DDFAF5", height: "4.6vh", textAlign: "center", paddingTop: "1vh", marginBottom: "2%", borderRadius: "0.7vh" }}>
                    <span style={{ fontSize: "14px", fontWeight: 700 }}>You save ₹{summaryObj.discount.toFixed(2)} in this order</span>
                  </div>
                </Card>
                <Row justify='center' style={{ width: "30vw", marginTop: "1vh" }}>
                  <Col span={2} />
                  <Col span={10}>
                    {paperFlag === "true" ?
                      paper?.map(item => {
                        if (item.id === "5257356") {
                          return (
                            <div style={{ background: "#FFFFFF", borderRadius: "5px", border: "1px solid #C6C3C5", width: "10.3vw", height: "15.2vh", position: "relative" }} onClick={handleSelectedItems(item)}>
                              <PlusOutlined style={{ background: "#FFEA28", position: "absolute", top: "0%", right: "0%", fontSize: "2vh" }} />
                              <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-around", flexDirection: "column" }}>
                                <img src={bag2} alt="" style={{ height: "4rem", width: "5rem" }} />
                                <span style={{ color: "#000000", fontSize: "1rem", textAlign: "center", fontWeight: "700" }} title={item.name}>{item.name}</span>
                                <span style={{ color: "#000000", fontSize: "1rem", textAlign: "center", fontWeight: "700" }}>₹ {item.sale_price}</span>
                              </div>
                            </div>
                          )
                        }
                      }) :
                      ""
                    }
                  </Col>
                  <Col span={1} />
                  <Col span={10}>
                    {cottonFlag === "true" ?
                      cotton?.map(item => {
                        if (item.id === "2365103") {
                          return (
                            <div style={{ background: "#FFFFFF", borderRadius: "5px", border: "1px solid #C6C3C5", width: "10.3vw", height: "15.2vh", position: "relative" }} onClick={handleSelectedItems(item)}>
                              <PlusOutlined style={{ background: "#FFEA28", position: "absolute", top: "0%", right: "0%", fontSize: "2vh" }} />
                              <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-around", flexDirection: "column" }}>
                                <img src={bag1} alt="" style={{ height: "4rem", width: "5rem" }} />
                                <span style={{ color: "#000000", fontSize: "1rem", textAlign: "center", fontWeight: "700" }} title={item.name}>{item.name}</span>
                                <span style={{ color: "#000000", fontSize: "1rem", textAlign: "center", fontWeight: "700" }}>₹ {item.sale_price}</span>
                              </div>
                            </div>
                          )
                        }
                      }) :
                      ""
                    }
                  </Col>
                </Row>
                <Button disabled={selectedItems?.length > 0 ? false : true} onClick={handleSubmit} style={{ marginTop: "1vh", width: "30vw", backgroundColor: "yellow", height: "6vh", borderRadius: "0.5vh", fontSize: "1.5vh", fontWeight: "600", display: 'flex', alignItems: "center", justifyContent: "center" }}>
                  <ShoppingCartOutlined style={{ fontSize: "1.9vh" }} /> <span style={{ fontSize: "1vw", fontWeight: 700 }}>
                    PROCEED TO CHECKOUT
                  </span>
                  {multilingual === "true" && (
                    <>
                      <span style={{ marginLeft: "0.25em", fontSize: "1vw", fontWeight: 700 }} >{"/"}</span>
                      <span style={{ marginLeft: "0.25em", fontSize: "1vw", fontWeight: 700 }} >{t('proceed-checkout')}</span>
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </ div>
        :
        <div id="4" onClick={hanldeCloseKeyboard}>
          <div style={{ backgroundColor: "rgba(255, 159, 0, 0.19)", textAlign: "center", fontWeight: 600, padding: "0.3%", fontSize: "1.5vw" }}>
            Please drop your products one by one or enter the item code. For deleting the product, you need to tap on the item and it will show the delete option
          </div>
          <div style={{ height: "5vh", padding: "2vh 0 0 3vw", fontSize: "2.6vh", fontWeight: 600 }}>Welcome {name}!</div>
          <div style={{ backgroundColor: "white", textAlign: "center", height: "52vh", padding: "0 2.4rem 0rem 2.4rem" }}>
            <Row style={{ height: "100%" }} justify="center">
              <Col span={24}>
                <img src={cartGif} style={{ height: "43vh", width: "43vw" }} />
                <p style={{ fontSize: "3.1vh", fontWeight: "600", marginTop: "-1vh", }}>
                  No items added !
                  {multilingual === "true" && (
                    <div style={{ lineHeight: "1em" }}>{t('no items added !')}</div>
                  )}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      }
      {keyboardvisible === false ?
        <div style={{ backgroundColor: "#F7F8FA" }}>
          <div className="bgColor" style={{ marginRight: "1%", marginLeft: "1%", position: "relative" }}>
            <h3 style={{ paddingTop: "0.5%", paddingLeft: "0.5%", color: "#000000", fontWeight: 600, fontSize: "1.8vh" }}>You May Also Like  </h3>
            <Row align='middle' style={{ display: "flex", padding: "0 2% 3% 2%", fontSize: "0.9rem" }} justify="center">
              <Col span={1} style={{ height: "100%", }}>{isScrolledToStart && <img src={leftArrow} style={{ width: "4.6vh", marginLeft: "-1vh" }} onClick={() => handleHorizantalScroll(ref.current, 35, 100, -360)} />}  </Col>
              <Col span={22} onScroll={handleScroll} style={{ overflowX: "scroll", display: "flex", scrollBehavior: "smooth", overflowY: "hidden" }} ref={ref}>
                {tempDup?.map((item) => {
                  return (
                    <div onClick={handleSelectedItems(item)} style={{ background: "#FFFFFF", borderRadius: "5px", border: "1px solid #C6C3C5", width: "15.9vw", height: "21vh", padding: "0.8rem", marginLeft: "1vw", position: "relative" }}>
                      <PlusOutlined style={{ background: "#FFEA28", position: "absolute", top: "0%", right: "0%", fontSize: "3vh" }} />
                      <div style={{ display: "flex", alignItems: "center", width: "13.5vw", justifyContent: "space-around", flexDirection: "column" }}>
                        <img src={item.imageUrl} alt="" style={{ height: "3.5rem", width: "4rem" }} />
                        <h3 style={{ color: "#000000", fontSize: "1.3rem", textAlign: "center", fontWeight: "600" }}>₹ {item.sale_price}</h3>
                        <span style={{ fontSize: "14px", fontWeight: 700, textAlign: "center", color: "#000000" }}>{item.name.length < 45 ? item.name : item.name.slice(0, 45).concat("...")}</span>
                      </div>
                    </div>
                  )
                })}
              </Col>
              <Col span={1} style={{ height: "100%" }}>{!isScrolledToEnd && <img src={rightArrow} style={{ width: "4.6vh", marginLeft: "1vh" }} onClick={() => handleHorizantalScroll(ref.current, 35, 100, 360)} />}</Col>
            </Row>
          </div>
        </div> : ""
      }
      <Modal open={modalFlag} type="dashed" footer={null} closable={false} centered={true} width={200}>
        <img src={modalItem?.imageUrl} alt="" width={100} />
        <p>{modalItem?.name}</p>
      </Modal>
      <Modal okText="Yes" cancelText="No" open={routeFlag} onCancel={() => { setRouteFlag(false); setTime(300); }} onOk={() => {
        let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
        selectedProducts = selectedProducts === null ? {} : selectedProducts;
        selectedProducts.productsData = [];
        localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
        callStop();
        navigate("/customer-login");
      }}>
        <p style={{ fontWeight: 600, fontSize: "3vh", paddingTop: "1em" }}>Are you sure you want to cancel the transaction ?</p>
      </Modal>
      <Modal centered open={timeoutFlag} closable={false} footer={null} width={300} style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
        <p style={{ fontSize: "15px", textAlign: "center" }}>Transaction Timeout in</p>
        <p style={{ fontSize: "15px", textAlign: "center", color: "red" }}>00:00:{time}</p>
        <Button style={{ borderRadius: "4px" }} onClick={() => { setTimeoutFlag(false); setTime(300); }}>Cancel</Button>
      </Modal>
      {keyboardvisible === true ?
        <>
          <Row justify='center' >
            <Col span={24}>
              <div style={{ width: "100%", position: "relative", marginTop: "-60px", backgroundColor: "#F7F8FA" }}>
                <Keyboard
                  buttonTheme={[
                    {
                      class: "hg-yellow",
                      buttons: "{space}"
                    },
                    {
                      class: "hg-mail",
                      buttons: "@yahoo.com @gmail.com"
                    },
                    {
                      class: "hg-space",
                      buttons: "{space}"
                    },
                  ]}
                  onChange={(val) => setSearch(val)}
                  onKeyPress={onKeyPressNumber}
                  theme={"hg-theme-default home"}
                  display={{
                    "{abc}": "ABC",
                    "{smileys}": "\uD83D\uDE03",
                    "{back}": "return",
                    "{numbers}": "123",
                    "{number}": "123",
                    '{bksp}': 'backspace',
                    "{backspace}": "⌫",
                    "{shift}": "⇧",
                    '{tab}': 'tab',
                    '{lock}': 'lock',
                    '{enter}': 'Enter',
                    '{space}': "Space",
                  }}
                  layout={{
                    shift: [
                      "q w e r t y u i o p",
                      "a s d f g h j k l",
                      "{shift} z x c v b n m {backspace}",
                      "{number} {space} @yahoo.com @gmail.com"
                    ],
                    numbers: [
                      "1 2 3 4 5 6 7 8 9 0",
                      '- / : ; ( ) $ & @ "',
                      '#+= . , ? ! ` {backspace}',
                      "{numbers} {space} {back}"
                    ],
                    number: [
                      "1 2 3 4 5 6 7 8 9 0",
                      '- / : ; ( ) $ & @ "',
                      '#+= . , ? ! ` {backspace}',
                      "{number} {space} {back}"
                    ],
                    default: [
                      "1 2 3 4 5 6 7 8 9 0",
                      '- / : ; ( ) $ & @ "',
                      '#+= . , ? ! ` {backspace}',
                      "{number} {space} {enter}"
                    ],
                  }}
                />
              </div>
            </Col>
          </Row>
        </>
        : ""
      }
    </div>
  );
};

export default Home;
