import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Spin, Form } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "../../antd.css";
import "./styles.css";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import backgroungImage from "../../assets/SCO_asset/signup/backgroundImage.png";
import Axios from "axios";
import URL from "../../app/server.json";
import CryptoJS from 'crypto-js';

const Login = () => {
  const keyboardRef1 = useRef(null);
  const keyboardRef2 = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [keyboard, setKeyboard] = useState(1);
  const [layout, setlayout] = useState("default");
  const [userNameError, setUserNameError] = useState({ flag: false, msg: "" });
  const [cursorPosition, setCursorPosition] = useState(null);
  const [isShiftDoubleClick, setIsShiftDoubleClick] = useState(false);
  const [shiftDoubleClick, setShiftDoubleClick] = useState(false);
  const apiKey = URL.GRAVITEE_API_KEY;

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  useEffect(() => {
    keyboardRef1.current.focus();
  }, []);

  const handleLogin = () => {
    setLoading({ status: true, message: "" });
    let sessionId = localStorage.getItem("sessionId");
    let terminalNumber = localStorage.getItem("terminalNumber");
    const token = localStorage.getItem("token");
    const encryptedUsername = CryptoJS.AES.encrypt(userName, 'secret-key').toString();
    const encryptedPassword = CryptoJS.AES.encrypt(password, 'secret-key').toString();
    let basicAuth = `${userName}:${password}`;
    if (userName !== "" && password !== "") {
      let headers = {
        "Content-Type": "application/json",
        token: `Basic ${window.btoa(basicAuth)},${token}`,
      }
      headers[URL.key_NAME] = apiKey;
      if (navigator.onLine === true) {
        Axios({
          url: `${URL.GRAVITEE_BASE_URL}/login`,
          method: "GET",
          headers: headers,
          params: {
            remainingUrl: `/ws/com.promantia.decathlon.custom_selfcheckout.logincheck?l=userName&p=password&terminalname=${terminalNumber}&sessionId=${sessionId}`,
            "username": encryptedUsername,
            "password": encryptedPassword
          }
        }).then((response) => {
          if (response.data.data.status === "SUCCESS") {
            let cashier = JSON.parse(localStorage.getItem('cashierData'));
            cashier.Username = encryptedUsername;
            cashier.Password = encryptedPassword;
            localStorage.setItem("cashierData", JSON.stringify(cashier));
            localStorage.setItem("token", response.data.data.token);
            navigate("/admin");
          } else {
            setLoading({ status: false, message: "" });
            setUserNameError({ flag: true, msg: "Dublicate Terminal" });
            return null;
          }
        }).catch((err) => {
          setLoading({ status: false, message: "" });
          let cashierData = JSON.parse(localStorage.getItem("cashierData"));
          cashierData.Username = CryptoJS.AES.decrypt(cashierData.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
          cashierData.Password = CryptoJS.AES.decrypt(cashierData.Password, 'secret-key').toString(CryptoJS.enc.Utf8);
          if (err.response.status === 401) {
            setUserNameError({ flag: true, msg: "Enter Valid User Name & Password" });
          } else if (userName === cashierData.Username && password === cashierData.Password) {
            navigate("/admin");
          } else {
            setUserNameError({ flag: true, msg: err.response.statusText });
          };
        });
      } else {
        let cashData = JSON.parse(localStorage.getItem('cashierData'));
        cashData.Username = CryptoJS.AES.decrypt(cashData.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
        cashData.Password = CryptoJS.AES.decrypt(cashData.Password, 'secret-key').toString(CryptoJS.enc.Utf8);
        if (cashData.Username === userName && cashData.Password === password) {
          setLoading({ status: false });
          navigate("/admin");
        } else {
          setLoading({ status: false });
          setUserNameError({ flag: true, msg: "Please enter valid credentials" });
        }
      }
    } else {
      setLoading({ status: false });
      setUserNameError({ flag: true, msg: "Username and password are mandotory" });
    };
  }
  const onClickUserName = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(1)
    setShiftDoubleClick(false)
  }
  const onClickPassword = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(2)
    setShiftDoubleClick(false)
  }

  const onKeyPress = (button) => {
    setUserNameError({ flag: false, msg: "" });
    if (keyboard === 1) {
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setUserName(userName.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setUserName(userName)
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" & button !== "{tab}" && button !== "{enter}" & button !== "{space}") {
        if (!shiftDoubleClick) {
          if (layout === "default") setlayout("shift");

        }
        let text = `${userName}${button}`
        setUserName(text.trim());
      }
    } else if (keyboard === 2) {
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setPassword(password.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setPassword(password)
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" & button !== "{tab}" && button !== "{enter}" & button !== "{space}") {
        if (!shiftDoubleClick) {
          if (layout === "default") setlayout("shift");

        }
        let text = `${password}${button}`
        setPassword(text.trim());
      }
    }
    if (button === "{numbers}" || button === "{caps}") handleNumbers();
    if (button === "{number}" || button === "{small}") handleNumber();
    if (button === "{back}") handleReturn();
    if (button === "{space}") handleSpace();
    if (button === "{mic}") handleMic();
  };

  const handleMic = () => {
    setUserName(userName)
    setPassword(password)
    setlayout(layout)
  }

  const handleNumbers = () => {
    const layout1 = layout;
    setlayout(layout1 === "default" ? "numbers" : "default");
    setUserName(userName)
    setPassword(password)
  }

  const handleReturn = () => {
    if (keyboard === 1) {
      keyboardRef2.current.focus();
      setKeyboard(2)
    }
    else if (keyboard === 2) {
      keyboardRef1.current.focus();
      setKeyboard(1)
    }
    setUserName(userName);
    setPassword(password)
  }

  const handleShift = () => {
    const layout1 = layout;
    setlayout(layout1 === "default" ? "shift" : "default");
    if (isShiftDoubleClick) {
      setlayout('default')
      setShiftDoubleClick(true)
    } else {
      setIsShiftDoubleClick(true);

      setTimeout(() => {
        setIsShiftDoubleClick(false);
      }, 500);
    }
  };

  const handleSpace = () => {
    if (keyboard === 1) {
      setUserName(userName + ' ');
    }
  }

  const handleNumber = () => {
    const layout1 = layout;
    setlayout(layout1 === "shift" ? "number" : "shift");
    setUserName(userName)
    setPassword(password)
  }

  const handleUserName = (ev) => {
    setUserName(ev.target.value);
  };

  const handlePassword = (ev) => {
    setPassword(ev.target.value);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;
  const sectionStyle = {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${backgroungImage})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "fixed",
  }

  return (
    <div style={sectionStyle}>
      <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
        <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
          <Row >
            <Col span={24}>
              <div style={{ height: "30px" }}>
              </div>
            </Col>
            <Col span={3}></Col>
            <Col span={18}>
              <p className="main-heading">WELCOME TO DECATHLON </p>
              <p className="sub-heading">Please enter your User ID & Password to proceed</p>
            </Col>
            <Col span={3}></Col>
          </Row>
          <Row justify="space-around" style={{ height: "60vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Col span={11} className="form-column">
              <Form layout="vertical" className="login-form">
                <Form.Item >
                  <Row style={{ padding: "0 0 0 0.2vw", marginBottom: "-19px" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: "0 0 20px", height: "3px", backgroundColor: "#fff" }} />
                        <div style={{ margin: "0 10px", fontSize: "22px", color: "#fff" }}>
                          User Name                      </div>
                        <div style={{ flex: "1", height: "3px", backgroundColor: "#fff" }} />
                      </div>
                    </Col>
                  </Row>
                  <input inputMode="none" className="input-field" ref={keyboardRef1} value={userName} onChange={handleUserName} onFocus={() => { setKeyboard(1) }} onClick={onClickUserName} />
                </Form.Item>
                <Form.Item style={{ marginTop: "2vh" }}>
                  <Row style={{ padding: "0 0 0 0.2vw", marginBottom: "-19px" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: "0 0 20px", height: "3px", backgroundColor: "#fff" }} />
                        <div style={{ margin: "0 10px", fontSize: "22px", color: "#fff" }}>
                          Password                      </div>
                        <div style={{ flex: "1", height: "3px", backgroundColor: "#fff" }} />
                      </div>
                    </Col>
                  </Row>
                  <input inputMode="none" className="input-field" type={"password"} ref={keyboardRef2} value={password} onPressEnter={handleLogin} onChange={handlePassword} onFocus={() => { setKeyboard(2) }} onClick={onClickPassword} />
                </Form.Item>
                <div style={{ height: "1vh", fontSize: "2.5vh", color: userNameError.flag === false ? "#fff" : "red", position: "relative", top: "-6%" }}>{userNameError.flag === false ? "" : userNameError.msg}</div>
                <Button className="btn-continue" onClick={handleLogin} htmlType="submit" >CONTINUE</Button>
              </Form>
            </Col>
            <Col span={12}>
              <div className="keyboard-div">
                <Keyboard
                  buttonTheme={[
                    {
                      class: "hg-yellow",
                      buttons: "{space}"
                    },
                    {
                      class: "hg-mail",
                      buttons: "@yahoo.com @gmail.com"
                    },
                    {
                      class: "hg-space",
                      buttons: "{space}"
                    },
                  ]}
                  layoutName={layout}
                  inputName={keyboard === 1 ? "un" : "psd"}
                  onChange={(val) => { keyboard === 1 ? setUserName(val) : setPassword(val) }}
                  onKeyPress={onKeyPress}
                  theme={"hg-theme-default signUp "}
                  display={{
                    "{abc}": "ABC",
                    "{smileys}": "\uD83D\uDE03",
                    "{back}": "Enter",
                    "{numbers}": "123",
                    "{number}": "123",
                    '{bksp}': 'backspace',
                    "{backspace}": "⌫",
                    "{shift}": "⇧",
                    '{tab}': 'tab',
                    '{lock}': 'lock',
                    '{enter}': 'enter',
                    '{space}': "Space",
                    "{caps}": "ABC",
                    "{small}": "abc"
                  }}
                  layout={{
                    shift: [
                      "q w e r t y u i o p",
                      "a s d f g h j k l",
                      "{shift} z x c v b n m {backspace}",
                      "{number} {space} @yahoo.com @gmail.com"
                    ],
                    numbers: [
                      "1 2 3 4 5 6 7 8 9 0",
                      '- / : ; ( ) $ & @ "',
                      '# . , _ ! ` {backspace}',
                      "{caps} {space} {back}"
                    ],
                    number: [
                      "1 2 3 4 5 6 7 8 9 0",
                      '- / : ; ( ) $ & @ "',
                      '# . , _ ! ` {backspace}',
                      "{small} {space} {back}"
                    ],
                    default: [
                      "Q W E R T Y U I O P",
                      "A S D F G H J K L",
                      "{shift} Z X C V B N M {backspace}",
                      "{numbers} {space} @yahoo.com @gmail.com"
                    ],
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
};

export default Login;