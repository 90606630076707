import { useEffect, useRef } from "react";

const ScannerComponent = (props) => {
  const { onScan } = props;
  const scannedData = useRef("");
  let timeoutId = null;
  let timeStamp = null;

  const handleKeyPress = (e) => {
    const { charCode, key } = e;
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode === 45) {
      const currentTimestampDiff = Date.now() - (timeStamp ? timeStamp : 0);
      if (timeStamp === null || (currentTimestampDiff / 1000) < 80) {
        timeStamp = Date.now();
        scannedData.current += key;
      } else {
        scannedData.current = "";
      }
    }
    if (scannedData.current !== "" && charCode === 13) {
      timeStamp = null;
      onScan(scannedData.current);
      scannedData.current = "";
      clearTimeout(timeoutId);
      timeoutId = null;
    }
    if (timeoutId === null && scannedData.current !== "") {
      timeoutId = setTimeout(() => {
        scannedData.current = "";
        timeoutId = null;
      }, 1800);
    }
  };

  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [onScan]);

  return null;
};

export default ScannerComponent;