import db from "../../database";
import suggestedData from "../home/suggestedItems.json";

const dataSync = async () => {
    const tempData = await db.tempData.toArray();
    console.log(tempData);
    if (tempData?.length > 0) {
        tempData?.map(async item => {
            console.log(item, "item");
            if (item?.type === "till") {
                await db.tillData.clear();
                const data = item?.data;
                await db.tillData.bulkAdd(data);
                let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
                selectedProducts = selectedProducts === null ? {} : selectedProducts;
                selectedProducts.tillData = item?.data;
                localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
            } else if (item?.type === "combo") {
                await db.comboData.clear();
                const data = item?.data;
                await db.comboData.bulkAdd(data);
            } else if (item?.type === "multibuy") {
                await db.multiBuyData.clear();
                const data = item?.data;
                await db.multiBuyData.bulkAdd(data);
            } else if (item?.type === "products") {
                if (item?.data?.length > 0) {
                    await Promise.all(item.data.map(async (item) => {
                        await db.productData
                            .where("id")
                            .equals(item.id)
                            .toArray()
                            .then(async (response) => {
                                if (response.length > 0) {
                                    await db.productData.update(item.id, item);
                                } else {
                                    await db.productData.add(item);
                                }
                            });
                    }));
                    let data = await db.productData.toArray();
                    const newArray1 = [], newArray2 = [], newArray3 = [];
                    let hashTable1 = {}, hashTable2 = {}, hashTable3 = {};
                    for (let i = 0; i < suggestedData.NonRfid.length; i++) {
                        let currentItem = suggestedData.NonRfid[i];
                        hashTable1[currentItem.id] = currentItem;
                    };
                    for (let i = 0; i < suggestedData.Cotton.length; i++) {
                        let currentItem = suggestedData.Cotton[i];
                        hashTable2[currentItem.id] = currentItem;
                    };
                    for (let i = 0; i < suggestedData.Paper.length; i++) {
                        let currentItem = suggestedData.Paper[i];
                        hashTable3[currentItem.id] = currentItem;
                    };
                    for (let i = 0; i < data.length; i++) {
                        let currentItem = data[i];
                        if (hashTable1.hasOwnProperty(currentItem.id)) {
                            let updatedItem = { ...hashTable1[currentItem.id], sale_price: currentItem.sale_price, name: currentItem.name };
                            let index = newArray1.findIndex(item => item.id === currentItem.id);
                            if (index !== -1) {
                                newArray1[index] = updatedItem;
                            } else {
                                newArray1.push(updatedItem);
                            };
                        };
                        if (hashTable2.hasOwnProperty(currentItem.id)) {
                            let updatedItem = { ...hashTable2[currentItem.id], sale_price: currentItem.sale_price };
                            let index = newArray2.findIndex(item => item.id === currentItem.id);
                            if (index !== -1) {
                                newArray2[index] = updatedItem;
                            } else {
                                newArray2.push(updatedItem);
                            };
                        };
                        if (hashTable3.hasOwnProperty(currentItem.id)) {
                            let updatedItem = { ...hashTable3[currentItem.id], sale_price: currentItem.sale_price };
                            let index = newArray3.findIndex(item => item.id === currentItem.id);
                            if (index !== -1) {
                                newArray3[index] = updatedItem;
                            } else {
                                newArray3.push(updatedItem);
                            };
                        };
                    };
                    const reorderedArray = [];
              const remainingObjects = [];

              suggestedData.NonRfid.forEach(suggestedItem => {
                const foundObject = newArray1.find(newArrayItem => newArrayItem.id === suggestedItem.id);
                if (foundObject) {
                  reorderedArray.push(foundObject);
                }
              });
              newArray1.forEach(newArrayItem => {
                if (!reorderedArray.some(item => item.id === newArrayItem.id)) {
                  remainingObjects.push(newArrayItem);
                }
              });
              const finalArray = [...reorderedArray, ...remainingObjects];
                    await db.nonRfidData.clear();
                    await db.cottonData.clear();
                    await db.paperData.clear();
                    await db.nonRfidData.add(finalArray);
                    await db.cottonData.add(newArray2);
                    await db.paperData.add(newArray3);
                };
                await db.tempData.clear();
                localStorage.setItem("dynamoDB", false);
                // Trigger the custom event
                const customEvent = new CustomEvent('customStorageChange', {
                    detail: { key: 'dynamoDB', newValue: JSON.stringify(false) }
                });
                window.dispatchEvent(customEvent);
            }
        });
    }
};

export {
    dataSync
};