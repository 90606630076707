import React, { useState, useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card } from "antd";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import VerySatisfied from "../../assets/SCO_asset/feedback/green.png";
import Satisfied from "../../assets/SCO_asset/feedback/yellow.png";
import NotSatisfied from "../../assets/SCO_asset/feedback/red.png";
import Tick from "../../assets/SCO_asset/ebill-buttons/tick.svg";
import _ from "lodash";
import Axios from "axios";
import moment from "moment";
import URL from "../../app/server.json";
import db from "../../database";
import { useTranslation } from 'react-i18next';
import { dataSync } from "../websocket/dataSync";
import CryptoJS from 'crypto-js';
import NsatReasons from "./NSatReasons";


const FeedbackStepTwo = () => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");
  const [nSatResonsVisible, setNSatResonsVisible] = useState(false);
  const[NSatReason,setNSatReason]=useState(null);
  const [name, setName] = useState("");
  const [cursorFlag, setCursorFlag] = useState(false);
  const [isFeedbackClicked, setIsFeedbackClicked] = useState(false);
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual")
  const apiKey = URL.GRAVITEE_API_KEY;
  const config = {
    headers: {},
  };
  config.headers[URL.key_NAME] = apiKey;
  const taxLines = useRef({});
  const  taxes = {};
  const [taxObj, setTaxObj] = useState(taxes);

  useEffect(()=>{
    if(NSatReason !== null){
      handleBillModeSelection("Not Satisfied",2,1)
    }
  },[NSatReason])

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  useEffect(() => {
    let key = localStorage.getItem("dynamoDB");
    if (key === "true") {
      console.log('value:', key);
      dataSync();
    };
    const handleCustomEvent = (event) => {
      if (event.detail.key === 'dynamoDB') {
        const dynamoDBValue = JSON.parse(event.detail.newValue);
        console.log('Parsed value:', dynamoDBValue);
        if (dynamoDBValue) {
          dataSync();
        }
      }
    };
    window.addEventListener('customStorageChange', handleCustomEvent);
    return () => {
      window.removeEventListener('customStorageChange', handleCustomEvent);
    };
  }, []);

  useEffect(() => {
    let tempName = localStorage.getItem("surname");
    setName(tempName);
  }, []);

  useEffect(() => {
    let timer;
    let isTimerActive = true;
    if (!isFeedbackClicked) {
      timer = setTimeout(() => {
        if (isTimerActive) {
          handleBillModeSelection("", "", "", "0");
        };
      }, 10000);
    };
    return () => {
      isTimerActive = false;
      clearTimeout(timer);
    };
  }, [isFeedbackClicked]);

  let cashier = JSON.parse(localStorage.getItem('cashierData'));
  cashier.Username = CryptoJS.AES.decrypt(cashier.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
  cashier.Password = CryptoJS.AES.decrypt(cashier.Password, 'secret-key').toString(CryptoJS.enc.Utf8);
  let basicAuth = `${cashier.Username}:${cashier.Password}`

  const emojiFeedbackList = [
    {
      title: "Very Satisfied",
      img: VerySatisfied,
      no: 3
    },
    {
      title: "Satisfied",
      img: Satisfied,
      no: 2
    },
    {
      title: "Not Satisfied",
      img: NotSatisfied,
      no: 1
    }
  ];


  const verify =(order) => {
    let taxDetails = order[0].taxDetails;
    for (let i =0 ; i < taxDetails.length ; i++){
      if(Number(taxDetails[i].CGST) !== Number(taxDetails[i].TCGST)){
          taxDetails[i].verify = false;
      }
          else if(Number(taxDetails[i].SGST) !== Number(taxDetails[i].TSGST)){
                  taxDetails[i].verify = false;
          }
          else {
              taxDetails[i].verify = true;
          }
      }
      taxDetails.forEach(ele => {
        if(!ele.verify){
          let chk=false;
          let reAdjustment = true;
         let taxCgstamt=Number(ele.CGST);
         let taxSgstamt=Number(ele.SGST);
        let taxCatagoryInfo=ele.taxRate;
        let lineCgst=0;
        let lineSgst=0;
        let j=order[0].lines.length;
        let count=0;
                //swap func
                while(!chk){
                  let i=0;
                   let test=false; 
                   count++;
                   lineCgst=0;
                   lineSgst=0;
                 order[0].lines.forEach(element => {
                   i++;
                if(taxCatagoryInfo===element.taxRate ){
                  if(Number(element.ICGST)!==Number(element.ISGST) && !test && !element.swap){
                    let temp=Number(order[0].lines[i-1].ICGST);
                    order[0].lines[i-1].ICGST=Number(order[0].lines[i-1].ISGST);
                    order[0].lines[i-1].ISGST=temp;
                    order[0].lines[i-1].swap=true;
                    test=true;
                  }
                  lineCgst=(Number(lineCgst)+Number(element.ICGST)).toFixed(2);
                  lineSgst=(Number(lineSgst)+Number(element.ISGST)).toFixed(2);
                  
             }
              });
              if(Number(lineCgst)===Number(taxCgstamt) && count>=j ){
               chk=true;
             }
             else if(Number(lineSgst)===Number(taxSgstamt) && count>=j ){
                chk=true;
             }
             else if(count>=j){
               chk=true;
             }
             else{
               chk=false;
               test=false;
             }
             }
             //adjustment function
      lineCgst=Number(lineCgst);
      lineSgst=Number(lineSgst);
      if(lineCgst!==taxCgstamt || lineSgst!== taxSgstamt){
        let adjustment=false;
        let j=0;
        let cgstAdj=false;
        let sgstAdj=false;
        let adjstCgstAmt;
        let adjstSgstAmt;
        if(lineCgst!== taxCgstamt){
          cgstAdj=true;
           adjstCgstAmt=(Number(lineCgst)-Number(taxCgstamt)).toFixed(2);
        }
        else{
          adjstCgstAmt=0;
        }
        if(lineSgst!==taxSgstamt){
          sgstAdj=true;
          adjstSgstAmt=(Number(lineSgst)-Number(taxSgstamt)).toFixed(2);
        }
        else{
          adjstSgstAmt=0;
        }
        while(!adjustment){
          order[0].lines.forEach(adjele => {
            j++;     
         if(taxCatagoryInfo===adjele.taxRate && !adjustment){
          if(adjstCgstAmt!==0 || adjstSgstAmt!==0){
          if(Number(adjele.ICGST)!==Number(adjele.ISGST)) {
            if(cgstAdj){
              if(adjstCgstAmt>0){
             let temp=(Number(adjele.ICGST)-0.01).toFixed(2);
             order[0].lines[j-1].ICGST=temp;
             let netamt=(Number(adjele.netAmount)+0.01).toFixed(2);
             order[0].lines[j-1].netAmount=netamt;
              let taxAmt=(Number(adjele.taxableAmt)-0.01).toFixed(2);
              order[0].lines[j-1].taxableAmt=taxAmt;
              adjstCgstAmt=(Number(adjstCgstAmt)-0.01).toFixed(2);   
              adjstCgstAmt=Number(adjstCgstAmt);
              }
              else if(adjstCgstAmt<0){
                let temp=(Number(adjele.ICGST)+0.01).toFixed(2);
                order[0].lines[j-1].ICGST=temp;
                let netamt=(Number(adjele.netAmount)-0.01).toFixed(2);
                order[0].lines[j-1].netAmount=netamt;
                 let taxAmt=(Number(adjele.taxableAmt)+0.01).toFixed(2);
                 order[0].lines[j-1].taxableAmt=taxAmt;
                 adjstCgstAmt=(Number(adjstCgstAmt)+0.01).toFixed(2);
                 adjstCgstAmt=Number(adjstCgstAmt);
              }
             }           
             if(sgstAdj){
                if(adjstSgstAmt>0){
               let temp1=(Number(adjele.ISGST)-0.01).toFixed(2);
               order[0].lines[j-1].ISGST=temp1;
               let netamt=(Number(adjele.netAmount)+0.01).toFixed(2);
               order[0].lines[j-1].netAmount=netamt;
                let taxAmt=(Number(adjele.taxableAmt)-0.01).toFixed(2);
                order[0].lines[j-1].taxableAmt=taxAmt;
                adjstSgstAmt=(Number(adjstSgstAmt)-0.01).toFixed(2);
                adjstSgstAmt=Number(adjstSgstAmt);
                }
                else if(adjstSgstAmt<0){
                  let temp2=(Number(adjele.ISGST)+0.01).toFixed(2);
                  order[0].lines[j-1].ISGST=temp2;
                  let netamt=(Number(adjele.netAmount)-0.01).toFixed(2);
                  order[0].lines[j-1].netAmount=netamt;
                   let taxAmt=(Number(adjele.taxableAmt)+0.01).toFixed(2);
                   order[0].lines[j-1].taxableAmt=taxAmt;
                   adjstSgstAmt=(Number(adjstSgstAmt)+0.01).toFixed(2);
                   adjstSgstAmt=Number(adjstSgstAmt);
                }
             }
             if(adjstCgstAmt===0 && adjstSgstAmt===0){
              adjustment=true;
             }
            }
            if((j >= order[0].lines.length) && (adjstCgstAmt !==0 || adjstSgstAmt !==0 )){
              adjustment = true;
              reAdjustment = false;
             }
         }
        }
       });
        }
        while(!reAdjustment){
          j = 0;
          order[0].lines.forEach(adjele => {
            j++;  
            if(taxCatagoryInfo===adjele.taxRate && !reAdjustment){
              if(adjstCgstAmt!==0 || adjstSgstAmt!==0){
              if(Number(adjele.ICGST)===Number(adjele.ISGST)) {
                if(cgstAdj){
                  if(adjstCgstAmt>0){
                 let temp=(Number(adjele.ICGST)-0.01).toFixed(2);
                 order[0].lines[j-1].ICGST=temp;
                 let netamt=(Number(adjele.netAmount)+0.01).toFixed(2);
                 order[0].lines[j-1].netAmount=netamt;
                  let taxAmt=(Number(adjele.taxableAmt)-0.01).toFixed(2);
                  order[0].lines[j-1].taxableAmt=taxAmt;
                  adjstCgstAmt=(Number(adjstCgstAmt)-0.01).toFixed(2);   
                  adjstCgstAmt=Number(adjstCgstAmt);
                  }
                  else if(adjstCgstAmt<0){
                    let temp=(Number(adjele.ICGST)+0.01).toFixed(2);
                    order[0].lines[j-1].ICGST=temp;
                    let netamt=(Number(adjele.netAmount)-0.01).toFixed(2);
                    order[0].lines[j-1].netAmount=netamt;
                     let taxAmt=(Number(adjele.taxableAmt)+0.01).toFixed(2);
                     order[0].lines[j-1].taxableAmt=taxAmt;
                     adjstCgstAmt=(Number(adjstCgstAmt)+0.01).toFixed(2);
                     adjstCgstAmt=Number(adjstCgstAmt);
                  }
                 }           
                 if(sgstAdj){
                    if(adjstSgstAmt>0){
                   let temp1=(Number(adjele.ISGST)-0.01).toFixed(2);
                   order[0].lines[j-1].ISGST=temp1;
                   let netamt=(Number(adjele.netAmount)+0.01).toFixed(2);
                   order[0].lines[j-1].netAmount=netamt;
                    let taxAmt=(Number(adjele.taxableAmt)-0.01).toFixed(2);
                    order[0].lines[j-1].taxableAmt=taxAmt;
                    adjstSgstAmt=(Number(adjstSgstAmt)-0.01).toFixed(2);
                    adjstSgstAmt=Number(adjstSgstAmt);
                    }
                    else if(adjstSgstAmt<0){
                      let temp2=(Number(adjele.ISGST)+0.01).toFixed(2);
                      order[0].lines[j-1].ISGST=temp2;
                      let netamt=(Number(adjele.netAmount)-0.01).toFixed(2);
                      order[0].lines[j-1].netAmount=netamt;
                       let taxAmt=(Number(adjele.taxableAmt)+0.01).toFixed(2);
                       order[0].lines[j-1].taxableAmt=taxAmt;
                       adjstSgstAmt=(Number(adjstSgstAmt)+0.01).toFixed(2);
                       adjstSgstAmt=Number(adjstSgstAmt);
                    }
                 }
                 if(adjstCgstAmt===0 && adjstSgstAmt===0){
                  reAdjustment=true;
                 }
                }
    
             }
            }
       });
        }
      }
        }
      });
      return order[0]
  }

  const handleBillModeSelection = async (value, cardIndex, no, defaultNo) => {
    setIsFeedbackClicked(true);
    setCursorFlag(true);
    if (cursorFlag === false) {
      const selectedBillModeIndex = _.findIndex(emojiFeedbackList, ["title", value]);
      let emojiNumber
      if (selectedBillModeIndex === cardIndex) {
        emojiNumber = no;
        setSelectedValue(value);
      }
      let token = localStorage.getItem("token");
      let ebillMode = localStorage.getItem("billMode");
      localStorage.setItem("saleendtime", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
      let orderData = await db.syncData.toArray();

      let obj = JSON.parse(localStorage.getItem("orderObj"));
      obj.NSATReasons = NSatReason?NSatReason:null ;
      obj.Feedback = defaultNo ? 0 : emojiNumber;
      obj.ebillMode =  `${ebillMode === "SMS" ? "mobile" : ebillMode === "Email" ? "email" : ebillMode === "Both Email and Mobile" ? "both" : "printedCopy"}`

      await verify([obj]);
       let taxLineArray = [];
      obj.lines.map((item) => {
        let taxableAmount = Number(item.taxableAmt).toFixed(2);
        let sellingAmount = Number(item.sellingAmount).toFixed(2);
        let netAmount = Number(item.netAmount).toFixed(2);
        let obj = {
          "itemCode": item.itemCode,
          "unitPrice": item.unitPrice,
          "ean": item.ean,
          "serialNumber":item.serialNumber,
          "rfidscancode":item.rfidscancode,
          "epcCode":item.epcCode,
          "offer": false,
          "offerName": item.discountName === undefined ? "" : item.discountName === undefined,
          "type": "takeaway",
          "unitDiscount": item.unitDiscount,
          "qty": item.qty,
          "sellingAmount": sellingAmount,
          "taxableAmt": taxableAmount,
          "netAmount": netAmount,
        }
        const jsonString =item.tax
        const dataArray = JSON.parse(jsonString);
        dataArray.map((e, ind) => {
               var tax = e.name[0] === "S" ? {
                "name": e.name,
                "rate": e.rate,
                "amount": item.ISGST,
                "netAmount": netAmount,
                "taxAmount": taxableAmount,
              } : {
                "name": e.name,
                "rate": e.rate,
                "amount": item.ICGST,
                "netAmount": netAmount,
                "taxAmount": taxableAmount,
              }

             var  a =  {...taxLines.current,[e.taxId]:tax}
             taxLines.current = a;
        });

        var newObj = {...obj,"taxLines": taxLines.current}
        taxLineArray.push(newObj);
        taxLines.current = {};

      });

      obj.taxDetails.map((element) => {
        if (element.gst.length > 0) {
          element.gst.map((item) => {
        const taxObject = item.name.charAt(0) === "S" ? {
          "name": item.name,
          "rate": element.taxRate,
          "amount": element.SGST,
          "TSGST": element.TSGST,
          "taxAmount": element.taxedAmount,
          "netAmount": element.netAmount,
        }:
        {
          "name": item.name,
          "rate": element.taxRate,
          "amount": element.CGST,
          "TCGST": element.TCGST,
          "taxAmount": element.taxedAmount,
          "netAmount": element.netAmount,
        }
        var a = {...taxObj.current,[item.taxId]:taxObject}
        taxObj.current = a;
        const taxes = {...taxObj.current};
        setTaxObj(taxes);
        });
      };
      });

      obj.lines = taxLineArray.reverse();
      delete obj.taxDetails;
      obj.taxes = taxObj.current; 
      let tempArray = [...orderData, [obj]];
      
      await db.syncData.bulkPut(tempArray);
      const processLogicInBackground = async () => {
        let data = await db.syncData.toArray();
        let finalArray = [];
        data.map((item) => {
          if (item !== undefined) {
            finalArray.push(item[0])
          }
        });
        if (finalArray !== undefined) {
          const chunkSize = URL.BATCH_SIZE;
          const numChunks = Math.ceil(finalArray.length / chunkSize);
          const sendChunk = async (chunk) => {
            let slicedArray = finalArray.slice(chunk * chunkSize, (chunk + 1) * chunkSize);
            let body = {
              "bills": slicedArray,
              "remainingUrl": `/ws/com.promantia.decathlon.custom_selfcheckout.order`,
              "Authorization": `Basic ${window.btoa(basicAuth)}, ${token}`
            };
            try {
              const res = await Axios.post(`${URL.GRAVITEE_BASE_URL}/order`, body, config);
              if (res.data.statusCode === 200) {
                await db.syncData.clear();
              }
            } catch (error) {
              if (chunk === numChunks - 1) {
                await db.syncData.clear();
                await db.syncData.bulkPut(tempArray);
              }
            }
          };
          const processChunks = async () => {
            for (let chunk = 0; chunk < numChunks; chunk++) {
              await sendChunk(chunk);
            };
          };
          processChunks();
        }
      };

      const syncData = () => {
        setTimeout(processLogicInBackground, 0);
      };

      syncData();
      navigate('/thankyou');
    };
  };

  return (
    <>
    {
      nSatResonsVisible?(<NsatReasons setNSatReason={setNSatReason}/>):(
        <div>
      <Row align='middle' style={{ backgroundColor: "#0072AF", padding: "5px 50px 5px 50px", height: "10vh" }}>
        <Col span={6}>
          <img src={Logo} alt="Logo" style={{ height: "50px" }} />
        </Col>
        <Col offset={14} span={4} >
        </Col>
      </Row>
      <div className="feedback-block">
        <Row gutter={[0,16]} >
        <Col span={24} className="where-would" style={{ fontSize: "29px", fontFamily: "Roboto Condensed", fontWeight: 600,}}>
          Please rate your shopping experience with us !
        </Col>
        {multilingual === "true" && (
          <Col span={24} className="where-would" style={{ fontSize: "29px", fontFamily: "Roboto Condensed", fontWeight: 600, }}>{t('please rate your shopping experience with us !')}</Col>
        )}
        </Row >
        <Row justify="space-between" style={{ marginTop: "8vh" }}>
          {emojiFeedbackList.map((item, index) => (
            <Col
              span={8}
              style={{ cursor: cursorFlag === false ? "pointer" : "not-allowed", display: "flex", alignItems: "center", justifyContent: "center" }}
              onClick={() => item.title !== "Not Satisfied" ? handleBillModeSelection(item.title, index, item.no) :setNSatResonsVisible(true)}
              >
              <div style={{ width: "27vw",height:"100%" }}>
                <img
                  src={Tick}
                  alt=""
                  className="tick-icon"
                  style={{ visibility: selectedValue === item.title ? "visible" : "hidden", bottom: "247px", left: "209px" }}
                />
                <Card
                  className={
                    selectedValue === item.title ? "billmode-card-active" : "billmode-card"
                  }
                  style={{height:"100%"}}
                >
                  <div className="feedback-img-bg">
                    <img src={item.img} alt="" className="feedback-img" />
                  </div>
                  <p className="feedback-text">{item.title}</p>
                  {multilingual === "true" && (
                    <span className="feedback-text"  >{item.title === "Satisfied" ? t('satisfied') : item.title === "Very Satisfied" ? t('very-satisfied') : t('not-satisfied')}</span>
                  )}
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
      )
    }
    </>
  );
};

export default FeedbackStepTwo;