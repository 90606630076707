import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, message, Modal, Spin, Switch, Button } from "antd";
import CashUp from "../../assets/SCO_asset/admiPageGif/CashupDetails.gif";
import IncrementalRefresh from "../../assets/SCO_asset/admiPageGif/IncrementalRefresh1.gif";
import MasterRefresh from "../../assets/SCO_asset/admiPageGif/MasterRefresh.gif";
import Axios from "axios";
import db from "../../database";
import URL from "../../app/server.json";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import { LoadingOutlined } from "@ant-design/icons";
import Home from "../../assets/SCO_asset/admiPageGif/home.png";
import logOut from "../../assets/SCO_asset/admiPageGif/logout.png";
import Vector from "../../assets/SCO_asset/admiPageGif/Vector4.png";
import CashupPrint from "./cashupPrint"
import moment from "moment";
import CryptoJS from 'crypto-js';

const Admin = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIncrementModal, setIsIncrementModal] = useState(false);
  const [cashupDetails, setCashupDetails] = useState([]);
  const [isEbill, setIsEbill] = useState(localStorage.getItem("ebill"));
  const [isPaperBag, setIsPaperBag] = useState(localStorage.getItem("paperBag"));
  const [isCottonBag, setIsCottonBag] = useState(localStorage.getItem("cottonBag"));
  const [tillData, setTillData] = useState([]);
  const [isPrint, setIsPrint] = useState(true);
  const [istempPrint, setIsTempPrint] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [isFlag, setIsFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [languageSelectionloading, setLanguageSelectionLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isIncrementalNetworkModal, setIsIncrementalNetworkModal] = useState(false);
  const [isCashupNetworkModal, setIsCashupNetworkModal] = useState(false);
  const [isMasterNetworkModal, setIsMasterNetworkModal] = useState(false);
  const [isOrderDataModal, setIsOrderDataModal] = useState(false);
  const [validateTextFlag, setValidateTextFlag] = useState(false);
  const [visible, setVisible] = useState(false);
  const [multilingual, setIsMultilingual] = useState(localStorage.getItem("multilingual"));
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("languageSelected"));
  const [languages, setLanguages] = useState([]);
  let language_selected = localStorage.getItem("language_selected");

  const apiKey = URL.GRAVITEE_API_KEY;
  const config = {
    headers: {},
  };
  config.headers[URL.key_NAME] = apiKey;
  const validateMasterRefresh1 = async () => {
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    let body = {
      organization: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.organization,
      posterminal: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.touchpointtype,
      storeName: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.storeName,
      terminalNumber: localStorage.getItem("terminalNumber"),
      lastLoadedTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"),
    };
    let validateResponse = await Axios.post(`${URL.GRAVITEE_BASE_URL}/validateMasterRefresh`, body, config);
    if (validateResponse.data.statusCode === 200) {
      if (validateResponse.data.data === "No") {
        setValidateTextFlag(true);
      };
    } else {
      message.error("something is wrong in validateMasterRefresh service");
    };
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    validateMasterRefresh1();
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    (async () => {
      localStorage.setItem("ebill", isEbill);
      localStorage.setItem("paperBag", isPaperBag);
      localStorage.setItem("cottonBag", isCottonBag);
      localStorage.setItem("multilingual", multilingual);
      let tillData = await db.tillData.toArray();
      let data = await db.syncData.toArray();
      let newData = [];
      data.map((item) => {
        if (item !== undefined) {
          newData.push(item[0])
        }
      })
      setOrderData(newData);
      setTillData(tillData);
    })();
  }, [isFlag])

  let terminalNumber = localStorage.getItem("terminalNumber")
  let token = localStorage.getItem("token");
  let cashier = JSON.parse(localStorage.getItem('cashierData'));
  cashier.Username = CryptoJS.AES.decrypt(cashier.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
  cashier.Password = CryptoJS.AES.decrypt(cashier.Password, 'secret-key').toString(CryptoJS.enc.Utf8);
  let basicAuth = `${cashier.Username}:${cashier.Password}`

  const showModal = async (props) => {
    if (props === "0") {
      setIsModalOpen(true);
    } else {
      setIsIncrementModal(true)
    }
  };

  const handleOk = (props) => {
    setValidateTextFlag(false);
    if (isOnline && orderData.length === 0) {
      if (props === "0") {
        getMasterRefreshData();
      }
      else {
        getInrementalData();
      }
    }
    else {
      if (!isOnline) {
        props === "0" ? setIsMasterNetworkModal(true) : setIsIncrementalNetworkModal(true)
        setIsIncrementModal(false)
        setIsModalOpen(false)
      }
      else if (orderData.length > 0) {
        setIsOrderDataModal(true)
        setIsIncrementModal(false)
        setIsModalOpen(false)
      }
    }
  };

  const handleCancel = (e) => {
    setValidateTextFlag(false);
    setIsModalOpen(false);
    setIsIncrementModal(false);
  };

  const getMasterRefreshData = async () => {
    if (tillData.length === 0) {
      localStorage.setItem("isMasterRefresh", true)
      localStorage.setItem("isIncremenatalRefresh", false)
      navigate("/loading")
    } else {
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      let body = {
        organization: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.organization,
        posterminal: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.touchpointtype,
        storeName: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.storeName,
        terminalNumber: localStorage.getItem("terminalNumber"),
        lastLoadedTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"),
        loaded: "No"
      };
      let validateResponse = await Axios.post(`${URL.GRAVITEE_BASE_URL}/validateMasterRefresh`, body, config);
      if (validateResponse.data.statusCode === 200) {
        localStorage.setItem("isMasterRefresh", true)
        localStorage.setItem("isIncremenatalRefresh", false)
        navigate("/loading")
      };
    };
  }

  const getInrementalData = () => {
    localStorage.setItem("isIncremenatalRefresh", true)
    localStorage.setItem("isMasterRefresh", false)
    navigate("/loading")
  }

  const getCashupDetails = async () => {
    if (isOnline) {
      if (orderData.length > 0) {
        setIsOrderDataModal(true)
        setIsIncrementModal(false)
        setIsModalOpen(false)
      } else {
        return new Promise(async (cashupDataSuccess, cashupDataFailure) => {
          let headers = {
            "Content-Type": "application/json",
            token: `Basic ${window.btoa(basicAuth)},${token}`,
          }
          headers[URL.key_NAME] = apiKey;
          try {
            const response = await Axios({
              url: `${URL.GRAVITEE_BASE_URL}/cashup`,
              method: "GET",
              headers: headers,
              params: {
                remainingUrl: `/ws/com.promantia.decathlon.custom_selfcheckout.cashupdetails?terminalname=${terminalNumber}`,
              }
            })
            const data = [response.data.data];
            if (response.data.statusCode === 200 && data.length > 0) {
              setCashupDetails(data)
              setIsTempPrint(true)
              cashupDataSuccess(data);
              setIsEbill(true);
              setIsPaperBag(true);
              setIsCottonBag(true);
              localStorage.setItem("ebill", true);
              localStorage.setItem("paperBag", true);
              localStorage.setItem("cottonBag", true);
            } else {
              cashupDataSuccess();
            }
          } catch (error) {
            message.error("Error while fetching cashup");
            cashupDataFailure(error);
          }
        });
      };
    }
    else {
      if (!isOnline) {
        setIsCashupNetworkModal(true)
      };
    }
  }

  window.onafterprint = (event) => {
    navigate("/");
    setIsTempPrint(false);
    setIsPrint(true);
  };

  const getPrint = async () => {
    setLoading(true)
    return new Promise(async (cashupDataSuccess, cashupDataFailure) => {
      let headers = {
        "Content-Type": "application/json",
        token: `Basic ${window.btoa(basicAuth)},${token}`,
      }
      headers[URL.key_NAME] = apiKey;
      try {
        const response = await Axios({
          url: `${URL.GRAVITEE_BASE_URL}/closecashup`,
          method: "GET",
          data: { "amountToKeep": "0" },
          headers: headers,
          params: {
            "amountToKeep": "0",
            remainingUrl: `/ws/com.promantia.decathlon.custom_selfcheckout.closecashup?terminalname=${terminalNumber}`
          }
        })
        const data = [response.data.data];
        if (response.data.statusCode === 200 && data.length > 0) {
          setLoading(false)
          setIsPrint(false)
          setIsTempPrint(false);
          setTimeout(() => {
            window.print();
          }, 300)
          cashupDataSuccess(data);
        } else {
          cashupDataSuccess();
        }
      } catch (error) {
        cashupDataFailure(error);
      }
    });
  }

  const onChangeMultilingual = (checked) => {
    if (checked === true) {
      setVisible(true)
      setLanguages([])
      setLanguageSelectionLoading(true)
      let terminal = localStorage.getItem("terminalNumber")
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      let organization = JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.organization;
      let headers = {
        "Content-Type": "application/json",
      }
      headers[URL.key_NAME] = apiKey;
      Axios({
        url: `${URL.GRAVITEE_BASE_URL}/languageData`,
        method: "GET",
        headers: headers,
      }).then(response => {
        if (response.status === 200) {
          setLanguageSelectionLoading(false)
          const languages_array = response.data.map(entry => entry.language);
          setLanguages(languages_array)
          localStorage.setItem("languages_array", languages_array)
        }
      }).catch(error => {
        console.error("Error fetching data:", error);
      });
      
      Axios({
        url: `${URL.GRAVITEE_BASE_URL}/getLanguagePreference`,
        method: "GET",
        headers: headers,
        params: {
          storeId: organization,
          terminal: terminal
        }
      }).then(res => {
        setSelectedLanguage(res.data.data[0].language_selected)
      }).catch(error => {
        console.error("Error fetching data:", error);
      });
    }
    else {
      setVisible(false)
      setSelectedLanguage("null")
      localStorage.removeItem("language_selected")
      localStorage.setItem("multilingual", checked);
    }
    setIsMultilingual(checked)
  }

  const onChangeEbill = (checked) => {
    setIsEbill(checked)
    localStorage.setItem("ebill", checked);
  }

  const onChangePagerBag = (checked) => {
    setIsPaperBag(checked)
    localStorage.setItem("paperBag", checked);
  }

  const onChangeCottonBag = (checked) => {
    setIsCottonBag(checked)
    localStorage.setItem("cottonBag", checked);
  }

  const getSyncData = async () => {
    setLoading(true);
    let data = await db.syncData.toArray();
    let finalArray = [];
    let tempArray = [...data];
    data.map((item) => {
      if (item !== undefined) {
        finalArray.push(item[0])
      }
    });
    const status = navigator.onLine ? "online" : "offline";
    return new Promise(async () => {
      if (status === "online") {
        if (finalArray !== undefined) {
          const chunkSize = URL.BATCH_SIZE;
          const numChunks = Math.ceil(finalArray.length / chunkSize);
          const sendChunk = async (chunk) => {
            let slicedArray = finalArray.slice(chunk * chunkSize, (chunk + 1) * chunkSize);
            let body = {
              "bills": slicedArray,
              "remainingUrl": `/ws/com.promantia.decathlon.custom_selfcheckout.order`,
              "Authorization": `Basic ${window.btoa(basicAuth)}, ${token}`
            };
            try {
              const res = await Axios.post(`${URL.GRAVITEE_BASE_URL}/order`, body, config);
              if (res.data.statusCode === 200) {
                await db.syncData.clear();
                setLoading(false);
                setIsFlag(true);
              }
            } catch (error) {
              if (chunk === numChunks - 1) {
                await db.syncData.clear();
                await db.syncData.bulkPut(tempArray);
                setLoading(false);
                message.error("Order is not processed");
              }
            }
          };
          const processChunks = async () => {
            for (let chunk = 0; chunk < numChunks; chunk++) {
              await sendChunk(chunk);
            };
          };
          processChunks();
        };
      } else {
        message.warning('network error');
        setLoading(false);
      }
    });
  };

  const handleNetworkOk = () => {
    setIsIncrementalNetworkModal(false);
    setIsCashupNetworkModal(false)
    setIsMasterNetworkModal(false)
  };

  const handleOrderDataOk = () => {
    setIsOrderDataModal(false);
  };

  const validateMasterRefresh = async () => {
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    let body = {
      organization: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.organization,
      posterminal: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.touchpointtype,
      storeName: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.storeName,
      terminalNumber: localStorage.getItem("terminalNumber"),
      lastLoadedTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"),
    };
    let validateResponse = await Axios.post(`${URL.GRAVITEE_BASE_URL}/validateMasterRefresh`, body, config);
    if (validateResponse.data.statusCode === 200) {
      if (validateResponse.data.data === "No") {
        showModal("0");
        setValidateTextFlag(true);
      } else {
        showModal("1");
      };
    } else {
      message.error("something is wrong in validateMasterRefresh service");
    };
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  const handleCloseModal = () => {
    setVisible(false);
    setIsMultilingual(localStorage.getItem("multilingual"))
    language_selected = localStorage.getItem("language_selected")
  }

  const handleSelectLanguage = (event) => {
    const languageSelected = event.target.innerText
    setSelectedLanguage(languageSelected)
    localStorage.setItem("multilingual", multilingual);
    localStorage.setItem("languageSelected", languageSelected)
    setVisible(false);
    setLoading(true)
    let terminal = localStorage.getItem("terminalNumber")
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    let organization = JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.organization;
    let headers = {
      "Content-Type": "application/json",
    }
    headers[URL.key_NAME] = apiKey;
    Axios({
      url: `${URL.GRAVITEE_BASE_URL}/languagePreference`,
      method: "GET",
      headers: headers,
      params: {
        storeId: organization,
        terminal: terminal,
        selectedLanguage: languageSelected
      }
    }).then(response => {
      if (response.status === 200) {
        localStorage.setItem("language_selected", languageSelected)
        localStorage.setItem("languageSelected", languageSelected)
        window.location.reload()
      }
    }).catch(error => {
      console.error("Error fetching data:", error);
    });
  };

  const onclickSelectedLanguage = () => {
    setVisible(true)
    setLanguages([])
    setLanguageSelectionLoading(true)
    let terminal = localStorage.getItem("terminalNumber")
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    let organization = JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.organization;
    let headers = {
      "Content-Type": "application/json",
    }
    headers[URL.key_NAME] = apiKey;
    Axios({
      url: `${URL.GRAVITEE_BASE_URL}/languageData`,
      method: "GET",
      headers: headers,
    }).then(response => {
      if (response.status === 200) {
        setLanguageSelectionLoading(false)
        const languages_array = response.data.map(entry => entry.language);
        setLanguages(languages_array)
        localStorage.setItem("languages_array", languages_array)
      }
    }).catch(error => {
      console.error("Error fetching data:", error);
    });
    Axios({
      url: `${URL.GRAVITEE_BASE_URL}/getLanguagePreference`,
      method: "GET",
      headers: headers,
      params: {
        storeId: organization,
        terminal: terminal
      }
    }).then(res => {
      localStorage.setItem("language_selected", res.data.data[0].language_selected)
    }).catch(error => {
      console.error("Error fetching data:", error);
    });
  }

  return (
    <div>
      <Spin indicator={antIcon} spinning={loading}>
        {
          isPrint === true ?
            <>
              {istempPrint ? <CashupPrint cashupDetails={cashupDetails} tillData={tillData} istempPrint={istempPrint} showModal={showModal} getCashupDetails={getCashupDetails} getPrint={getPrint} /> : <>
                <Row align='middle' className="nav-row" >
                  <Col span={6}>
                    <img src={Logo} alt="Logo" style={{ height: "50px" }} />
                  </Col>
                  <Col offset={14} span={4} >
                  </Col>
                </Row>
                <Row align="middle" style={{ padding: "0 2rem" }}>
                  <Col span={18}>
                    <div style={{ display: "flex" }}>
                      <Col span={8} align="center" >
                        <div onClick={validateTextFlag === false ? null : () => showModal("0")} className="card" style={{ cursor: validateTextFlag === false ? "not-allowed" : "pointer", opacity: validateTextFlag === false ? 0.3 : 1 }}>
                          <div style={{ display: "flex", justifyContent: "center", paddingTop: "8%" }}>
                            <img src={MasterRefresh} alt="" className="mr-img" />
                          </div>
                          <p className="admin-row1-headings" style={{ marginTop: "1.1vh" }}>Master Refresh</p>
                          <p className="admin-row1-paragraph" style={{ margin: "0 10px" }}>Master refresh will load all the products listed in Decathlon. This will take 1-2 minutes.</p>
                        </div>
                      </Col>
                      <Col span={8} align="center" >
                        <div onClick={validateMasterRefresh} className="card">
                          <div style={{ display: "flex", justifyContent: "center", paddingTop: "7%" }}>
                            <img src={IncrementalRefresh} className="ir-img" alt="" />
                          </div>
                          <p className="admin-row1-headings" style={{ marginTop: "1.1vh" }}>Incremental Refresh</p>
                          <p className="admin-row1-paragraph" style={{ margin: "0 10px" }}>Helps refresh the POS. Ideally done daily as a good practice.</p>
                        </div>
                      </Col>
                      <Col span={8} align="center" >
                        <div onClick={getCashupDetails} className="card">
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={CashUp} alt="" className="cashUp-img" />
                          </div>
                          <p className="admin-row1-headings" style={{ marginTop: "1.1vh" }}>Cashup Details</p>
                          <p className="admin-row1-paragraph" style={{ margin: "0 10px" }}>Captures all the transaction details done on this POS.</p>
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col span={5} style={{ justifyContent: 'flex-end' }} >
                    <div className="row-3-card" >
                      <Row align="middle" justify="flex-start" style={{ width: "100%", padding: "1em" }}>
                        <Col span={16}>
                          <span className="admin-row1-headings" style={{ fontSize: "3vh", flex: "1" }}>Dual Language Display</span>
                        </Col>
                        <Col span={8}>
                          <Switch onChange={onChangeMultilingual} style={{ backgroundColor: JSON.parse(multilingual) ? "rgba(94, 197, 4, 1)" : "red", position: "relative" }} checked={JSON.parse(multilingual)}>
                          </Switch>
                        </Col>
                        {language_selected === null &&
                          <Col span={24}>
                            <span className="admin-row1-paragraph" style={{ textAlign: "left", textDecoration: "underline" }}>Local Language: English</span>
                          </Col>
                        }
                        {language_selected !== null &&
                          <Col>
                            <a className="admin-row1-paragraph" onClick={onclickSelectedLanguage} style={{ textAlign: "left", textDecoration: "underline" }}>Local Language: {language_selected}</a>
                          </Col>
                        }
                      </Row>
                    </div>
                    <div className="row-3-card">
                      <Row align="middle" justify="center" style={{ width: "100%", padding: "1em" }}>
                        <Col span={16}>
                          <span className="admin-row1-headings" style={{ fontSize: "3vh", float: "left" }}>E-bill</span>
                        </Col>
                        <Col span={8}>
                          <Switch onChange={onChangeEbill} style={{ backgroundColor: JSON.parse(isEbill) ? "rgba(94, 197, 4, 1)" : "red", position: "relative" }} checked={JSON.parse(isEbill)}>
                          </Switch>
                        </Col>
                      </Row>
                    </div>
                    <div className="row-3-card">
                      <Row align="middle" justify="center" style={{ width: "100%", padding: "1em" }} >
                        <Col span={16}>
                          <span className="admin-row1-headings" style={{ fontSize: "3vh", float: "left" }}>Paper bags</span>
                        </Col>
                        <Col span={8}>
                          <Switch style={{ backgroundColor: JSON.parse(isPaperBag) ? "rgba(94, 197, 4, 1)" : "red", position: "relative" }} onChange={onChangePagerBag} checked={JSON.parse(isPaperBag)}>
                          </Switch>
                        </Col>
                      </Row>
                    </div>
                    <div className="row-3-card">
                      <Row align="middle" justify="center" style={{ width: "100%", padding: "1em" }}>
                        <Col span={16}>
                          <span className="admin-row1-headings" style={{ fontSize: "3vh", float: "left" }}>Cotton bags</span>
                        </Col>
                        <Col span={8}>
                          <Switch style={{ backgroundColor: JSON.parse(isCottonBag) ? "rgba(94, 197, 4, 1)" : "red", position: "relative" }} onChange={onChangeCottonBag} checked={JSON.parse(isCottonBag)}></Switch>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row align="middle" justify="center" style={{ marginBottom: "0px", marginTop: "8vh", padding: "0 2rem" }}>
                  <Col span={19}> <img src={Home} style={{ verticalAlign: "center", cursor: "pointer" }} onClick={() => { navigate("/customer-login") }} /></Col>
                  <Col span={3} align='end' >
                    <span style={{ fontSize: "3vh", marginRight: "0.5em" }}>
                      {orderData.length}
                    </span> <img src={Vector} onClick={getSyncData} /></Col>
                  <Col span={2} align='end' > <img src={logOut} style={{ cursor: "pointer" }} onClick={() => { navigate("/") }} /></Col>
                </Row>
              </>
              }
            </>
            : <CashupPrint showModal={showModal} cashupDetails={cashupDetails} tillData={tillData} istempPrint={istempPrint} getCashupDetails={getCashupDetails} getPrint={getPrint} />
        }
        <Modal visible={isModalOpen} onOk={() => { handleOk("0") }} onCancel={handleCancel} height={240} width={400}>
          <Row>
            <Col span={2} />
            <Col span={20} style={{ textAlign: "center", fontWeight: "500", fontSize: "large" }}>
              {validateTextFlag === false ?
                <>
                  <p style={{ marginTop: "20px" }}>Warning Message: Master Refresh will take 15 Minutes to load.</p>
                  <p>Are you sure you want to continue?</p>
                </> :
                <p style={{ marginTop: "20px" }}>Please do MasterRefresh to continue.</p>
              }
            </Col>
            <Col span={2} />
          </Row>
        </Modal>
        <Modal visible={isIncrementModal} onOk={() => { handleOk("1") }} onCancel={() => { handleCancel("1") }} height={240} width={400}>
          <Row>
            <Col span={2} />
            <Col span={20} style={{ textAlign: "center", fontWeight: "500", fontSize: "large" }}>
              <p style={{ marginTop: "20px" }}>Are you sure you want to continue?</p>
            </Col>
            <Col span={2} />
          </Row>
        </Modal>
        <Modal open={isCashupNetworkModal} onOk={handleNetworkOk} cancelButtonProps={{ hidden: true }} height={240} width={400} >
          <Row>
            <Col span={2} />
            <Col span={20} style={{ textAlign: "center", fontWeight: "500", fontSize: "large" }}>
              <span style={{ marginTop: "20px" }}>Network error while loading cashup details</span>
            </Col>
            <Col span={2} />
          </Row>
        </Modal>
        <Modal open={isMasterNetworkModal} onOk={handleNetworkOk} cancelButtonProps={{ hidden: true }} height={240} width={400} >
          <Row>
            <Col span={2} />
            <Col span={20} style={{ textAlign: "center", fontWeight: "500", fontSize: "large" }}>
              <span style={{ marginTop: "20px" }}>Network error while doing master refresh</span>
            </Col>
            <Col span={2} />
          </Row>
        </Modal>
        <Modal open={isIncrementalNetworkModal} onOk={handleNetworkOk} cancelButtonProps={{ hidden: true }} height={240} width={400} >
          <Row>
            <Col span={2} />
            <Col span={20} style={{ textAlign: "center", fontWeight: "500", fontSize: "large" }}>
              <span style={{ marginTop: "20px" }}>Network error while doing incremental refresh</span>
            </Col>
            <Col span={2} />
          </Row>
        </Modal>
        <Modal open={isOrderDataModal} onOk={handleOrderDataOk} cancelButtonProps={{ hidden: true }} height={240} width={400}>
          <Row>
            <Col span={2} />
            <Col span={20} style={{ textAlign: "center", fontWeight: "500", fontSize: "large" }}>
              <span style={{ marginTop: "20px" }}>Bill Sync Pending</span>
            </Col>
            <Col span={2} />
          </Row>
        </Modal>
        <Modal
          visible={visible}
          onCancel={handleCloseModal}
          style={{ borderRadius: "20px" }}
          footer={null}
        >
          <div
            style={{
              color: "#000000",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            <h3>Choose Your Language</h3>
          </div>
          <Spin indicator={antIcon} spinning={languageSelectionloading}>
            <Row gutter={[16, 16]} justify="center" style={{ padding: "0 2em" }}>
              {languages.map((language, index) => {
                language_selected = localStorage.getItem("language_selected");
                const bgColor = language === selectedLanguage ? "gray" : "";
                return (
                  <Col xs={24} sm={12} md={12} key={language}>
                    <Button
                      size="medium"
                      value={language}
                      style={{
                        borderRadius: "10px",
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "1.5em 3em",
                        width: "100%",
                        backgroundColor: bgColor,
                      }}
                      onClick={(e) => handleSelectLanguage(e)}
                    >
                      {language}
                    </Button>
                  </Col>
                );
              })}
            </Row>
          </Spin>
        </Modal>
      </Spin>
    </div>
  );
}

export default Admin;