import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Spin, Modal, Form } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "../../antd.css";
import "../styles.css";
import backgroungImage from "../../assets/SCO_asset/signup/backgroundImage.png";
import Axios from "axios";
import URL from "../../app/server.json";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./style.css";
import db from "../../database";
import moment from "moment";
import CryptoJS from 'crypto-js';

const Login = () => {
  const keyboardRef1 = useRef(null);
  const keyboardRef2 = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [syncloading, setSyncloading] = useState({ status: false, message: "" });
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [keyboard, setKeyboard] = useState(1);
  const [layout, setlayout] = useState("default");
  const [userNameError, setUserNameError] = useState({ flag: false, msg: "" });
  const [cursorPosition, setCursorPosition] = useState(null);
  const [masterFlag, setMasterFlag] = useState(false);
  const [syncFlag, setSyncFlag] = useState(false);
  const [isShiftDoubleClick, setIsShiftDoubleClick] = useState(false);
  const [shiftDoubleClick, setShiftDoubleClick] = useState(false);
  const apiKey = URL.GRAVITEE_API_KEY;
  const config = {
    headers: {},
  };
  config.headers[URL.key_NAME] = apiKey;

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  useEffect(() => {
    (async () => {
      const sessionId = localStorage.getItem("sessionId");
      if (sessionId !== "" && typeof (sessionId) === "string") {
      } else {
        navigate("/signup");
      }
    })();
  }, []);

  useEffect(() => {
    localStorage.removeItem("orderObj")
    keyboardRef1.current.focus();
  }, []);

  const handleLogin = async () => {
    setLoading({ status: true, message: "" });
    let sessionId = localStorage.getItem("sessionId");
    let terminalNumber = localStorage.getItem("terminalNumber");
    const token = localStorage.getItem("token");
    const encryptedUsername = CryptoJS.AES.encrypt(userName, 'secret-key').toString();
    const encryptedPassword = CryptoJS.AES.encrypt(password, 'secret-key').toString();
    let basicAuth = `${userName}:${password}`;
    if (navigator.onLine === true) {
      let headers = {
        "Content-Type": "application/json",
        "token": `Basic ${window.btoa(basicAuth)},${token}`,
      }
      headers[URL.key_NAME] = apiKey;
      Axios({
        url: `${URL.GRAVITEE_BASE_URL}/login`,
        method: "GET",
        headers: headers,
        params: {
          remainingUrl: `/ws/com.promantia.decathlon.custom_selfcheckout.logincheck?l=userName&p=password&terminalname=${terminalNumber}&sessionId=${sessionId}`,
          "username": encryptedUsername,
          "password": encryptedPassword
        }
      }).then(async (response) => {
        if (response.data.data.status === "SUCCESS") {
          let cashier = JSON.parse(localStorage.getItem('cashierData'));
          cashier.Username = encryptedUsername;
          cashier.Password = encryptedPassword;
          localStorage.setItem("cashierData", JSON.stringify(cashier));
          localStorage.setItem("token", response.data.data.token);
          let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
          let body = {
            organization: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.organization,
            posterminal: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.touchpointtype,
            storeName: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.storeName,
            terminalNumber: localStorage.getItem("terminalNumber"),
            lastLoadedTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"),
          };
          let validateResponse = await Axios.post(
            `${URL.GRAVITEE_BASE_URL}/validateMasterRefresh`,
            body, config);
          if (validateResponse.data.statusCode === 200) {
            if (validateResponse.data.data === "No") {
              setLoading({ status: false, message: "" });
              let syncData = await db.syncData.toArray();
              if (syncData.length > 0) {
                setSyncFlag(true);
              } else {
                setMasterFlag(true);
              };
            } else {
              setLoading({ status: false, message: "" });
              let syncData = await db.syncData.toArray();
              if (syncData.length > 0) {
                navigate("/admin");
              } else {
                localStorage.setItem("isIncremenatalRefresh", true)
                localStorage.setItem("isMasterRefresh", false)
                navigate("/loading")
              };
            };
          };
        } else {
          setLoading({ status: false, message: "" });
          setUserNameError({ flag: true, msg: "Dublicate Terminal" });
          return null;
        }
      }).catch((err) => {
        setLoading({ status: false, message: "" });
        let cashierData = JSON.parse(localStorage.getItem("cashierData"));
        cashierData.Username = CryptoJS.AES.decrypt(cashierData.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
        cashierData.Password = CryptoJS.AES.decrypt(cashierData.Password, 'secret-key').toString(CryptoJS.enc.Utf8);
        if (err.response.status === 401) {
          setUserNameError({ flag: true, msg: "Enter Valid User Name & Password" });
        } else if (userName === cashierData.Username && password === cashierData.Password) {
          navigate("/admin");
        } else {
          setUserNameError({ flag: true, msg: err.response.data });
        };
      });
    } else {
      let cashierData = JSON.parse(localStorage.getItem("cashierData"));
      cashierData.Username = CryptoJS.AES.decrypt(cashierData.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
      cashierData.Password = CryptoJS.AES.decrypt(cashierData.Password, 'secret-key').toString(CryptoJS.enc.Utf8);
      if (userName === cashierData.Username && password === cashierData.Password) {
        setLoading({ status: false, message: "" });
        navigate("/admin");
      } else {
        setLoading({ status: false, message: "" });
        setUserNameError({ flag: true, msg: "Enter Valid User Name & Password" });
      }
    };
  };

  const onClickUserName = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(1)
    setShiftDoubleClick(false)
  }

  const onClickPassword = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(2)
    setShiftDoubleClick(false)
  }

  const onKeyPress = (button, e) => {
    setUserNameError({ flag: false, msg: "" });
    if (keyboard === 1) {
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setUserName(userName.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setUserName(userName)
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" && button !== "{tab}" && button !== "{enter}" && button !== "{space}" && button !== "{back}") {
        if (!shiftDoubleClick) {
          if (layout === "default") setlayout("shift");

        }
                let text = `${userName}${button}`
        setUserName(text.trim());
      }
    } else if (keyboard === 2) {
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setPassword(password.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setPassword(password)
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" && button !== "{tab}" && button !== "{enter}" && button !== "{space}" && button !== "{back}") {
        let text = `${password}${button}`
        if (!shiftDoubleClick) {
          if (layout === "default") setlayout("shift");

        }
                setPassword(text.trim());
      }
    }
    if (button === "{numbers}" || button === "{caps}") handleNumbers();
    if (button === "{number}" || button === "{small}") handleNumber();
    if (button === "{back}") handleReturn();
    if (button === "{space}") handleSpace();
    if (button === "{mic}") handleMic();
  };

  const handleMic = () => {
    setUserName(userName);
    setPassword(password);
    setlayout(layout)
  }

  const handleSpace = () => {
    if (keyboard === 1) {
      setUserName(userName + ' ');
    } else if (keyboard === 2) {
      setPassword(password + ' ');
    }
  }

  const handleShift = () => {
    const layout1 = layout;
    setlayout(layout1 === "default" ? "shift" : "default");
    if (isShiftDoubleClick) {
      setlayout('default')
      setShiftDoubleClick(true)
    } else {
      setIsShiftDoubleClick(true);

      setTimeout(() => {
        setIsShiftDoubleClick(false);
      }, 500);
    }
  };

  const handleNumbers = () => {
    const layout1 = layout;
    setlayout(layout1 === "default" ? "numbers" : "default");
    setUserName(userName)
    setPassword(password)
  }

  const handleNumber = () => {
    const layout1 = layout;
    setlayout(layout1 === "shift" ? "number" : "shift");
    setUserName(userName)
    setPassword(password)
  }

  const handleReturn = () => {
    if (keyboard === 1) {
      keyboardRef2.current.focus();
      setKeyboard(2)
    }
    else if (keyboard === 2) {
      keyboardRef1.current.focus();
      setKeyboard(1)
    }
    setUserName(userName);
    setPassword(password)
  }

  const handleUserName = (ev) => {
    setUserName(ev.target.value);
  };

  const handlePassword = (ev) => {
    setPassword(ev.target.value);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  const sectionStyle = {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${backgroungImage})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "fixed",
  }

  const blurred = {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${backgroungImage})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "fixed",
    filter: "blur(5px)",
    PointerEvent: "none"
  }

  const handleMasterOk = async () => {
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    let body = {
      organization: selectedProducts.tillData[0]?.storeDetail?.organization,
      posterminal: selectedProducts.tillData[0]?.storeDetail?.touchpointtype,
      storeName: selectedProducts.tillData[0]?.storeDetail?.storeName,
      terminalNumber: localStorage.getItem("terminalNumber"),
      lastLoadedTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"),
      loaded: "No"
    };
    let validateResponse = await Axios.post(`${URL.GRAVITEE_BASE_URL}/validateMasterRefresh`, body, config);
    if (validateResponse.data.statusCode === 200) {
      localStorage.setItem("isMasterRefresh", true)
      localStorage.setItem("isIncremenatalRefresh", false)
      navigate("/loading");
    };
  };

  const handleSyncOk = async () => {
    setSyncloading({ status: true, message: "" })
    const status = navigator.onLine ? "online" : "offline";
    if (status === "online") {
      let syncData = await db.syncData.toArray();
      let newData = [];
      syncData.map((item) => {
        if (item !== undefined) {
          newData.push(item[0])
        };
      });
      let token = localStorage.getItem("token");
      let cashier = JSON.parse(localStorage.getItem('cashierData'));
      cashier.Username = CryptoJS.AES.decrypt(cashier.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
      cashier.Password = CryptoJS.AES.decrypt(cashier.password, 'secret-key').toString(CryptoJS.enc.Utf8);
      let basicAuth = `${cashier.Username}:${cashier.Password}`;
      try {
        let body = {
          "bills": newData,
          "remainingUrl": `/ws/com.promantia.decathlon.custom_selfcheckout.order`,
          "Authorization": `Basic ${window.btoa(basicAuth)}, ${token}`
        };
        let responseData = await Axios.post(`${URL.GRAVITEE_BASE_URL}/order`, body, config);
        if (responseData.data.statusCode === 200) {
          await db.syncData.clear();
          setSyncloading({ status: false, message: "" });
          setSyncFlag(false);
          setMasterFlag(true);
        } else {
          setSyncloading({ status: false, message: "Order is not processed" });
        }
      } catch (error) {
        setSyncloading({ status: false, message: "Order is not processed" });
      };
    } else {
      setSyncloading({ status: false, message: "Order is not processed" });
    };
  };

  return (
    <div style={masterFlag === true || syncFlag === true ? blurred : sectionStyle}>
      <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
        <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
          <Row >
            <Col span={3}></Col>
            <Col span={18}>
              <p className="main-heading">WELCOME TO DECATHLON </p>
              <p className="sub-heading">Please enter your User ID & Password to proceed</p>
            </Col>
            <Col span={3}></Col>
          </Row>
          <Row justify="space-around" style={{ height: "60vh", isplay: "flex", alignItems: "center", justifyContent: "center" }}>
            <Col span={11} className="form-column" >
              <Form layout="vertical" className="login-form">
                <Form.Item >
                  <Row style={{ padding: "0 0 0 0.2vw", marginBottom: "-19px" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: "0 0 20px", height: "3px", backgroundColor: "#fff" }} />
                        <div style={{ margin: "0 10px", fontSize: "22px", color: "#fff" }}>
                          User Name                      </div>
                        <div style={{ flex: "1", height: "3px", backgroundColor: "#fff" }} />
                      </div>
                    </Col>
                  </Row>
                  <input inputMode="none" className="input-field" autoComplete="off" ref={keyboardRef1} value={userName} onChange={handleUserName} onFocus={() => { setKeyboard(1) }} onClick={onClickUserName} />
                </Form.Item>
                <Form.Item style={{ marginTop: "2vh" }}>
                  <Row style={{ padding: "0 0 0 0.2vw", marginBottom: "-19px" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: "0 0 20px", height: "3px", backgroundColor: "#fff" }} />
                        <div style={{ margin: "0 10px", fontSize: "22px", color: "#fff" }}>
                          Password                      </div>
                        <div style={{ flex: "1", height: "3px", backgroundColor: "#fff" }} />
                      </div>
                    </Col>
                  </Row>
                  <input inputMode="none" className="input-field" autoComplete="off" type={"password"} ref={keyboardRef2} value={password} onPressEnter={handleLogin} onChange={handlePassword} onFocus={() => { setKeyboard(2) }} onClick={onClickPassword} />
                </Form.Item>
                <div style={{ height: "1vh", fontSize: "2.5vh", color: userNameError.flag === false ? "#fff" : "red", position: "relative", top: "-6%" }}>{userNameError.flag === false ? "" : userNameError.msg}</div>
                <Button className="btn-continue" onClick={handleLogin} htmlType="submit" >CONTINUE</Button>
              </Form>
            </Col>
            <Col span={12} >
              <div className="keyboard-div">
                <Keyboard
                  buttonTheme={[
                    {
                      class: "hg-yellow",
                      buttons: "{space}"
                    },
                    {
                      class: "hg-mail",
                      buttons: "@yahoo.com @gmail.com"
                    },
                    {
                      class: "hg-space",
                      buttons: "{space}"
                    },
                  ]}
                  layoutName={layout}
                  inputName={keyboard === 1 ? "un" : "psd"}
                  onChange={(val) => { keyboard === 1 ? setUserName(val) : setPassword(val) }}
                  onKeyPress={onKeyPress}
                  theme={"hg-theme-default signUp "}
                  display={{
                    "{abc}": "ABC",
                    "{smileys}": "\uD83D\uDE03",
                    "{back}": "Enter",
                    "{numbers}": "123",
                    "{number}": "123",
                    '{bksp}': 'backspace',
                    "{backspace}": "⌫",
                    "{shift}": "⇧",
                    '{tab}': 'tab',
                    '{lock}': 'lock',
                    '{enter}': 'enter',
                    '{space}': "Space",
                    "{caps}": "ABC",
                    "{small}": "abc",
                  }}
                  layout={{
                    shift: [
                      "q w e r t y u i o p",
                      "a s d f g h j k l",
                      "{shift} z x c v b n m {backspace}",
                      "{number} {space} @yahoo.com @gmail.com"
                    ],
                    numbers: [
                      "1 2 3 4 5 6 7 8 9 0",
                      '- / : ; ( ) $ & @ "',
                      '# . , _ ! ` {backspace}',
                      "{caps} {space} {back}"
                    ],
                    number: [
                      "1 2 3 4 5 6 7 8 9 0",
                      '- / : ; ( ) $ & @ "',
                      '# . , _ ! ` {backspace}',
                      "{small} {space} {back}"
                    ],
                    default: [
                      "Q W E R T Y U I O P",
                      "A S D F G H J K L",
                      "{shift} Z X C V B N M {backspace}",
                      "{numbers} {space} @yahoo.com @gmail.com"
                    ],
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
      <Modal visible={syncFlag} onOk={handleSyncOk} centered closable={false} height={240} width={400} footer={[
        <Button key="submit" type="primary" onClick={handleSyncOk}>
          Continue
        </Button>
      ]}>
        <Spin indicator={antIcon} spinning={syncloading.status}>
          <Row>
            <Col span={2} />
            <Col span={20} style={{ textAlign: "center", fontWeight: "500", fontSize: "large" }}>
              <p style={{ marginTop: "20px" }}>Bill sync pending. Please click continue to sync the bills.</p>
              {syncloading.message !== "" ? <span style={{ fontSize: "10px", color: "red" }}>{syncloading.message}</span> : ""}
            </Col>
            <Col span={2} />
          </Row>
        </Spin>
      </Modal>
      <Modal visible={masterFlag} onOk={handleMasterOk} centered closable={false} height={240} width={400} footer={[
        <Button key="submit" type="primary" onClick={handleMasterOk}>
          Continue
        </Button>
      ]}>
        <Row>
          <Col span={2} />
          <Col span={20} style={{ textAlign: "center", fontWeight: "500", fontSize: "large" }}>
            <p style={{ marginTop: "20px" }}>Master refresh required. Please click continue to proceed.</p>
          </Col>
          <Col span={2} />
        </Row>
      </Modal>
    </div>
  );
};

export default Login;
