import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, message, Modal, Input, Button, Spin, Card } from "antd";
import { LoadingOutlined, LeftCircleOutlined } from '@ant-design/icons';
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import Axios from "axios";
import QRCode from "react-qr-code";
import "./style.css";
import URL from "../../app/server.json";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { useTranslation } from 'react-i18next';
import { scoLog } from "./scoLog";
import moment from "moment";
import { isEmpty } from "lodash";
import CryptoJS from 'crypto-js';

const QrPayment = () => {
  const keyboardRef1 = useRef(null);
  const keyboardRef2 = useRef(null);
  const navigate = useNavigate();
  const [qrData, setQrData] = useState();
  const [time, setTime] = useState("");
  const [msg, setMsg] = useState("");
  const [loginFlag, setLoginFlag] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [transactionFlag, setTransactionFlag] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [validateData, setValidateData] = useState();
  const [name, setName] = useState("");
  const [keyboard, setKeyboard] = useState(1);
  const [layoutUserName, setlayoutUserName] = useState("default");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userNameError, setUserNameError] = useState({ flag: false, msg: "" });
  const [tIdError, setTIdError] = useState({ flag: false, msg: "" });
  const [backFlag, setBackFlag] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [isShiftDoubleClick, setIsShiftDoubleClick] = useState(false);
  const [shiftDoubleClick, setShiftDoubleClick] = useState(false);
  const [giftCardPayment, setGiftCardPayment] = useState({});
  const apiKey = URL.GRAVITEE_API_KEY;
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual");
  let userData = JSON.parse(localStorage.getItem('userData'));
  let index = 0;

  useEffect(() => {
    let data = localStorage.getItem("giftCardPayment") ? JSON.parse(localStorage.getItem("giftCardPayment")) : {}
    setGiftCardPayment(data)
    let tempName = localStorage.getItem("surname");
    setName(tempName);
  }, []);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  // Order Obj creation Start
  let selectedProducts = JSON.parse(localStorage.getItem("selectedProducts"));
  let orderDate = localStorage.getItem("orderDate");
  let salebegintime = localStorage.getItem("salebegintime");
  let doorNo;
  let street;
  let zipcode;
  let city;
  let country;
  let state;
  let email;
  let mobile;
  let total = 0;
  let discount = 0;
  let decathlonId;
  let cashierData = JSON.parse(localStorage.getItem("cashierData"));
  let orderId = Number(localStorage.getItem("order_lastassignednum")) + 1;
  let terminalId = localStorage.getItem("terminalNumber");
  let customerFlag = localStorage.getItem("customerSignupFlag");
  let customerEmail = localStorage.getItem("email");
  let customerPhoneNo = localStorage.getItem("phoneNo");
  let customerName = localStorage.getItem("surname");
  let customerPincodeNo = localStorage.getItem("pincodeNo");
  let cashierUsername = CryptoJS.AES.decrypt(cashierData.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
  let cashierPassword = CryptoJS.AES.decrypt(cashierData.Password, 'secret-key').toString(CryptoJS.enc.Utf8);
  let basicAuth = `${cashierUsername}:${cashierPassword}`
  let token = localStorage.getItem("token");

  let linesArray = [];
  let taxDetails = [];
  selectedProducts = selectedProducts === null ? {} : selectedProducts;
  if (selectedProducts.tillData !== undefined || selectedProducts.tillData !== null
  ) {
    selectedProducts.productsData.map((item, ind) => {
      total = total + item.sale_price;
      discount = discount + (item.sale_price * item.percentage) / 100;
    });
    let taxData = JSON.parse(localStorage.getItem("taxData"));
    taxData.newArray.map((item) => {
      JSON.parse(item.tax).map((e, ind) => {
        if (e.name[0] === "C") {
          let taxableAmount = Number(item.taxableAmount * item.count);
          let sellingAmount = item.discountSalePrice;
          let netAmount = sellingAmount - taxableAmount;
          let obj = {
            "itemCode": item.id,
            "unitPrice": item.discountPrice,
            "ean": item.ean,
            "serialNumber": item.ean,
            "rfidscancode": "",
            "epcCode": item.epcCode,
            "offer": false,
            "offerName": item.discountName === undefined ? "" : item.discountName === undefined,
            "type": "takeaway",
            "unitDiscount": item.discountValue,
            "qty": item.count,
            "taxCategory": e.name,
            "taxRate": e.rate,
            "sellingAmount": sellingAmount,
            "taxableAmt": taxableAmount.toFixed(2),
            "ISGST": item.isgst.toFixed(2),
            "ICGST": item.icgst,
            "netAmount": netAmount.toFixed(2),
            "taxId": e.taxId,
            "swap": item.swap,
            "tax": item.tax,
          };
          linesArray.push(obj);
        };
      });
    });
    taxData.taxArray.map((element) => {
      if (JSON.parse(element.gst).length > 0) {
        let index = 0;
        let taxCategoryName = JSON.parse(element.gst)?.findIndex(item => item.name.charAt(0) === "S");
        if (taxCategoryName !== -1) {
          index = taxCategoryName;
        };
        JSON.parse(element.gst).map((item, index) => {
          if (item.name === "CGST-LS" || item.name === "CGST-LY" || item.name === "CGST-LP") {
            if (index === 0) {
              if (JSON.parse(element.gst)[1].name.charAt(0) === "S") {
                let taxObj = {
                  "taxCategory": JSON.parse(element.gst)[1].name,
                  "taxRate": element.key / 2,
                  "TSGST": element.isgst.toFixed(2),
                  "TCGST": element.icgst.toFixed(2),
                  "CGST": element.GroupCGST,
                  "SGST": element.GroupSGST,
                  "sellingAmount": element.discountSalePrice,
                  "taxedAmount": element.taxPrice,
                  "netAmount": (element.basePrice).toFixed(2),
                  "taxId": JSON.parse(element.gst)[1].taxId,
                  "verify": element.verify,
                  "gst": JSON.parse(element.gst),
                };
                taxDetails.push(taxObj);
              }
            } else {
              if (JSON.parse(element.gst)[0].name.charAt(0) === "S") {
                let taxObj = {
                  "taxCategory": JSON.parse(element.gst)[0].name,
                  "taxRate": element.key / 2,
                  "TSGST": element.isgst.toFixed(2),
                  "TCGST": element.icgst.toFixed(2),
                  "CGST": element.GroupCGST,
                  "SGST": element.GroupSGST,
                  "sellingAmount": element.discountSalePrice,
                  "taxedAmount": element.taxPrice,
                  "netAmount": (element.basePrice).toFixed(2),
                  "taxId": JSON.parse(element.gst)[0].taxId,
                  "verify": element.verify,
                  "gst": JSON.parse(element.gst),
                };
                taxDetails.push(taxObj);
              }
            }
          } else if (item.name !== "CGST-LS" || item.name !== "CGST-LY" || item.name !== "CGST-LP" || item.name !== "SGST-LS" || item.name !== "SGST-LY" || item.name !== "SGST-LP") {
            if (item.name.charAt(0) === "C") {
              let taxObj = {
                "taxCategory": item.name,
                "taxRate": element.key / 2,
                "TSGST": element.isgst.toFixed(2),
                "TCGST": element.icgst.toFixed(2),
                "CGST": element.GroupCGST,
                "SGST": element.GroupSGST,
                "sellingAmount": element.discountSalePrice,
                "taxedAmount": element.taxPrice,
                "netAmount": (element.basePrice).toFixed(2),
                "taxId": item.taxId,
                "verify": element.verify,
                'gst': JSON.parse(element.gst),
              };
              taxDetails.push(taxObj);
            }
          }
        });
      };
    });
    doorNo = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeAddressline1;
    street = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeAddressline2;
    zipcode = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storePostalCode;
    city = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeCityName;
    country = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeCountry;
    state = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeRegion;
    if (userData !== undefined) {
      if (userData !== null) {
        email = userData.result.Email !== undefined ? userData.result.Email : customerEmail;
        mobile = userData.result.Mobile !== undefined ? userData.result.Mobile : customerPhoneNo;
        decathlonId = userData.result.CardNumber;
      }
    }
  }

  let obj = {
    address: [
      {
        doorNo: `${doorNo}`,
        street: `${street}`,
        zipcode: `${zipcode}`,
        city: `${city}`,
        country: `${country}`,
        state: `${state}`,
        type: "billing",
      },
    ],
    MyDecathlonId: decathlonId !== undefined ? `${decathlonId}` : "",
    email: !isEmpty(email) ? CryptoJS.AES.encrypt(email, 'secret-key').toString() : isEmpty(customerEmail) ? "" : CryptoJS.AES.encrypt(customerEmail, 'secret-key').toString(),
    mobile: !isEmpty(mobile) ? CryptoJS.AES.encrypt(mobile, 'secret-key').toString() : isEmpty(customerPhoneNo) ? "" : CryptoJS.AES.encrypt(customerPhoneNo, 'secret-key').toString(),
    zipcode: customerPincodeNo || "",
    paymentMethod: "PhonePe",
    name: customerFlag === "false" ? name : customerName,
    salebegintime: `${salebegintime}`,
    orderDate: `${moment(orderDate).format("DD-MM-YYYY")}`,
    totalGrossAmount: Number((total - discount).toFixed(2)),
    totalDiscount: 0,
    lines: linesArray.reverse(),
    store: `${"7" +
      terminalId.toString().substring(2, 6) +
      terminalId.toString().substring(2, 6)
      }`,
    terminal: `${terminalId}`,
    orderNumber: `${terminalId}/${Number(orderId)}`,
    payment: [],
    saleendtime: `${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}`,
    PaymentDone: "true",
    cashiername: `${cashierData.Username}`,
    ebillMode: "",
    taxDetails: taxDetails,
  };

  // Order Obj creation End

  useEffect(() => {
    if (index === 0) {
      let total = 0;
      let discount = 0;
      let callBackApi;
      let checkStatusApi;
      let dynamicQrApi;
      let dynamicQrAuthIndex;
      let dynamicQrAuthkey;
      let expiresInTime;
      let phonePeMerchantId;
      let verifyLevel2;
      let cashierData = JSON.parse(localStorage.getItem('cashierData'));
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      let terminalId = localStorage.getItem("terminalNumber");
      let data = localStorage.getItem("giftCardPayment") ? JSON.parse(localStorage.getItem("giftCardPayment")) : {}
      selectedProducts = selectedProducts === null ? {} : selectedProducts;
      if (selectedProducts.productsData !== undefined || selectedProducts.productsData !== null) {
        selectedProducts.productsData.map((item, ind) => {
          total = total + Number(item.sale_price);
          discount = discount + (Number(item.sale_price) * item.percentage) / 100;
        });
        setTime(JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.verifyTimeout);
        callBackApi = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.callBackApi;
        checkStatusApi = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.checkStatusApi;
        dynamicQrApi = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.dynamicQrApi;
        dynamicQrAuthIndex = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.dynamicQrAuthIndex;
        dynamicQrAuthkey = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.dynamicQrAuthkey;
        expiresInTime = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.expiresInTime;
        phonePeMerchantId = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.phonePeMerchantId;
        verifyLevel2 = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.verifyLevel2;
      };
      let headers = {
        "Content-Type": "application/json",
      }
      data.giftCard === true ? Number((total - discount - data.amountPaid).toFixed(2)) : (total - discount).toFixed(2)
      headers[URL.key_NAME] = apiKey;
      Axios({
        url: `${URL.GRAVITEE_BASE_URL}/qrCode`,
        method: "GET",
        data: {
          "amount": CryptoJS.AES.encrypt((data.giftCard === true ? total - discount - data.amountPaid : total - discount).toFixed(2), 'secret-key').toString(),
          "callBackApi": `${callBackApi}`,
          "cashierName": `${cashierData.Username}`,
          "checkStatusApi": `${checkStatusApi}`,
          "dynamicQrApi": `${dynamicQrApi}`,
          "dynamicQrAuthIndex": dynamicQrAuthIndex,
          "dynamicQrAuthkey": `${dynamicQrAuthkey}`,
          "expiresInTime": `${expiresInTime}`,
          "message": "Plase complete the payment",
          "phonePeMerchantId": `${phonePeMerchantId}`,
          "storeId": `${'7' + terminalId.toString().substring(2, 6) + terminalId.toString().substring(2, 6)}`,
          "terminalId": `${terminalId}`,
          "transactionId": `${terminalId}/${Number(orderId)}`,
          "verifyLevel2": `${verifyLevel2}`
        },
        headers: headers,
        params: {
          "amount": CryptoJS.AES.encrypt((data.giftCard === true ? total - discount - data.amountPaid : total - discount).toFixed(2), 'secret-key').toString(),
          "callBackApi": `${callBackApi}`,
          "cashierName": `${cashierData.Username}`,
          "checkStatusApi": `${checkStatusApi}`,
          "dynamicQrApi": `${dynamicQrApi}`,
          "dynamicQrAuthIndex": dynamicQrAuthIndex,
          "dynamicQrAuthkey": `${dynamicQrAuthkey}`,
          "expiresInTime": `${expiresInTime}`,
          "message": "Plase complete the payment",
          "phonePeMerchantId": `${phonePeMerchantId}`,
          "storeId": `${'7' + terminalId.toString().substring(2, 6) + terminalId.toString().substring(2, 6)}`,
          "terminalId": `${terminalId}`,
          "transactionId": `${terminalId}/${Number(orderId)}`,
          "verifyLevel2": `${verifyLevel2}`
        },
      }).then((response) => {
        if (response.data.statusCode === 200 && response.data.data.qrCode) {
          setQrData(response.data.data);
          localStorage.setItem("transactionId", response.data.data.transactionId);
        } else {
          setQrData([]);
          setBackFlag(true);
        }
      }).catch(err => {
        setQrData([]);
        setBackFlag(true);
      })
    };
    index++;
  }, []);

  useEffect(() => {
    if (time !== 0) {
      const timer = setInterval(() => {
        if (qrData?.transactionId !== undefined) {
          let callBackApi;
          let checkStatusApi;
          let dynamicQrApi;
          let dynamicQrAuthIndex;
          let dynamicQrAuthkey;
          let expiresInTime;
          let phonePeMerchantId;
          let verifyLevel2;
          let statusCheckDuration;
          let verifyTimeout;
          let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
          selectedProducts = selectedProducts === null ? {} : selectedProducts;
          if (selectedProducts.productsData !== undefined || selectedProducts.productsData !== null) {
            callBackApi = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.callBackApi;
            checkStatusApi = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.checkStatusApi;
            dynamicQrApi = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.dynamicQrApi;
            dynamicQrAuthIndex = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.dynamicQrAuthIndex;
            dynamicQrAuthkey = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.dynamicQrAuthkey;
            expiresInTime = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.expiresInTime;
            phonePeMerchantId = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.phonePeMerchantId;
            verifyLevel2 = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.verifyLevel2;
            statusCheckDuration = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.statusCheckDuration;
            verifyTimeout = JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.verifyTimeout;
          };
          let headers = {
            "Content-Type": "application/json",
          }
          headers[URL.key_NAME] = apiKey;
          Axios({
            url: `${URL.GRAVITEE_BASE_URL}/validate`,
            method: "GET",
            data: {
              "callBackApi": `${callBackApi}`,
              "checkStatusApi": `${checkStatusApi}`,
              "dynamicQrApi": `${dynamicQrApi}`,
              "dynamicQrAuthIndex": dynamicQrAuthIndex,
              "dynamicQrAuthkey": `${dynamicQrAuthkey}`,
              "expiresInTime": `${expiresInTime}`,
              "phonePeMerchantId": `${phonePeMerchantId}`,
              "statusCheckDuration": `${statusCheckDuration}`,
              "transactionId": `${qrData?.transactionId}`,
              "verifyLevel2": `${verifyLevel2}`,
              "verifyTimeout": `${verifyTimeout}`
            },
            headers: headers,
            params: {
              "callBackApi": `${callBackApi}`,
              "checkStatusApi": `${checkStatusApi}`,
              "dynamicQrApi": `${dynamicQrApi}`,
              "dynamicQrAuthIndex": dynamicQrAuthIndex,
              "dynamicQrAuthkey": `${dynamicQrAuthkey}`,
              "expiresInTime": `${expiresInTime}`,
              "phonePeMerchantId": `${phonePeMerchantId}`,
              "statusCheckDuration": `${statusCheckDuration}`,
              "transactionId": `${qrData?.transactionId}`,
              "verifyLevel2": `${verifyLevel2}`,
              "verifyTimeout": `${verifyTimeout}`
            }
          }).then((response) => {
            if (response.data.statusCode === 200) {
              setValidateData(response.data.data);
              setMsg(JSON.parse(response.data.data.message));
              if (JSON.parse(response.data.data.message) === "Your payment is successful.") {
                localStorage.setItem("response", JSON.stringify(response.data));
                let id = localStorage.getItem("transactionId");
                obj.payment = [
                  {
                    responemessage: `${response === "undefined" ? "" : response.data.data.responseMessage}`,
                    message: `${response === "undefined" ? "" : response.data.data.message}`,
                    manualverificationid: "",
                    paymentRefId: JSON.parse(response.data.data.responseMessage).data?.providerReferenceId,
                    ismanual: "N",
                    isphonepe: "Y",
                    cashiername: `${cashierData.Username}`,
                    transactionid: `${id}`,
                    totalamountpaid: Number((giftCardPayment.giftCard === true ? total - discount - giftCardPayment.amountPaid : total - discount).toFixed(2)),
                    paymentMethod: "PhonePe"
                  },
                ];
                if(giftCardPayment.giftCard === true){
                  giftCardPayment.payment.map((payments)=>{
                    obj.payment.push(payments)
                  })
                }
                localStorage.setItem("orderObj", JSON.stringify(obj));
                // let orderId = Number(localStorage.getItem("order_lastassignednum")) + 1;
                localStorage.setItem("order_lastassignednum", Number(orderId));
                localStorage.setItem("tempQrTransactionId", JSON.parse(response.data.data.responseMessage).data?.providerReferenceId);
                scoLog();
                navigate("/success-payment");
              } else if (JSON.parse(response.data.data.message) === "Payment Failed") {
              }
            } else {
              message.error("Something is wrong");
              navigate("/qr-payment");
            }
          });
        }
      }, 1000);
      if (msg === "Your payment is successful." || msg === "Payment Failed") {
        clearInterval(timer);
      }
      return () => {
        clearInterval(timer);
      };
    }
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(time - 1);
    }, 1000);
    if (time === 0) {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  });

  const handleUsername = (ev) => {
    setUserNameError({ flag: false, msg: "" });
    setUsername(ev.target.value);
  };

  const handlePassword = (ev) => {
    setUserNameError({ flag: false, msg: "" });
    setPassword(ev.target.value);
  };

  const handleTransactionId = (ev) => {
    setTIdError({ flag: false, msg: "" });
    setTransactionId(ev.target.value);
  };

  const handleVerify = () => {
    setTransactionFlag(false);
    localStorage.setItem("tempQrTransactionId", transactionId);
    localStorage.setItem("response", JSON.stringify(validateData));
    let id = localStorage.getItem("transactionId");
    obj.payment = [
      {
        responemessage: `${validateData === "undefined" ? "" : validateData.responseMessage
          }`,
        message: `${validateData === "undefined" ? "" : validateData.message}`,
        manualverificationid: transactionId,
        paymentRefId: transactionId,
        ismanual: "Y",
        isphonepe: "Y",
        cashiername: `${cashierData.Username}`,
        transactionid: `${id}`,
        totalamountpaid: Number((giftCardPayment.giftCard === true ? total - discount - giftCardPayment.amountPaid : total - discount).toFixed(2)),
        paymentMethod: "PhonePe"
      },
    ];
    if(giftCardPayment.giftCard === true){
      giftCardPayment.payment.map((payments)=>{
        obj.payment.push(payments)
      })
    }
    localStorage.setItem("orderObj", JSON.stringify(obj));
    // let orderId = Number(localStorage.getItem("order_lastassignednum")) + 1;
    localStorage.setItem("order_lastassignednum", Number(orderId));
    let ebill = localStorage.getItem("ebill");
    scoLog();
    if (ebill === "true" && transactionId !== "") {
      scoLog(name);
      navigate("/bill-mode");
    }
    else {
      if (transactionId === "") {
        setTransactionFlag(true)
        setlayoutUserName("numbers")
        setTIdError({ flag: true, msg: "Please Enter Transaction Id" });
      }
      else {
        localStorage.setItem("billMode", "Printed Receipt");
        scoLog(name);
        navigate("/feedback-steptwo");
      }
    };
  };

  const handleLogin = () => {
    let cashierData = JSON.parse(localStorage.getItem('cashierData'));
    cashierData.Username = CryptoJS.AES.decrypt(cashierData.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
    cashierData.Password = CryptoJS.AES.decrypt(cashierData.Password, 'secret-key').toString(CryptoJS.enc.Utf8);
    if (username === cashierData.Username && password === cashierData.Password) {
      setlayoutUserName("numbers")
      setTransactionFlag(true);
    } else {
      setUserNameError({ flag: true, msg: "Please give valid credentials" });
    }
  };

  const onKeyPressNumber = (button) => {
    setTIdError({ flag: false, msg: "" });
    if (button === "{backspace}") {
      if (cursorPosition !== 0) {
        setTransactionId(transactionId.slice(0, -1));
        setCursorPosition(cursorPosition - 1)
      }
      else {
        setTransactionId(transactionId)
      }
    };
    if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" & button !== "{tab}" && button !== "{enter}" & button !== "{space}") {
      if (layoutUserName === "default") setlayoutUserName("shift");
      let text = `${transactionId}${button}`
      setTransactionId(text.trim());
    }
    if (button === "{shift}" || button === "{lock}") handleShift()
    if (button === "{numbers}" || button === "{caps}") handleNumbers();
    if (button === "{number}" || button === "{small}") handleNumber();
    if (button === "{space}") handleSpace();
    if (button === "{back}") setTransactionId(transactionId);
    if (button === "{mic}") handleMic();
  };

  const onClickUserName = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(1)
    setShiftDoubleClick(false)
  }

  const onClickPassword = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(2)
    setShiftDoubleClick(false)
  }

  const onClickTransactionId = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(3)
    setShiftDoubleClick(false)
  }

  const onKeyPress = (button) => {
    setUserNameError({ flag: false, msg: "" });
    if (keyboard === 1) {
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setUsername(username.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setUsername(username)
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" & button !== "{tab}" && button !== "{enter}" & button !== "{space}") {
        if (!shiftDoubleClick) {
          if (layoutUserName === "default") setlayoutUserName("shift");
        }
        let text = `${username}${button}`
        setUsername(text.trim());
      }
    } else if (keyboard === 2) {
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setPassword(password.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setPassword(password)
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" & button !== "{tab}" && button !== "{enter}" & button !== "{space}") {
        if (!shiftDoubleClick) {
          if (layoutUserName === "default") setlayoutUserName("shift");
        } let text = `${password}${button}`
        setPassword(text.trim());
      }
    }
    if (button === "{numbers}" || button === "{caps}") handleNumbers();
    if (button === "{number}" || button === "{small}") handleNumber();
    if (button === "{back}") handleReturn();
    if (button === "{space}") handleSpace();
    if (button === "{mic}") handleMic();
  };

  const handleMic = () => {
    setUsername(username);
    setPassword(password);
    setTransactionId(transactionId)
    setlayoutUserName(layoutUserName)
  };

  const handleShift = () => {
    const layout1 = layoutUserName;
    setlayoutUserName(layout1 === "default" ? "shift" : "default");
    setUsername(username);
    setPassword(password);
    setTransactionId(transactionId)
    if (isShiftDoubleClick) {
      setlayoutUserName('default')
      setShiftDoubleClick(true)
    } else {
      setIsShiftDoubleClick(true);

      setTimeout(() => {
        setIsShiftDoubleClick(false);
      }, 500);
    }
  };

  const handleNumber = () => {
    const layout1 = layoutUserName;
    setlayoutUserName(layout1 === "shift" ? "number" : "shift");
    setUsername(username);
    setPassword(password);
    setTransactionId(transactionId)
  };

  const handleNumbers = () => {
    const layout1 = layoutUserName;
    setlayoutUserName(layout1 === "default" ? "numbers" : "default");
    setUsername(username);
    setPassword(password);
    setTransactionId(transactionId)
  };

  const handleReturn = () => {
    if (keyboard === 1) {
      keyboardRef2.current.focus();
      setKeyboard(2)
    }
    else if (keyboard === 2) {
      keyboardRef1.current.focus();
      setKeyboard(1)
    }
    setUsername(username);
    setPassword(password)
  };

  const handleSpace = () => {
    if (keyboard === 1) {
      setUsername(username + ' ');
    } else if (keyboard === 2) {
      setPassword(password + ' ');
    }
    setTransactionId(transactionId + ' ');
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;
  const giftcardToken = async () => {
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    if (selectedProducts.tillData !== undefined || selectedProducts.tillData !== null) {
      let orderId = localStorage.getItem("order_lastassignednum");
      let terminalId = localStorage.getItem("terminalNumber");
      const formattedDateTime = `d_ikey_${moment().format('YYYY-MM-DD')}T${moment().format('HH:mm:ss')}`;
      let body = {
        "UserName": `${URL.Gift_Card_token_UserName}`,
        "Password":  `${URL.Gift_Card_token_Password}`,
        "ForwardingEntityId":  `${URL.Gift_Card_token_ForwardingEntityId}`,
        "ForwardingEntityPassword":  `${URL.Gift_Card_token_ForwardingEntityPassword}`,
        "TransactionId":  `${URL.Gift_Card_token_TransactionId}`,
        "TerminalId":  `${URL.Gift_Card_token_TerminalId}`,
        "idempotencyKey": formattedDateTime
      };
      const config = {
        headers: {
          'X-Gravitee-Api-Key': apiKey,
        },
      };
      try {
        const res = await Axios.post(`${URL.GRAVITEE_BASE_URL}/giftcardToken`, body, config);
        if (res.data.statusCode === 200) {
          localStorage.setItem("giftCardToken", JSON.stringify(res?.data?.data))
          onCancelGiftCard()
          let obj =
          {
            "store": JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.value,
            "terminal": terminalId,
            "apiType": "Authentication",
            "documentno": `${terminalId}/${Number(orderId)}`,
            "amount": 0,
            "cardNumber": "",
            "idempotencyKey": null,
            "transactionId": "43093",
            "approvalCode": "",
            "qwikcilverRequest": body,
            "qwikcilverResponse": res.data.data
          }

          let bodyData = {
            "logs": obj,
            "remainingUrl": `/ws/com.openbravo.decathlon.retail.giftcards.qwikCilverLog`,
            "Authorization": `Basic ${window.btoa(basicAuth)}, ${token}`
          };

          await Axios.post(`${URL.GRAVITEE_BASE_URL}/qwikCilverLog`, bodyData, config);
        };
      } catch (error) {
        message.error("Something is wrong in get token for discounts service");
      };
    };
  };
  const onCancelGiftCard = async () => {
    const tokenDetails = JSON.parse(localStorage.getItem("giftCardToken"))
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    let giftCardData = JSON.parse(localStorage.getItem("giftCardPayment"));
    if (giftCardData !== undefined && giftCardData !== null) {
      let body = {
        "inputType": "1",
        "numberOfCards": 1,
        "Cards": [
          {
            "CardNumber": giftCardData.response.Cards[0].CardNumber,
            "OriginalRequest":
            {
              "OriginalBatchNumber": giftCardData.response.CurrentBatchNumber,
              "OriginalTransactionId": giftCardData.response.TransactionId,
              "OriginalAmount": giftCardData.TotalAmount,
              "OriginalInvoiceNumber": giftCardData.response.Cards[0].InvoiceNumber
            },
            "Reason": giftCardData.response.Notes.split("|")[0].replace(/{|}/g, "")
          }
        ],
      };
      let headers = {
        "DateatClient": "2021-09-22T13:17:00",
        "Authorization": `Bearer ${tokenDetails?.AuthToken}`,
        "TransactionId": giftCardData.response.TransactionId,
        "MerchantOutletNameToOverride": "Decathlon-Test-01",
      }
      try {
        const res = await Axios.post(`${URL.CANCEL_GIFTCARD_URL}`, body, { headers });
        if (res.status === 200) {
          localStorage.removeItem("giftCardPayment");
        }
      } catch (error) {
        console.log(error);
      }
    }

  }
  const cancelPayment = () => {
    let giftCardData = JSON.parse(localStorage.getItem("giftCardPayment"));
    if (giftCardData !== undefined && giftCardData !== null) {
      giftcardToken()
    }
    setTimeout(() => {
      navigate("/payment-selection");
    }, 2000)  }
  return (
    <div>
      <Spin indicator={antIcon} spinning={qrData !== undefined ? false : true} >
        <Row align='middle' style={{ backgroundColor: "#0072AF", padding: "5px 0 5px 50px", height: "10vh" }} >
          <Col span={6}>
            <img src={Logo} alt="Logo" style={{ height: "50px" }} />
          </Col>
          <Col span={12}>
          </Col>
          <Col span={6} >
            <div style={{ display: "flex", float: "right", alignItems: "center" }}>
              <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
              </span>
              <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
                <LeftCircleOutlined style={{ fontSize: "24px" }} onClick={() => setIsModalOpen(true)} />
                <span style={{ fontSize: "15px" }}>Back</span>
              </span>
            </div>
          </Col>
        </Row>
        {!loginFlag ?
          <Row justify="space-around" style={{ height: "90vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Col span={11} className="qr-card-block" style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              <>
                {qrData?.qrCode !== undefined ?
                  (time !== 0 ?
                    <>
                      <span style={{ color: "rgba(0, 0, 0, 0.66)", fontSize: "3vh", fontWeight: 700 }}>
                        Please scan the QR code to make the payment
                      </span>
                      {multilingual === "true" && (
                        <span style={{ color: "rgba(0, 0, 0, 0.66)", fontSize: "3vh", fontWeight: 700 }}>{t('please scan the QR code to make the payment')}</span>
                      )}
                      <Card className="billmode-card" style={{ borderRadius: "6px", marginTop: "4vh", height: "35vh" }}>
                        <QRCode value={qrData?.qrCode} style={{ height: "28.6vh", width: "20vw" }} />
                      </Card>
                      <div style={{ marginTop: "2vh" }}>
                        <h2 style={{ color: "#FA0404", marginBottom: "0rem" }}>{time}</h2>
                        <h2 className="payment-status-text">Payment Incomplete</h2>
                      </div>
                      <p />
                      <Button className="back-btn" style={{ fontWeight: "600" }} onClick={() => { setIsModalOpen(true); }}>
                        Cancel
                        {multilingual === "true" && (
                          <>
                            <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                            <span style={{ marginLeft: "0.3rem" }}>{t('cancel')}</span>
                          </>
                        )}
                      </Button>
                    </>
                    :
                    <>
                      <div style={{ marginTop: "2vh" }}>
                        <p className="seconds-left" style={{ cursor: "pointer", fontSize: "8vh" }} onClick={() => { setTime(0); setLoginFlag(true); }}>?</p>
                        <h2 className="payment-status-text">Payment Incomplete</h2>
                      </div>
                      <p style={{ fontFamily: "Roboto Condensed", color: "#767677", fontSize: "18px", width: "80vw", position: "relative", fontWeight: 700 }}>Click on the BACK button to choose a different payment method or go to SUPPORT to manually verify</p>
                      <Button className="back-btn" onClick={() => { setIsModalOpen(true); }}>
                        Cancel
                        {multilingual === "true" && (
                          <>
                            <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                            <span style={{ marginLeft: "0.3rem" }}>{t('cancel')}</span>
                          </>)}
                      </Button>
                    </>
                  )
                  :
                  ""
                }
              </>
            </Col>
          </Row>
          :
          !transactionFlag ?
            <div>
              <Row>
                <Col span={1} />
                <Col span={22} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <p style={{ fontSize: "3vh", fontWeight: "600" }}>Enter Admin Credentials</p>
                  <div style={{ marginBottom: "6vh" }}>
                    <Input inputMode="none" className="cp-input-field" ref={keyboardRef1} onFocus={() => setKeyboard(1)} onClick={onClickUserName} autoFocus={true} value={username} onChange={handleUsername} placeholder="User Name" />
                    <div className="cp-field-lable" >
                      User Name
                      {multilingual === "true" && (
                        <>
                          <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                          <span style={{ marginLeft: "0.3rem" }}>{t('user name')}</span>
                        </>)}
                    </div>
                  </div>
                  <div style={{ marginBottom: "6vh" }}>
                    <Input inputMode="none" className="cp-input-field" type={"password"} ref={keyboardRef2} onFocus={() => setKeyboard(2)} onClick={onClickPassword} value={password} onChange={handlePassword} placeholder="Password" />
                    <div style={{ position: "absolute", top: "24%", fontSize: "1.1rem", left: "30%", backgroundColor: "white", padding: "0 2%", color: "#A0A8B2" }}>
                      Password
                      {multilingual === "true" && (
                        <>
                          <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                          <span style={{ marginLeft: "0.3rem" }}>{t('password')}</span>
                        </>)}
                    </div>
                  </div>
                  <div style={{ height: "1vh", fontSize: "2.5vh", color: userNameError.flag === false ? "#fff" : "red", position: "relative", top: "-6%" }}>{userNameError.flag === false ? "" : userNameError.msg}</div>
                  <Button className="cp-continue-btn" onClick={handleLogin} >CONTINUE
                    {multilingual === "true" && (
                      <>
                        <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                        <span style={{ marginLeft: "0.3rem" }}>{t('continue')}</span>
                      </>)}
                  </Button>
                  <div style={{ width: "100%", position: "relative" }}>
                    <Keyboard
                      buttonTheme={[
                        {
                          class: "hg-yellow",
                          buttons: "{space}"
                        },
                        {
                          class: "hg-space",
                          buttons: "{space}"
                        },
                        {
                          class: "hg-mail",
                          buttons: "@yahoo.com @gmail.com"
                        },
                      ]}
                      layoutName={layoutUserName}
                      inputName={keyboard === 1 ? "un" : "psd"}
                      onChange={(val) => { keyboard === 1 ? setUsername(val) : setPassword(val) }}
                      onKeyPress={onKeyPress}
                      theme={"hg-theme-default login"}
                      display={{
                        "{abc}": "ABC",
                        "{smileys}": "\uD83D\uDE03",
                        "{back}": "Enter",
                        "{numbers}": "123",
                        "{number}": "123",
                        '{bksp}': 'backspace',
                        "{backspace}": "⌫",
                        "{shift}": "⇧",
                        '{tab}': 'tab',
                        '{lock}': 'lock',
                        '{enter}': 'enter',
                        '{space}': "Space",
                        "{mic}": "🎙️",
                        "{caps}": "ABC",
                        "{small}": "abc"
                      }}
                      layout={{
                        shift: [
                          "q w e r t y u i o p",
                          "a s d f g h j k l",
                          "{shift} z x c v b n m {backspace}",
                          "{number} {space} @yahoo.com @gmail.com"
                        ],
                        numbers: [
                          "1 2 3 4 5 6 7 8 9 0",
                          '- / : ; ( ) $ & @ "',
                          '# . , _ ! ` {backspace}',
                          "{caps} {space} {back}"
                        ],
                        number: [
                          "1 2 3 4 5 6 7 8 9 0",
                          '- / : ; ( ) $ & @ "',
                          '# . , _ ! ` {backspace}',
                          "{small} {space} {back}"
                        ],
                        default: [
                          "Q W E R T Y U I O P",
                          "A S D F G H J K L",
                          "{shift} Z X C V B N M {backspace}",
                          "{numbers} {space} @yahoo.com @gmail.com"
                        ],
                      }}
                    />
                  </div>
                </Col>
                <Col span={1} />
              </Row>
            </div>
            :
            <div>
              <Row>
                <Col span={1} />
                <Col span={22} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div style={{ position: "relative", top: "8%" }}>
                    <Input inputMode="none" className="cp-tId-field" autoFocus={true} value={transactionId} onChange={handleTransactionId} onPressEnter={handleVerify} onFocus={() => { setKeyboard(3) }} onClick={onClickTransactionId} />
                    <div className="cp-tId-lable">Transaction ID</div>
                    <div style={{ left: "24%", height: "1vh", fontSize: "2.5vh", color: tIdError.flag === false ? "#fff" : "red", position: "absolute", top: "120%" }}>{tIdError.flag === false ? "" : tIdError.msg}</div>
                  </div>
                  <Button className="cp-final-continue-btn" onClick={handleVerify} >CONTINUE
                    {multilingual === "true" && (
                      <>
                        <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                        <span style={{ marginLeft: "0.3rem" }}>{t('continue')}</span>
                      </>)}
                  </Button>
                  <div style={{ width: "100%", position: "relative" }}>
                    <Keyboard
                      buttonTheme={[
                        {
                          class: "hg-yellow",
                          buttons: "{space}"
                        },
                        {
                          class: "hg-space",
                          buttons: "{space}"
                        },
                        {
                          class: "hg-mail",
                          buttons: "@yahoo.com @gmail.com"
                        },
                      ]}
                      layoutName={layoutUserName}
                      onChange={(val) => { setTransactionId(val) }}
                      onKeyPress={onKeyPressNumber}
                      theme={"hg-theme-default login"}
                      display={{
                        "{abc}": "ABC",
                        "{smileys}": "\uD83D\uDE03",
                        "{back}": "Enter",
                        "{numbers}": "123",
                        "{number}": "123",
                        '{bksp}': 'backspace',
                        "{backspace}": "⌫",
                        "{shift}": "⇧",
                        '{tab}': 'tab',
                        '{lock}': 'lock',
                        '{enter}': 'enter',
                        '{space}': "Space",
                        "{caps}": "ABC",
                        "{small}": "abc"
                      }}
                      layout={{
                        shift: [
                          "q w e r t y u i o p",
                          "a s d f g h j k l",
                          "{shift} z x c v b n m {backspace}",
                          "{number} {space} @yahoo.com @gmail.com"
                        ],
                        numbers: [
                          "1 2 3 4 5 6 7 8 9 0",
                          '- / : ; ( ) $ & @ "',
                          '# . , _ ! ` {backspace}',
                          "{caps} {space} {back}"
                        ],
                        number: [
                          "1 2 3 4 5 6 7 8 9 0",
                          '- / : ; ( ) $ & @ "',
                          '# . , _ ! ` {backspace}',
                          "{small} {space} {back}"
                        ],
                        default: [
                          "Q W E R T Y U I O P",
                          "A S D F G H J K L",
                          "{shift} Z X C V B N M {backspace}",
                          "{numbers} {space} @yahoo.com @gmail.com"
                        ],
                      }}
                    />
                  </div>
                </Col>
                <Col span={1} />
              </Row>
            </div>
        }
        <Modal okText="Yes" cancelText="No" open={isModalOpen} onOk={() => cancelPayment()} onCancel={() => setIsModalOpen(false)}>
          <p style={{ fontWeight: 600, fontSize: "3vh", paddingTop: "1em" }}>Are you sure you want to cancel the transaction ?</p>
        </Modal>
        <Modal centered open={backFlag} closable={false} footer={null} width={300} style={{ justifyContent: "center", textAlign: "center", alignItems: "center" }}>
          <p style={{ fontSize: "15px", textAlign: "center", color: "red" }}>Qr code not Generated</p>
          <Button style={{ borderRadius: "4px" }} onClick={() => { navigate("/payment-selection") }}>Cancel</Button>
        </Modal>
      </Spin>
    </div>
  );
};

export default QrPayment;
