import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "../../antd.css";
import "../styles.css";
import LoadingGif from "../../assets/SCO_asset/products/Master & Incremental Refresh.gif";
import Logo1 from "../../assets/SCO_asset/home/decathlon-logo.png";
import db from "../../database";
import Axios from "axios";
import moment from "moment";
import URL from "../../app/server.json";
import IncrementalRefreshLoading from "../../assets/SCO_asset/products/IncrementalRefreshLoading.gif";
import suggestedData from "../home/suggestedItems.json";
import CryptoJS from 'crypto-js';

const Loading = () => {
  const navigate = useNavigate()
  const [loadedMaster, setLoadedMaster] = useState(0);
  const [totalProductsCount, setTotalProductsCount] = useState(0);
  const [isComboFlag, setIsComboFlag] = useState(false);
  const [isMultiFlag, setIsMultiFlag] = useState(false);
  const [isConfiguration, setIsConfiguration] = useState(false);
  let masterRefresh = localStorage.getItem("isMasterRefresh");
  let incrementalRefresh = localStorage.getItem("isIncremenatalRefresh");
  let index = 0;
  let cashier = JSON.parse(localStorage.getItem('cashierData'));
  cashier.Username = CryptoJS.AES.decrypt(cashier.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
  cashier.Password = CryptoJS.AES.decrypt(cashier.Password, 'secret-key').toString(CryptoJS.enc.Utf8);
  let basicAuth = `${cashier.Username}:${cashier.Password}`;
  let token = localStorage.getItem("token");
  const apiKey = URL.GRAVITEE_API_KEY;
  const config = {
    headers: {},
  };
  config.headers[URL.key_NAME] = apiKey;

  useEffect(()=>{
    let headers = {
       "Content-Type": "application/json",
     }
     headers[URL.key_NAME] = apiKey;
     Axios({
       url: `${URL.GRAVITEE_BASE_URL}/NSatReasons`,
       method: "GET",
       headers: headers,
     }).then(NSatReasonsResponse => {
       if (NSatReasonsResponse.status === 200) {
        localStorage.setItem("NSatReasons",JSON.stringify(NSatReasonsResponse.data))
        Axios({
          url: `${URL.GRAVITEE_BASE_URL}/NSatOthers`,
          method: "GET",
          headers: headers,
        }).then(NSatOthersResponse => {
          if (NSatOthersResponse.status === 200) {
           localStorage.setItem("NSatOthers",JSON.stringify(NSatOthersResponse.data))
          }})
       }})
       
  },[])

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  useEffect(() => {
    if (index === 0) {
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      selectedProducts = selectedProducts === null ? {} : selectedProducts;
      index++;
      const processSync = async () => {
        try {
          if (incrementalRefresh === "true") {
            if (localStorage.getItem("reloadkey") === null) {
              localStorage.setItem("reloadkey", true);
              window.location.reload();
            } else {
              localStorage.removeItem("reloadkey");
              await tillData();
            }
          } else {
            await deletePreviousData();
            await tillData();
          };
        } catch (error) {
        }
      };

      const deletePreviousData = () => {
        return new Promise(async (deletionSuccess, deletionFailure) => {
          try {
            await db.productData.clear();
            deletionSuccess();
          } catch (error) {
            deletionFailure(error);
          }
        });
      };

      const tillData = async () => {
        await db.tillData.clear();
        console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "tillstart");
        localStorage.setItem("startTime", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
        setIsConfiguration(true);
        let partitionKey = localStorage.getItem("terminalNumber");
        Axios.get(`${URL.GRAVITEE_BASE_URL}/tillData?partitionKey=${partitionKey}`, config)
          .then(async response => {
            if (response.status === 200) {
              if (response.data.length > 0) {
                const data = response.data;
                await db.tillData.bulkAdd(data);
                selectedProducts.tillData = data;
                localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
                console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "tillend");
                await getComboData();
              }
            };
          });
      };

      const getComboData = async () => {
        await db.comboData.clear();
        console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "combostart");
        setIsConfiguration(false)
        setIsComboFlag(true);
        Axios.get(`${URL.GRAVITEE_BASE_URL}/comboData`, config)
          .then(async response => {
            if (response.status === 200) {
              const data = response.data;
              await db.comboData.bulkAdd(data);
              console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "comboend");
              await getMultiBuyData();
            };
          });
      };

      const getMultiBuyData = async () => {
        await db.multiBuyData.clear();
        console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "multistart");
        setIsComboFlag(false)
        setIsMultiFlag(true)
        Axios.get(`${URL.GRAVITEE_BASE_URL}/multibuyData`, config)
          .then(async response => {
            if (response.status === 200) {
              const data = [];
                let organization = JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.organization;
                response.data.map((item) => {
                  const inputString = item?.Organization;
                  const regex = /Organization\(([\w\s,]+)\)/;
                  const matches = inputString.match(regex);
                  let organizationData = matches[1].split(",")
                  if(organizationData.length>1){
                    const regex = /([A-Fa-f0-9]{32})/g;
                    const matches = Array.from(inputString.matchAll(regex), match => match[0]);
                    console.log(matches);
                  let index =  matches.findIndex((item)=> (item === organization))
                  if(index >=0){
                    data.push(item);
                  }
                  }else {
                    let flag = matches[1] === "0" ? true : matches[1] === organization ? true :false;
                    if(flag === true){
                      data.push(item);
                    }
                  }
                })
              await db.multiBuyData.bulkAdd(data);
              if (incrementalRefresh === "true") {
                console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "multiend");
                await incrementalProducts();
              } else {
                console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "multiend");
                await getLastAssignedNumber();
              };
            };
          });
      };

      const getLastAssignedNumber = async () => {
        console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "numberstart");
        let partitionKey = localStorage.getItem("terminalNumber");
        let headers = {
          "Content-Type": "application/json",
          "token": `Basic ${window.btoa(basicAuth)},${token}`,
        }
        headers[URL.key_NAME] = apiKey;
        Axios.get(`${URL.GRAVITEE_BASE_URL}/lastAssignedNumber?partitionKey=${partitionKey}`, {
          headers: headers
        })
          .then(async response => {
            if (response.status === 200) {
              const data = response.data.data;
              localStorage.setItem("order_lastassignednum", data.lastassignednum);
              console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "numberend");
              await getTotalProducts();
            };
          });
      };

      const incrementalProducts = async () => {
        let productsloadstarttime = localStorage.getItem("productsloadstarttime");
        localStorage.setItem("productsloadstarttime", moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"));
        console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "incrementalstart");
        let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
        let partitionKey = JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.organization;
        Axios.get(`${URL.GRAVITEE_BASE_URL}/incrementalProducts?partitionKey=${partitionKey}&lastUpdatedTime=${productsloadstarttime}`, config)
          .then(async response => {
            if (response.status === 200) {
              const data = response?.data?.data;
              if (data?.length > 0) {
                await Promise.all(data.map(async (item) => {
                  await db.productData
                      .where("id")
                      .equals(item.id)
                      .toArray()
                      .then(async (response) => {
                          if (response.length > 0) {
                              await db.productData.update(item.id, item);
                          } else {
                              await db.productData.add(item);
                          }
                      });
              }));
                let dbData = await db.productData.toArray();
                const newArray1 = [], newArray2 = [], newArray3 = [];
                let hashTable1 = {}, hashTable2 = {}, hashTable3 = {};
                for (let i = 0; i < suggestedData.NonRfid.length; i++) {
                  let currentItem = suggestedData.NonRfid[i];
                  hashTable1[currentItem.id] = currentItem;
                };
                for (let i = 0; i < suggestedData.Cotton.length; i++) {
                  let currentItem = suggestedData.Cotton[i];
                  hashTable2[currentItem.id] = currentItem;
                };
                for (let i = 0; i < suggestedData.Paper.length; i++) {
                  let currentItem = suggestedData.Paper[i];
                  hashTable3[currentItem.id] = currentItem;
                };
                for (let i = 0; i < dbData.length; i++) {
                  let currentItem = dbData[i];
                  if (hashTable1.hasOwnProperty(currentItem.id)) {
                    let updatedItem = { ...hashTable1[currentItem.id], sale_price: currentItem.sale_price, name: currentItem.name };
                    let index = newArray1.findIndex(item => item.id === currentItem.id);
                    if (index !== -1) {
                      newArray1[index] = updatedItem;
                    } else {
                      newArray1.push(updatedItem);
                    };
                  };
                  if (hashTable2.hasOwnProperty(currentItem.id)) {
                    let updatedItem = { ...hashTable2[currentItem.id], sale_price: currentItem.sale_price };
                    let index = newArray2.findIndex(item => item.id === currentItem.id);
                    if (index !== -1) {
                      newArray2[index] = updatedItem;
                    } else {
                      newArray2.push(updatedItem);
                    };
                  };
                  if (hashTable3.hasOwnProperty(currentItem.id)) {
                    let updatedItem = { ...hashTable3[currentItem.id], sale_price: currentItem.sale_price };
                    let index = newArray3.findIndex(item => item.id === currentItem.id);
                    if (index !== -1) {
                      newArray3[index] = updatedItem;
                    } else {
                      newArray3.push(updatedItem);
                    };
                  };
                };
                const reorderedArray = [];
              const remainingObjects = [];

              suggestedData.NonRfid.forEach(suggestedItem => {
                const foundObject = newArray1.find(newArrayItem => newArrayItem.id === suggestedItem.id);
                if (foundObject) {
                  reorderedArray.push(foundObject);
                }
              });
              newArray1.forEach(newArrayItem => {
                if (!reorderedArray.some(item => item.id === newArrayItem.id)) {
                  remainingObjects.push(newArrayItem);
                }
              });
              const finalArray = [...reorderedArray, ...remainingObjects];
                await db.nonRfidData.clear();
                await db.cottonData.clear();
                await db.paperData.clear();
                await db.nonRfidData.add(finalArray);
                await db.cottonData.add(newArray2);
                await db.paperData.add(newArray3);
                console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "incrementalend");
              };
              if (incrementalRefresh === "true") {
                localStorage.setItem("isIncremenatalRefresh", false);
                navigate("/admin");
              } else {
                await tillData();
              };
            };
          });
      };

      const getTotalProducts = async () => {
        console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "totalstart");
        setIsMultiFlag(false);
        let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
        let partitionKey = JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.organization;
        Axios.get(`${URL.GRAVITEE_BASE_URL}/productsCount?partitionKey=${partitionKey}`, config)
          .then(async response => {
            if (response.status === 200) {
              setTotalProductsCount(response.data.data.count);
              console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "totalend");
              await getProducts();
            };
          });
      };

      const getProducts = async () => {
        localStorage.setItem("productsloadstarttime", moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"));
        console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "productsstart");
        let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
        let partitionKey = JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.organization;
        const startKey = 0;
        const items = [];
        async function fetchItems(startKey, itemsArray = []) {
          try {
            const response = await fetch(`${URL.GRAVITEE_BASE_URL}/products?partitionKey=${partitionKey}&startKey=${startKey}`, config);
            if (!response.ok) {
              throw new Error('Error fetching items');
            }
            const responseData = await response.json();
            setLoadedMaster(responseData.data.length + itemsArray.length);
            itemsArray.push(...responseData.data);
            const nextKey = responseData.nextKey;
            if (nextKey) {
              await fetchItems(nextKey, itemsArray);
            } else {
              const data = [...itemsArray];
              console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "productsend");
              await db.productData.bulkPut(data);
              const newArray1 = [], newArray2 = [], newArray3 = [];
              let hashTable1 = {}, hashTable2 = {}, hashTable3 = {};
              for (let i = 0; i < suggestedData.NonRfid.length; i++) {
                let currentItem = suggestedData.NonRfid[i];
                hashTable1[currentItem.id] = currentItem;
              };
              for (let i = 0; i < suggestedData.Cotton.length; i++) {
                let currentItem = suggestedData.Cotton[i];
                hashTable2[currentItem.id] = currentItem;
              };
              for (let i = 0; i < suggestedData.Paper.length; i++) {
                let currentItem = suggestedData.Paper[i];
                hashTable3[currentItem.id] = currentItem;
              };
              for (let i = 0; i < data.length; i++) {
                let currentItem = data[i];
                if (hashTable1.hasOwnProperty(currentItem.id)) {
                  let updatedItem = { ...hashTable1[currentItem.id], sale_price: currentItem.sale_price, name: currentItem.name };
                  let index = newArray1.findIndex(item => item.id === currentItem.id);
                  if (index !== -1) {
                    newArray1[index] = updatedItem;
                  } else {
                    newArray1.push(updatedItem);
                  };
                };
                if (hashTable2.hasOwnProperty(currentItem.id)) {
                  let updatedItem = { ...hashTable2[currentItem.id], sale_price: currentItem.sale_price };
                  let index = newArray2.findIndex(item => item.id === currentItem.id);
                  if (index !== -1) {
                    newArray2[index] = updatedItem;
                  } else {
                    newArray2.push(updatedItem);
                  };
                };
                if (hashTable3.hasOwnProperty(currentItem.id)) {
                  let updatedItem = { ...hashTable3[currentItem.id], sale_price: currentItem.sale_price };
                  let index = newArray3.findIndex(item => item.id === currentItem.id);
                  if (index !== -1) {
                    newArray3[index] = updatedItem;
                  } else {
                    newArray3.push(updatedItem);
                  };
                };
              };
              await db.nonRfidData.clear();
              await db.cottonData.clear();
              await db.paperData.clear();
              await db.nonRfidData.add(newArray1);
              await db.cottonData.add(newArray2);
              await db.paperData.add(newArray3);
              let body = {
                organization: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.organization,
                posterminal: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.touchpointtype,
                storeName: JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.storeName,
                terminalNumber: localStorage.getItem("terminalNumber"),
                lastLoadedTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"),
                loaded: "Yes"
              };
              let validateResponse = await Axios.post(
                `${URL.GRAVITEE_BASE_URL}/validateMasterRefresh`,
                body,
                config
              );
              if (validateResponse.data.statusCode === 200) {
                console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "productsend");
                await getToken();
              };
            };
          } catch (error) {
            console.error('Error fetching items', error);
          }
        };
        fetchItems(startKey, items);
      };

      const getToken = () => {
        console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "tokenstart");
        return new Promise(async (tokenDataSuccess, tokenDataFailure) => {
          try {
            localStorage.setItem("isMasterRefresh", false);
            tokenDataSuccess(token);
            if (masterRefresh === "true") {
              navigate("/admin")
            }
            else {
              localStorage.setItem("endTime", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
              console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "tokenend");
              navigate("/customer-login");
            }
          } catch (error) {
            tokenDataFailure(error);
          }
        });
      };
      processSync();
    }
  }, []);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="header-block">
        <Row>
          <Col span={3}>
            <img src={Logo1} alt="" style={{ height: "50px" }} />
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: "50px" }}>
        <Row justify="middle">
          <Col span={1} />
          <Col span={22} style={{ height: "72vh", width: "100%", background: "rgba(231, 246, 245, 1)", borderRadius: "15px" }}>
            <Row style={{ marginTop: "11vh" }}>
              <Col span={24} style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: 'center' }}>
                <img src={incrementalRefresh === "false" ? LoadingGif : IncrementalRefreshLoading} style={{ height: (incrementalRefresh === "false") ? "40vh" : "40vh", width: (incrementalRefresh === "false") ? "40vw" : "30vw" }} />
                {incrementalRefresh === "false" ?
                  isConfiguration ?
                    <>
                      <p style={{ fontWeight: "600", fontSize: "20px", textAlign: "center" }}> </p>
                      <p style={{ fontWeight: "600", lineHeight: "38px", fontSize: "32px", textAlign: "center" }}>Configurations refresh is in progress!</p>
                    </>
                    :
                    isComboFlag ?
                      <>
                        <p style={{ fontWeight: "600", lineHeight: "38px", fontSize: "32px", textAlign: "center" }}>Combo refresh is in progress!</p>
                      </>
                      :
                      isMultiFlag ?
                        <>
                          <p style={{ fontWeight: "600", lineHeight: "38px", fontSize: "32px", textAlign: "center" }}>Multibuy refresh is in progress!</p>
                        </>
                        :
                        <>
                          {totalProductsCount !== 0 ? <p style={{ fontWeight: "600", fontSize: "20px", textAlign: "center" }}>{loadedMaster} products loaded from {totalProductsCount}</p> : ""}
                          <p style={{ fontWeight: "600", lineHeight: "38px", fontSize: "32px", textAlign: "center" }}>Master refresh is in progress!</p>
                        </>
                  :
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <p className="loading-statement" style={{ marginBottom: "0.5rem", fontWeight: "600", lineHeight: "38px", fontSize: "32px", textAlign: "center" }}>Incremental refresh is in progress!</p>
                    <div style={{ display: "grid" }}>
                    </div>
                  </div>
                }
              </Col>
            </Row>
          </Col>
          <Col span={1} />
        </Row>
      </div>
    </div>
  )
}
export default Loading;