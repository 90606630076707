import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Spin } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "../../antd.css";
import "../styles.css";
import Axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import URL from "../../app/server.json";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import backgroungImage from "../../assets/SCO_asset/signup/backgroundImage.png";
import './signup.css';
import CryptoJS from 'crypto-js';

const Signup = () => {
  let keyboardRef = useRef([]);
  keyboardRef = { current: ["", ""] }
  const keyboardRef1 = useRef(null);
  const keyboardRef2 = useRef(null);
  const keyboardRef3 = useRef(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [terminalNumber, setTerminalNumber] = useState("");
  const [keyboard, setKeyboard] = useState(1);
  const [layout, setlayout] = useState("default");
  const [userNameError, setUserNameError] = useState({ flag: false, msg: "" });
  const [cursorPosition, setCursorPosition] = useState(null);
  const [isShiftDoubleClick, setIsShiftDoubleClick] = useState(false);
  const [shiftDoubleClick, setShiftDoubleClick] = useState(false);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  useEffect(() => {
    (async () => {
      localStorage.setItem("ebill", true);
      localStorage.setItem("paperBag", true);
      localStorage.setItem("cottonBag", true);
      const sessionId = localStorage.getItem("sessionId");
      if (sessionId !== "" && typeof (sessionId) === "string") {
        navigate("/");
      } else {
        setTimeout(() => {
          keyboardRef1.current.focus();
        }, 100);
      };
    })();
  }, []);

  useEffect(() => {

    if (keyboard === 3) {
      setlayout("numbers")
    }
  }, [keyboard])

  const handleHome = () => {
    if (terminalNumber !== "" && userName !== "" && password !== "") {
      setLoading({ status: true, message: "" });
      const apiKey = URL.GRAVITEE_API_KEY;
      const encryptedUsername = CryptoJS.AES.encrypt(userName, 'secret-key').toString();
      const encryptedPassword = CryptoJS.AES.encrypt(password, 'secret-key').toString();
      let headers = {
        "Content-Type": "application/json",
      }
      headers[URL.key_NAME] = apiKey;
      Axios({
        url: `${URL.GRAVITEE_BASE_URL}/signup`,
        method: "GET",
        headers: headers,
        params: {
          remainingUrl: `/ws/com.promantia.decathlon.custom_selfcheckout.logincheck?l=userName&p=password&terminalname=${terminalNumber}&sessionId=`,
          "username": encryptedUsername,
          "password": encryptedPassword  
        }
      }).then(async (response) => {
        if (response.data.data.status !== "FAILURE") {
          localStorage.setItem("sessionId", response.data.data.sessionId)
          localStorage.setItem("terminalNumber", terminalNumber)
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("isMasterRefresh", false);
          localStorage.setItem("isIncremenatalRefresh", false);
          localStorage.setItem("cashierData", JSON.stringify(response.data.data));
          navigate("/loading");
        } else {
          setLoading({ status: false, message: "" });
          setUserNameError({ flag: true, msg: "Duplicate terminal" });
          return null;
        }
      }).catch((errorMessage) => {
        setLoading({ status: false, message: "" });
        if (errorMessage.response.status === 401) {
          setUserNameError({ flag: true, msg: "Invalid Credentials" });
        }
      })
    } else {
      const errors = [];
      if (terminalNumber === "") {
        errors.push("Terminal number is required.");
      }
      if (userName === "") {
        errors.push("Username is required.");
      }
      if (password === "") {
        errors.push("Password is required.");
      }
      setUserNameError({ flag: true, msg: errors });
    }
  }

  const onClickUserName = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(1)
    setShiftDoubleClick(false)
  }

  const onClickPassword = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(2)
    setShiftDoubleClick(false)
  }

  const onClickTerminal = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(3)
    setShiftDoubleClick(false)
  }

  const onKeyPress = (button) => {
    setUserNameError({ flag: false, msg: "" });
    if (keyboard === 1) {
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setUserName(userName.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setUserName(userName)
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" & button !== "{tab}" && button !== "{enter}" & button !== "{space}") {
        if (!shiftDoubleClick) {
          if (layout === "default") setlayout("shift");

        }
        let text = `${userName}${button}`
        setUserName(text.trim());
      }
    } else if (keyboard === 2) {
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setPassword(password.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setPassword(password)
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" & button !== "{tab}" && button !== "{enter}" & button !== "{space}" & button !== "{numbers}") {
        if (!shiftDoubleClick) {
          if (layout === "default") setlayout("shift");

        }
        let text = `${password}${button}`
        setPassword(text.trim());
      }
    } else if (keyboard === 3) {
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setTerminalNumber(terminalNumber.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setTerminalNumber(terminalNumber)
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" & button !== "{tab}" && button !== "{enter}" & button !== "{space}" & button !== "{numbers}") {
        let text = `${terminalNumber}${button}`
        setTerminalNumber(text.trim());
      }
    }
    if (button === "{numbers}" || button === "{caps}") handleNumbers();
    if (button === "{number}" || button === "{small}") handleNumber();
    if (button === "{back}") handleReturn();
    if (button === "{space}") handleSpace();
    if (button === "{mic}") handleMic();
  };

  const handleMic = () => {
    setUserName(userName)
    setPassword(password)
    setTerminalNumber(terminalNumber)
    setlayout(layout)
  }

  const handleSpace = () => {
    if (keyboard === 1) {
      setUserName(userName + ' ');
    } else if (keyboard === 2) {
      setPassword(password + ' ');
    }
    setTerminalNumber(terminalNumber + ' ');
  }

  const handleReturn = () => {
    if (keyboard === 1) {
      keyboardRef2.current.focus();
      setKeyboard(2)
    }
    else if (keyboard === 2) {
      keyboardRef3.current.focus();
      setKeyboard(3)
    }
    else if (keyboard === 3) {
      keyboardRef1.current.focus();
      setKeyboard(1)
    }
    setUserName(userName);
    setPassword(password);
    setTerminalNumber(terminalNumber);
  }

  const handleNumbers = () => {
    const layout1 = layout;
    keyboard !== 3 ? setlayout(layout1 === "default" ? "numbers" : "default") : setlayout(layout)
    setUserName(userName);
    setPassword(password);
    setTerminalNumber(terminalNumber);
  }

  const handleNumber = () => {
    const layout1 = layout;
    setlayout(layout1 === "shift" ? "number" : "shift");
    setUserName(userName);
    setPassword(password);
    setTerminalNumber(terminalNumber);
  }

  const handleShift = () => {
    const layout1 = layout;
    setlayout(layout1 === "default" ? "shift" : "default");
    setUserName(userName);
    setPassword(password);
    setTerminalNumber(terminalNumber);
    if (isShiftDoubleClick) {
      setlayout('default')
      setShiftDoubleClick(true)
    } else {
      setIsShiftDoubleClick(true);

      setTimeout(() => {
        setIsShiftDoubleClick(false);
      }, 500);
    }
  };

  const handleUserName = (ev) => {
    setUserName(ev.target.value);
  };

  const handlePassword = (ev) => {
    setPassword(ev.target.value);
  };

  const handleTerminal = (ev) => {
    setTerminalNumber(ev.target.value);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px", position: "fixed", top: "50%", left: "50%", }} spin />;

  const sectionStyle = {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${backgroungImage})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "fixed",
  }

  return (
    <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
      <div style={sectionStyle}>
        <Row >
          <Col span={24}
          >
            <div style={{ height: "35px" }}>
            </div>
          </Col>
          <Col span={3}></Col>
          <Col span={18}>
            <p className="signup-main-heading">WELCOME TO DECATHLON </p>
            <p className="signup-sub-heading">Please create your account with us to proceed with your order</p>
          </Col>
          <Col span={3}></Col>
        </Row>
        <Row justify="space-around" style={{ height: "60vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Col span={11} className="signup-form-col">
            <Form layout="vertical" className="signup-form">
              <Form.Item >
                <Row style={{ padding: "0 0 0 0.2vw", marginBottom: "-19px" }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: "0 0 20px", height: "3px", backgroundColor: "#fff" }} />
                      <div style={{ margin: "0 10px", fontSize: "22px", color: "#fff" }}>
                        User Name                      </div>
                      <div style={{ flex: "1", height: "3px", backgroundColor: "#fff" }} />
                    </div>
                  </Col>
                </Row>
                <input inputMode="none" className="input-field" ref={keyboardRef1} value={userName} onChange={handleUserName} onFocus={() => { setKeyboard(1) }} onClick={onClickUserName} />
              </Form.Item>
              <Form.Item >
                <Row style={{ padding: "0 0 0 0.2vw", marginBottom: "-19px" }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: "0 0 20px", height: "3px", backgroundColor: "#fff" }} />
                      <div style={{ margin: "0 10px", fontSize: "22px", color: "#fff" }}>
                        Password                      </div>
                      <div style={{ flex: "1", height: "3px", backgroundColor: "#fff" }} />
                    </div>
                  </Col>
                </Row>
                <input inputMode="none" className="input-field" type={"password"} ref={keyboardRef2} value={password} onChange={handlePassword} onFocus={() => { setKeyboard(2) }} onClick={onClickPassword} />
              </Form.Item>
              <Form.Item >
                <Row style={{ padding: "0 0 0 0.2vw", marginBottom: "-19px" }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: "0 0 20px", height: "3px", backgroundColor: "#fff" }} />
                      <div style={{ margin: "0 10px", fontSize: "22px", color: "#fff" }}>
                        Terminal Number                      </div>
                      <div style={{ flex: "1", height: "3px", backgroundColor: "#fff" }} />
                    </div>
                  </Col>
                </Row>
                <input inputMode="none" className="input-field" ref={keyboardRef3} onPressEnter={handleHome} value={terminalNumber} onChange={handleTerminal} onFocus={() => { setKeyboard(3) }} onClick={onClickTerminal} />
              </Form.Item>
              <div style={{ height: "1vh", fontSize: "2.5vh", color: userNameError.flag === false ? "#fff" : "red", }}>{userNameError.flag === false ? "" : userNameError.msg}</div>
            </Form>
          </Col>
          <Col span={12} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div className="signup-kb-div">
              <Keyboard
                buttonTheme={[
                  {
                    class: "hg-yellow",
                    buttons: "{space}"
                  },
                  {
                    class: "hg-mail",
                    buttons: "@yahoo.com @gmail.com"
                  },
                ]}
                layoutName={layout}
                inputName={keyboard === 1 ? "un" : keyboard === 2 ? "psd" : "terminal"}
                onChange={(val) => { keyboard === 1 ? setUserName(val) : keyboard === 2 ? setPassword(val) : setTerminalNumber(val) }}
                onKeyPress={onKeyPress}
                theme={"hg-theme-default signUp"}
                display={{
                  "{abc}": "ABC",
                  "{smileys}": "\uD83D\uDE03",
                  "{back}": "Enter",
                  "{numbers}": "123",
                  "{number}": "123",
                  '{bksp}': 'backspace',
                  "{backspace}": "⌫",
                  "{shift}": "⇧",
                  '{tab}': 'tab',
                  '{lock}': 'lock',
                  '{enter}': 'enter',
                  '{space}': "Space",
                  "{caps}": "ABC",
                  "{small}": "abc"
                }}
                layout={{
                  shift: [
                    "q w e r t y u i o p",
                    "a s d f g h j k l",
                    "{shift} z x c v b n m {backspace}",
                    "{number} {space} @yahoo.com @gmail.com"
                  ],
                  numbers: [
                    "1 2 3 4 5 6 7 8 9 0",
                    '- / : ; ( ) $ & @ "',
                    '# . , _ ! ` {backspace}',
                    "{caps} {space} {back}"
                  ],
                  number: [
                    "1 2 3 4 5 6 7 8 9 0",
                    '- / : ; ( ) $ & @ "',
                    '# . , _ ! ` {backspace}',
                    "{small} {space} {back}"
                  ],
                  default: [
                    "Q W E R T Y U I O P",
                    "A S D F G H J K L",
                    "{shift} Z X C V B N M {backspace}",
                    "{numbers} {space} @yahoo.com @gmail.com"
                  ],
                }}
              />
            </div>
            <Button className="btn-continue" onClick={handleHome} htmlType="submit">CONTINUE</Button>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default Signup;
