import React, { useState } from "react";
import { Row, Col } from "antd";
import "antd/dist/antd.css";
import "../../antd.css";
import { useNavigate } from "react-router-dom";


const NsatOthers = (props) => {
    const navigate = useNavigate();
    const othersReasons = localStorage.getItem("NSatOthers")

    const onClickReason = (selectedReason) => {
        props.setNSatReason(selectedReason)
        // navigate('/thankyou');
    }
    return (

        <Row gutter={[0, 16]} justify="center" style={{ marginTop: "3vh" }}>
        {JSON.parse(othersReasons).map((list, index) => (
            <Col onClick={() => { onClickReason(list.othersReason) }} key={index} style={{ background: '#FFFFFF', borderRadius: '10px', padding: '1em', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: "16vw", margin: '0 8px', border: "1px solid rgba(84, 84, 84, 0.25)", height: "150px" }}>
                <span style={{ fontFamily: 'Roboto Condensed', fontWeight: 500, fontSize: '17px' }}>{list.othersReason}</span>
            </Col>
        ))}

        {/* Calculate the number of empty columns needed */}
        {Array.from({ length: 5 - (JSON.parse(othersReasons).length % 5 || 5) }, (_, i) => (
            <Col key={`empty-${i}`} style={{ width: "16vw", margin: '0 8px', height: "150px" }} />
        ))}
    </Row>

    );

}
export default NsatOthers;