import "./cashup.css";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import { Row, Col } from "antd";
import logOut from "../../assets/SCO_asset/admiPageGif/exit.png";
import { Scrollbars } from "react-custom-scrollbars";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';

const CashupPrint = ({ cashupDetails, tillData, istempPrint, showModal, getCashupDetails, getPrint }) => {
  let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
  let storeName= JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.storeName
 
  const navigate = useNavigate();
  let terminalNumber = localStorage.getItem("terminalNumber");

  return (
    <>
      {
        istempPrint ? <div className="table-body">
          <div style={{ height: "30vh", width: "100%" }}>
            <Row align='middle' style={{ backgroundColor: "#0072AF", padding: "5px 50px 5px 50px", height: "10vh" }}>
              <Col span={6}>
                <img src={Logo} alt="Logo" style={{ height: "50px" }} />
              </Col>
              <Col offset={14} span={4} >
                <div style={{ display: "flex", float: "right" }}>
                  <img src={logOut} onClick={() => { navigate("/customer-login") }} />
                </div>
              </Col>
            </Row>
            <div style={{ backgroundColor: "#0079BA", padding: "5px 50px 5px 50px", height: "20vh", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div className="table-btn" onClick={() => { navigate("/customer-login") }}>Home</div>
              <div className="table-btn" onClick={getCashupDetails}>Cashup details</div>
              <div className="table-btn" onClick={getPrint}>Post print and close</div>
            </div>
          </div>
          <Scrollbars style={{ height: "70vh" }}>
            <div class="table-container">
              <div id="pdfTable">
                <div class="table-header-container" style={istempPrint ? { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" } : { marginBottom: "1  5vh" }}>
                  <Row style={{ width: "100%" }}>
                    <Col span={8} style={{ textAlign: "left" }}>
                      <h3 style={{ color: "black" }}>Terminal Number:  {terminalNumber}</h3>
                      <h3 style={{ color: "black" }}>Store Name:  {storeName}</h3>
                    </Col>
                    <Col span={8} style={{ textAlign: "center", fontSize: "3.5vh", fontWeight: "600" }}>
                      Cashup Report
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }}>
                      <h3 style={{ color: "black" }}>User:  {CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem("cashierData")).Username, 'secret-key').toString(CryptoJS.enc.Utf8)}</h3>
                    </Col>
                  </Row>
                </div>
                <table>
                  <tr>
                    <td>Net-Sales</td>
                    <td>{cashupDetails[0]?.netSales}</td>
                  </tr>
                  {cashupDetails[0]?.cashTaxInfo?.map((item) => {
                    return (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.amount}</td>
                      </tr>
                    )
                  })}
                  <tr>
                    <td>Gross Sales</td>
                    <td>{cashupDetails[0]?.grossSales}</td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <td>Net Returns</td>
                    <td>{cashupDetails[0]?.netReturns}</td>
                  </tr>
                  <tr>
                    <td>Gross Returns</td>
                    <td>{cashupDetails[0]?.grossReturns}</td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <td>TOTAL RETAIL TRANSACTIONS</td>
                    <td>{cashupDetails[0]?.totalRetailTransactions}</td>
                  </tr>
                </table>
                <h3>STARTING:</h3>
                <table>
                  {cashupDetails[0]?.cashPaymentMethodInfo?.map((item, ind) => {
                    return (
                      <tr>
                        <td>Starting {item?.paymentMethod}</td>
                        <td>{item?.startingCash}</td>
                      </tr>
                    )
                  }
                  )}
                </table>
                <h3>WITHDRAWAL:</h3>
                <table>
                  {cashupDetails[0]?.cashPaymentMethodInfo?.map((item, ind) => {
                    return (
                      <tr>
                        <td>Starting {item?.paymentMethod}</td>
                        <td>{item?.totalDrops}</td>
                      </tr>
                    )
                  }
                  )}
                </table>
                <h3>DEPOSIT:</h3>
                <table>
                  {cashupDetails[0]?.cashPaymentMethodInfo?.map((item, ind) => {
                    return (
                      <tr>
                        <td>Starting {item?.paymentMethod}</td>
                        <td>{item?.totalSales}</td>
                      </tr>
                    )
                  }
                  )}
                </table>

                <h3>TOTAL TO KEEP:</h3>
                <table>
                  {cashupDetails[0]?.cashPaymentMethodInfo?.map((item, ind) => {
                    return (
                      <tr>
                        <td>Starting {item?.paymentMethod}</td>
                        <td>{item?.amountToKeep}</td>
                      </tr>
                    )
                  }
                  )}
                </table>
                <h3>TOTAL TO DEPOSIT:</h3>
                <table>
                  {cashupDetails[0]?.cashPaymentMethodInfo?.map((item, ind) => {
                    return (
                      <tr>
                        <td>Starting {item?.paymentMethod}</td>
                        <td>{item?.totalDeposits}</td>
                      </tr>
                    )
                  }
                  )};
                </table>
              </div>
            </div>
          </Scrollbars>
        </div>
          :
          <div class="table-container">
            <div id="pdfTable" >
              <div class="table-header-container">
                <div class="table-header-item">
                  <img src={Logo} style={{ height: "50px" }} />
                </div>
                <div class="table-header-item">
                  Cashup Report
                </div>
                <div class="table-header-item">
                  CLOSED
                </div>
                <div class="table-header-item">
                  Store Name:  {storeName}
                </div>
                <div class="table-header-item">
                  Terminal Number:  {terminalNumber}
                </div>
                <div class="table-header-item">
                  User:  {CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem("cashierData")).Username, 'secret-key').toString(CryptoJS.enc.Utf8)}
                </div>
              </div>
              <table>
                <tr>
                  <td>Net-Sales</td>
                  <td>{cashupDetails[0]?.netSales}</td>
                </tr>
                {cashupDetails[0]?.cashTaxInfo?.map((item) => {
                  return (
                    <tr>
                      <td>{item.name}</td>
                      <td>{item.amount}</td>
                    </tr>
                  )
                })}
                <tr>
                  <td>Gross Sales</td>
                  <td>{cashupDetails[0]?.grossSales}</td>
                </tr>
              </table>
              <table>
                <tr>
                  <td>Net Returns</td>
                  <td>{cashupDetails[0]?.netReturns}</td>
                </tr>
                <tr>
                  <td>Gross Returns</td>
                  <td>{cashupDetails[0]?.grossReturns}</td>
                </tr>
              </table>
              <table>
                <tr>
                  <td>TOTAL RETAIL TRANSACTIONS</td>
                  <td>{cashupDetails[0]?.totalRetailTransactions}</td>
                </tr>
              </table>
              <h3 style={{ fontWeight: "600" }}>STARTING:</h3>
              <table>
                {cashupDetails[0]?.cashPaymentMethodInfo?.map((item, ind) => {
                  return (
                    <tr>
                      <td>Starting {item?.paymentMethod}</td>
                      <td>{item?.startingCash}</td>
                    </tr>
                  )
                }
                )}
              </table>
              <h3 style={{ fontWeight: "600" }}>WITHDRAWAL:</h3>
              <table>
                {cashupDetails[0]?.cashPaymentMethodInfo?.map((item, ind) => {
                  return (
                    <tr>
                      <td>Starting {item?.paymentMethod}</td>
                      <td>{item?.totalDrops}</td>
                    </tr>
                  )
                }
                )}
              </table>
              <h3 style={{ fontWeight: "600" }}>DEPOSIT:</h3>
              <table>
                {cashupDetails[0]?.cashPaymentMethodInfo?.map((item, ind) => {
                  return (
                    <tr>
                      <td>Starting {item?.paymentMethod}</td>
                      <td>{item?.totalSales}</td>
                    </tr>
                  )
                }
                )}
              </table>

              <h3 style={{ fontWeight: "600" }}>TOTAL TO KEEP:</h3>
              <table>
                {cashupDetails[0]?.cashPaymentMethodInfo?.map((item, ind) => {
                  return (
                    <tr>
                      <td>Starting {item?.paymentMethod}</td>
                      <td>{item?.amountToKeep}</td>
                    </tr>
                  )
                }
                )}
              </table>
              <h3 style={{ fontWeight: "600" }}>TOTAL TO DEPOSIT:</h3>
              <table>
                {cashupDetails[0]?.cashPaymentMethodInfo?.map((item, ind) => {
                  return (
                    <tr>
                      <td>Starting {item?.paymentMethod}</td>
                      <td>{item?.totalDeposits}</td>
                    </tr>
                  )
                }
                )};
              </table>
            </div>
          </div>
      }
    </>
  )
}

export default CashupPrint
