import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Spin, Modal, Button, message, Input, Card } from "antd";
import { LoadingOutlined, LeftCircleOutlined } from '@ant-design/icons';
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import CardSwipe from "../../assets/SCO_asset/payment/cardPay.png";
import Axios from "axios";
import URL from "../../app/server.json";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { Observable } from 'rxjs';
import { useTranslation } from 'react-i18next';
import { scoLog } from "./scoLog";
import moment from "moment";
import { isEmpty, set } from "lodash";
import CryptoJS from 'crypto-js';

const CardPayment = () => {
  let lab = localStorage.getItem("pinelab");
  const keyboardRef1 = useRef(null);
  const keyboardRef2 = useRef(null);
  const navigate = useNavigate();
  const [time, setTime] = useState(0);
  const [msg, setMsg] = useState("");
  const [loginFlag, setLoginFlag] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [transactionFlag, setTransactionFlag] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [loading, setLoading] = useState(false);
  const [validateData, setValidateData] = useState();
  const [name, setName] = useState("");
  const [keyboard, setKeyboard] = useState(1);
  const [layoutUserName, setlayoutUserName] = useState("default");
  const [manualFlag, setManulaFlag] = useState(false);
  const [paymentCancel, setPaymentCancel] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userNameError, setUserNameError] = useState({ flag: false, msg: "" });
  const [tIdError, setTIdError] = useState({ flag: false, msg: "" });
  const [cursorPosition, setCursorPosition] = useState(null);
  const [blankPage, setBlankPage] = useState(false);
  const [isShiftDoubleClick, setIsShiftDoubleClick] = useState(false);
  const [shiftDoubleClick, setShiftDoubleClick] = useState(false);
  const [socket, setSocket] = useState("");
  const [giftCardPayment,setGiftCardPayment] = useState({});
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual")
  let token = localStorage.getItem("token");
  let p2pRequestId = localStorage.getItem("transactionId");
  const cashierData = JSON.parse(localStorage.getItem("cashierData"));
  let cashierUsername = CryptoJS.AES.decrypt(cashierData.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
  let cashierPassword = CryptoJS.AES.decrypt(cashierData.Password, 'secret-key').toString(CryptoJS.enc.Utf8);
  let userData =  JSON.parse(localStorage.getItem('userData'));
  let basicAuth = `${cashierUsername}:${cashierPassword}`
  const apiKey = URL.GRAVITEE_API_KEY;
  let index = 0;
  let socketInd = 0;


  // Order Obj creation Start
  let selectedProducts = JSON.parse(localStorage.getItem("selectedProducts"));
  let orderDate = localStorage.getItem("orderDate");
  let salebegintime = localStorage.getItem("salebegintime");
  let response = localStorage.getItem("response");
  let doorNo;
  let street;
  let zipcode;
  let city;
  let country;
  let state;
  let email;
  let mobile;
  let total = 0;
  let discount = 0;
  let decathlonId;
  let orderId = Number(localStorage.getItem("order_lastassignednum")) + 1;
  let terminalId = localStorage.getItem("terminalNumber");
  let customerFlag = localStorage.getItem("customerSignupFlag");
  let customerEmail = localStorage.getItem("email");
  let customerPhoneNo = localStorage.getItem("phoneNo");
  let customerName = localStorage.getItem("surname");
  let customerPincodeNo = localStorage.getItem("pincodeNo");
  let linesArray = [];
  let taxDetails = [];
  selectedProducts = selectedProducts === null ? {} : selectedProducts;
  if (selectedProducts.tillData !== undefined ||selectedProducts.tillData !== null
  ) {
    selectedProducts.productsData.map((item, ind) => {
      total = total + item.sale_price;
      discount = discount + (item.sale_price * item.percentage) / 100;
    });
    let taxData = JSON.parse(localStorage.getItem("taxData"));
    taxData.newArray.map((item) => {
      JSON.parse(item.tax).map((e, ind) => {
          if (e.name[0] === "C") {
              let taxableAmount = Number(item.taxableAmount * item.count);
              let sellingAmount = item.discountSalePrice;
              let netAmount = sellingAmount - taxableAmount;
              let obj = {
                  "itemCode": item.id,
                  "unitPrice": item.discountPrice,
                  "ean": item.ean,
                  "serialNumber":item.ean,
                  "rfidscancode":"",
                  "epcCode":item.epcCode,
                  "offer": false,
                  "offerName": item.discountName === undefined ? "" : item.discountName === undefined,
                  "type": "takeaway",
                  "unitDiscount": item.discountValue,
                  "qty": item.count,
                  "taxCategory": e.name,
                  "taxRate": e.rate,
                  "sellingAmount": sellingAmount,
                  "taxableAmt": taxableAmount.toFixed(2),
                  "ISGST": item.isgst.toFixed(2),
                  "ICGST": item.icgst,
                  "netAmount": netAmount.toFixed(2),
                  "taxId": e.taxId,
                  "swap": item.swap,
                  'tax':item.tax,
              };
              linesArray.push(obj);
          };
      });
  });
  taxData.taxArray.map((element) => {
      if (JSON.parse(element.gst).length > 0) {
          let index = 0;
          let taxCategoryName = JSON.parse(element.gst)?.findIndex(item => item.name.charAt(0) === "S");
          if (taxCategoryName !== -1) {
              index = taxCategoryName;
          };
          JSON.parse(element.gst).map((item, index) => {
              if (item.name === "CGST-LS" || item.name === "CGST-LY" || item.name === "CGST-LP") {
                  if (index === 0) {
                      if (JSON.parse(element.gst)[1].name.charAt(0) === "S") {
                          let taxObj = {
                              "taxCategory": JSON.parse(element.gst)[1].name,
                              "taxRate": element.key / 2,
                              "TSGST": element.isgst.toFixed(2),
                              "TCGST": element.icgst.toFixed(2),
                              "CGST": element.GroupCGST,
                              "SGST": element.GroupSGST,
                              "sellingAmount": element.discountSalePrice,
                              "taxedAmount": element.taxPrice,
                              "netAmount": (element.basePrice).toFixed(2),
                              "taxId": JSON.parse(element.gst)[1].taxId,
                              "verify": element.verify,
                              "gst":JSON.parse(element.gst),
                          };
                          taxDetails.push(taxObj);
                      }
                  } else {
                      if (JSON.parse(element.gst)[0].name.charAt(0) === "S") {
                          let taxObj = {
                              "taxCategory": JSON.parse(element.gst)[0].name,
                              "taxRate": element.key / 2,
                              "TSGST": element.isgst.toFixed(2),
                              "TCGST": element.icgst.toFixed(2),
                              "CGST": element.GroupCGST,
                              "SGST": element.GroupSGST,
                              "sellingAmount": element.discountSalePrice,
                              "taxedAmount": element.taxPrice,
                              "netAmount": (element.basePrice).toFixed(2),
                              "taxId": JSON.parse(element.gst)[0].taxId,
                              "verify": element.verify,
                              "gst":JSON.parse(element.gst),
                          };
                          taxDetails.push(taxObj);
                      }
                  }
              } else if (item.name !== "CGST-LS" || item.name !== "CGST-LY" || item.name !== "CGST-LP" || item.name !== "SGST-LS" || item.name !== "SGST-LY" || item.name !== "SGST-LP") {
                  if (item.name.charAt(0) === "C") {
                      let taxObj = {
                          "taxCategory": item.name,
                          "taxRate": element.key / 2,
                          "TSGST": element.isgst.toFixed(2),
                          "TCGST": element.icgst.toFixed(2),
                          "CGST": element.GroupCGST,
                          "SGST": element.GroupSGST,
                          "sellingAmount": element.discountSalePrice,
                          "taxedAmount": element.taxPrice,
                          "netAmount": (element.basePrice).toFixed(2),
                          "taxId": item.taxId,
                          "verify": element.verify,
                          "gst":JSON.parse(element.gst),
                      };
                      taxDetails.push(taxObj);
                  }
              }
          });
      };
  });
  doorNo = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeAddressline1;
  street = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeAddressline2;
  zipcode = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storePostalCode;
  city = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeCityName;
  country = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeCountry;
  state = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeRegion;
  if (userData !== undefined) {
    if (userData !== null) {
      email = userData.result.Email !== undefined ? userData.result.Email : customerEmail;
      mobile =  userData.result.Mobile !== undefined ?  userData.result.Mobile : customerPhoneNo;
      decathlonId = userData.result.CardNumber;
    }
  }
  }

  let obj = {
    address: [
      {
        doorNo: `${doorNo}`,
        street: `${street}`,
        zipcode: `${zipcode}`,
        city: `${city}`,
        country: `${country}`,
        state: `${state}`,
        type: "billing",
      },
    ],
    MyDecathlonId: decathlonId !== undefined ? `${decathlonId}` : "",
    email:!isEmpty(email) ? CryptoJS.AES.encrypt(email, 'secret-key').toString(): isEmpty(customerEmail) ? "" : CryptoJS.AES.encrypt(customerEmail, 'secret-key').toString(),
    mobile: !isEmpty(mobile) ? CryptoJS.AES.encrypt(mobile, 'secret-key').toString() : isEmpty(customerPhoneNo) ? "" : CryptoJS.AES.encrypt(customerPhoneNo, 'secret-key').toString(),
    zipcode:customerPincodeNo||"",
    paymentMethod: "",
    name: customerFlag === "false" ? name : customerName,
    salebegintime: `${salebegintime}`,
    orderDate: `${moment(orderDate).format("DD-MM-YYYY")}`,
    totalGrossAmount: Number((total - discount).toFixed(2)),
    totalDiscount: 0,
    lines: linesArray.reverse(),
    store: `${"7" + terminalId.toString().substring(2, 6) + terminalId.toString().substring(2, 6)}`,
    terminal: `${terminalId}`,
    orderNumber: `${terminalId}/${Number(orderId)}`,
    payment: [],
    saleendtime: `${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}`,
    PaymentDone: "true",
    cashiername: `${cashierData.Username}`,
    ebillMode: "",
    taxDetails: taxDetails,
  };

  // Order Obj creation End

  useEffect(() => {
    if (socketInd === 0) {
      setSocket(new WebSocket(URL.PINE_LAB_URL));
      socketInd++;
    };
  }, []);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  useEffect(() => {
    let data = localStorage.getItem("giftCardPayment") ? JSON.parse(localStorage.getItem("giftCardPayment")) : {}
    setGiftCardPayment(data)
    let tempName = localStorage.getItem("surname");
    setName(tempName);
  }, []);

  useEffect(() => {
    if (index === 0) {
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      selectedProducts = selectedProducts === null ? {} : selectedProducts;
      if (selectedProducts.productsData !== undefined || selectedProducts.productsData !== null) {
        setTime(JSON.parse(selectedProducts.tillData[0].paymentConfigurations).phonepeConfigurations.verifyTimeout);
      };
    };
    index++;
  }, []);

  const sendMessage = (message) => {
    if (socket.readyState === 1) {
      socket.send(message);
      return `Sent to server ${message}`;
    } else {
      return 'Message was not sent - the socket is closed';
    };
  };

  useEffect(() => {
    if (socket !== "") {
      if (lab === "true") {
        let object = JSON.parse(localStorage.getItem("socket"));
        const input = object.txnType + ', ' + object.orderId + ', ' + CryptoJS.AES.decrypt(object.price, 'secret-key').toString(CryptoJS.enc.Utf8);
        setTimeout(() => {
          if (sendMessage(input.toString()).search('closed') < 0) {
          } else {
            setLoading(false);
            message.error("Card Payment Device is not Connected");
            setBlankPage(true);
          }
        }, 500);
      };
    };
  }, [socket]);

  useEffect(() => {
    if (lab === "true" && blankPage === false) {
      if (socket !== "" && time !== 0) {
        function createSocketObservable(socket) {
          return new Observable(
            observer => {
              socket.onmessage = (event) => observer.next(event.data);
              socket.onerror = (event) => {
                observer.error(event);
              };
              socket.onclose = (event) => {
                observer.complete();
              };
              return () =>
                socket.close(1000, 'The user disconnected');
            }
          );
        }
        const observable = createSocketObservable(socket);
        observable.subscribe(
          data => {
            console.log('Message From Server:\n' + data);
            if (typeof data === 'string') {
              if (((data).search('APPROVED')) > 0) {
                socket.close();
                // let orderId = Number(localStorage.getItem("order_lastassignednum")) + 1;
                obj.paymentMethod = "Card";
                obj.payment = [
                  {
                    paymentMethod : "Card",
                    paymentRefId: `${terminalId}${Number(orderId)}`,
                  },
                ];

                if(giftCardPayment.giftCard === true){
                  giftCardPayment.payment.map((payments)=>{
                    obj.payment.push(payments)
                  })
                }
                localStorage.setItem("orderObj", JSON.stringify(obj));
                localStorage.setItem("tempCardTransactionId",`${terminalId}${Number(orderId)}`)
                localStorage.setItem("order_lastassignednum", Number(orderId));
                scoLog(name);
                navigate("/success-payment");
              } else if (((data).search("Failed To Process Transaction. Please Retry ")) > 0 || ((data).search("TXN CANCELLED")) > 0) {
                socket.close();
                setManulaFlag(true);
                setTime(0);
              } else {
                console.log('something is wrong in response from socket');
              };
            };
          },
          err => {
            console.log('Message From Error:\n' + err);
          },
          () => console.log('The observable stream is complete')
        );
      } else if (socket !== "" && time === 0) {
        socket.close();
        setManulaFlag(true);
      };
    } else {
      const cashierData = JSON.parse(localStorage.getItem("cashierData"));
      cashierData.Username = CryptoJS.AES.decrypt(cashierData.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
      cashierData.Password = CryptoJS.AES.decrypt(cashierData.Password, 'secret-key').toString(CryptoJS.enc.Utf8);    
      let basicAuth = `${cashierData.Username}:${cashierData.Password}`
      if (time !== 0) {
        const timer = setInterval(() => {
          let token = localStorage.getItem("token");
          let terminalId = localStorage.getItem("terminalNumber");
          let headers = {
            "Content-Type": "application/json",
            "token": `Basic ${window.btoa(basicAuth)},${token}`,
          }
          headers[URL.key_NAME] = apiKey;
          Axios({
            url: `${URL.GRAVITEE_BASE_URL}/statusCheck`,
            method: "GET",
            data: {
              "documentNo": `${terminalId}/${Number(orderId)}`,
              "origP2pRequestId": `${p2pRequestId}`,
            },
            headers: headers,
            params: {
              "documentNo": `${terminalId}/${Number(orderId)}`,
              "origP2pRequestId": `${p2pRequestId}`,
            }
          }).then((response) => {
            if (response.data.statusCode === 200) {
              setValidateData(response.data.data);
              setManulaFlag(true);
              setMsg(response.data.data.message);
              if (response.data.data.status === 0) {
                localStorage.setItem("response", JSON.stringify(response.data));
                  obj.paymentMethod = "Ezetap Card";
                  obj.payment = [
                    {
                      paymentMethod :"Ezetap Card",
                      totalamountpaid: giftCardPayment.giftCard === true ? (total - discount - giftCardPayment.amountPaid).toFixed(2) : Number((total - discount).toFixed(2)),
                      transactionid: "",
                      paymentRefId: `${response.data.data.data.txnID}`,
                      ismanual: "N",
                      cashiername: CryptoJS.AES.encrypt(`${cashierData.Username}`, 'secret-key').toString(),
                    },
                  ];
                  if(giftCardPayment.giftCard === true){
                    giftCardPayment.payment.map((payments)=>{
                      obj.payment.push(payments)
                    })
                  }
                  localStorage.setItem("tempCardTransactionId",response.data.data.data.txnID)
                  localStorage.setItem("orderObj", JSON.stringify(obj));
                  localStorage.setItem("order_lastassignednum", Number(orderId));
                  navigate("/success-payment");
                  scoLog();
              } else if (response.data.data.status === 1) {
              } else if (response.data.data.messageCode === "P2P_DEVICE_CANCELED") {
                navigate("/payment-selection")
              }
            } else {
              message.error("Something is wrong");
              navigate("/card-payment");
            }
          });
        }, 1000);
        if (msg === "Transaction Complete!") {
          clearInterval(timer);
        }
        return () => {
          clearInterval(timer);
        };
      }
    };
  });

  useEffect(() => {
    if (!paymentCancel) {
      setTimeout(() => {
        navigate("/payment-selection");
      }, 2000)
    }
    const timer = setInterval(() => {
      setTime(time - 1);
    }, 1000);
    if (time === 0) {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  });
  const giftcardToken = async () => {
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    if (selectedProducts.tillData !== undefined || selectedProducts.tillData !== null) {
      let orderId = localStorage.getItem("order_lastassignednum");
      let terminalId = localStorage.getItem("terminalNumber");
      const formattedDateTime = `d_ikey_${moment().format('YYYY-MM-DD')}T${moment().format('HH:mm:ss')}`;
      let body = {
        "UserName": `${URL.Gift_Card_token_UserName}`,
        "Password":  `${URL.Gift_Card_token_Password}`,
        "ForwardingEntityId":  `${URL.Gift_Card_token_ForwardingEntityId}`,
        "ForwardingEntityPassword":  `${URL.Gift_Card_token_ForwardingEntityPassword}`,
        "TransactionId":  `${URL.Gift_Card_token_TransactionId}`,
        "TerminalId":  `${URL.Gift_Card_token_TerminalId}`,
        "idempotencyKey": formattedDateTime
      };
      const config = {
        headers: {
          'X-Gravitee-Api-Key': apiKey,
        },
      };
      try {
        const res = await Axios.post(`${URL.GRAVITEE_BASE_URL}/giftcardToken`, body, config);
        if (res.data.statusCode === 200) {
          localStorage.setItem("giftCardToken", JSON.stringify(res?.data?.data))
          onCancelGiftCard()
          let obj =
          {
            "store": JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.value,
            "terminal": terminalId,
            "apiType": "Authentication",
            "documentno": `${terminalId}/${Number(orderId)}`,
            "amount": 0,
            "cardNumber": "",
            "idempotencyKey": null,
            "transactionId": "43093",
            "approvalCode": "",
            "qwikcilverRequest": body,
            "qwikcilverResponse": res.data.data
          }

          let bodyData = {
            "logs": obj,
            "remainingUrl": `/ws/com.openbravo.decathlon.retail.giftcards.qwikCilverLog`,
            "Authorization": `Basic ${window.btoa(basicAuth)}, ${token}`
          };

          await Axios.post(`${URL.GRAVITEE_BASE_URL}/qwikCilverLog`, bodyData, config);
          setLoading(false);
        };
      } catch (error) {
        setLoading(false);
        message.error("Something is wrong in get token for discounts service");
      };
    };
  };
  const onCancelGiftCard = async () => {
    const tokenDetails = JSON.parse(localStorage.getItem("giftCardToken"))
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    let giftCardData = JSON.parse(localStorage.getItem("giftCardPayment"));
    if (giftCardData !== undefined && giftCardData !== null) {
      let body = {
        "inputType": "1",
        "numberOfCards": 1,
        "Cards": [
          {
            "CardNumber": giftCardData.response.Cards[0].CardNumber,
            "OriginalRequest":
            {
              "OriginalBatchNumber": giftCardData.response.CurrentBatchNumber,
              "OriginalTransactionId": giftCardData.response.TransactionId,
              "OriginalAmount":giftCardData.TotalAmount,
              "OriginalInvoiceNumber": giftCardData.response.Cards[0].InvoiceNumber
            },
            "Reason": giftCardData.response.Notes.split("|")[0].replace(/{|}/g, "")
          }
        ],
      };
      let headers={
        "DateatClient":"2021-09-22T13:17:00",
        "Authorization":`Bearer ${tokenDetails?.AuthToken}` ,
        "TransactionId": giftCardData.response.TransactionId,
        "MerchantOutletNameToOverride": "Decathlon-Test-01",
      }
      try {
        const res = await Axios.post(`${URL.CANCEL_GIFTCARD_URL}`, body, { headers });
        if (res.status === 200) {
          localStorage.removeItem("giftCardPayment");
        }
      } catch (error) {
        console.log(error);
      }
    }

  }
  const cancelPayment = () => {
    let giftCardData = JSON.parse(localStorage.getItem("giftCardPayment"));
    if (manualFlag) {
      if (giftCardData !== undefined && giftCardData !== null) {
        giftcardToken()
      }
      let headers = {
        "Content-Type": "application/json",
        "token": `Basic ${window.btoa(basicAuth)},${token}`,
      }
      headers[URL.key_NAME] = apiKey;
      Axios({
        url: `${URL.GRAVITEE_BASE_URL}/callCancel`,
        method: "GET",
        data: {
          "origP2pRequestId": `${p2pRequestId}`
        },
        headers: headers,
        params: {
          "origP2pRequestId": `${p2pRequestId}`
        },
      }).then((response) => {
        if (response.data.statusCode === 200) {
          setPaymentCancel(false);
          setIsModalOpen(false);
        } else {
          setIsModalOpen(false);
          message.error("Canceled Transaction");
        }
      })
    } else {
      if (giftCardData !== undefined && giftCardData !== null) {
        giftcardToken()

      }
      setPaymentCancel(false);
      setTimeout(() => {
        navigate("/payment-selection");
      }, 2000)
    }
  }

  const handleUsername = (ev) => {
    setUsername(ev.target.value);
  };

  const handlePassword = (ev) => {
    setPassword(ev.target.value);
  };

  const handleTransactionId = (ev) => {
    setTIdError({ flag: false, msg: "" });
    setTransactionId(ev.target.value);
  };

  const handleVerify = () => {
    setTransactionFlag(false);
    obj.paymentMethod = "Ezetap Manual";
    obj.payment = [
      {
        paymentMethod :"Ezetap Manual",
        transactionid: "",
        paymentRefId: `${transactionId}`,
        ismanual: "Y",
        cashiername: `${cashierData.Username}`,
        totalamountpaid: giftCardPayment.giftCard === true ? (total - discount - giftCardPayment.amountPaid).toFixed(2) : Number((total - discount).toFixed(2)),
      },
    ];
    if(giftCardPayment.giftCard === true){
      giftCardPayment.payment.map((payments)=>{
        obj.payment.push(payments)
      })
    }
    localStorage.setItem("orderObj", JSON.stringify(obj));
    localStorage.setItem("tempCardTransactionId",transactionId)
    localStorage.setItem("order_lastassignednum", Number(orderId));
    localStorage.setItem("response", JSON.stringify(validateData));
    let ebill = localStorage.getItem("ebill");
    if (ebill === "true" && transactionId !== "") {
      scoLog(name);
      navigate("/bill-mode");
    } else {
      if (transactionId === "") {
        setTransactionFlag(true)
        setlayoutUserName("numbers")
        setTIdError({ flag: true, msg: "Please Enter Transaction Id" });
      }
      else {
        localStorage.setItem("billMode", "Printed Receipt");
        scoLog(name);
        navigate("/feedback-steptwo");
      }
    };
  };

  const handleLogin = () => {
    setLoading(true);
    let cashierData = JSON.parse(localStorage.getItem('cashierData'));
    cashierData.Username = CryptoJS.AES.decrypt(cashierData.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
    cashierData.Password = CryptoJS.AES.decrypt(cashierData.Password, 'secret-key').toString(CryptoJS.enc.Utf8);  
    if (username === cashierData.Username && password === cashierData.Password) {
      setLoading(false);
      setlayoutUserName("numbers")
      if (lab === "true") {
        localStorage.setItem("paymentMode", "Manual Card");
        let ebill = localStorage.getItem("ebill");
        obj.paymentMethod = "Manual Card";
        obj.payment = [
          {
            paymentMethod : "Manual Card",
            paymentRefId: `${terminalId}${Number(orderId)}`,
            totalamountpaid: giftCardPayment.giftCard === true ? (total - discount - giftCardPayment.amountPaid).toFixed(2) : Number((total - discount).toFixed(2)),
          },
        ];
        if(giftCardPayment.giftCard === true){
          giftCardPayment.payment.map((payments)=>{
            obj.payment.push(payments)
          })
        }
        localStorage.setItem("orderObj", JSON.stringify(obj));
        localStorage.setItem("order_lastassignednum", Number(orderId));
        scoLog(name);
        if (ebill === "true") {
          scoLog(name);
          navigate("/bill-mode");
        } else {
          localStorage.setItem("billMode", "Printed Receipt");
          scoLog(name);
          navigate("/feedback-steptwo");
        };
      } else {
        setTransactionFlag(true);
      };
    } else {
      setLoading(false);
      setUserNameError({ flag: true, msg: "Please give valid credentials" });
    }
  };

  const onKeyPressNumber = (button) => {
    setTIdError({ flag: false, msg: "" });
    if (button === "{backspace}") {
      if (cursorPosition !== 0) {
        setTransactionId(transactionId.slice(0, -1));
        setCursorPosition(cursorPosition - 1)
      }
      else {
        setTransactionId(transactionId)
      }
    };
    if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" & button !== "{tab}" && button !== "{enter}" & button !== "{space}") {
      if (layoutUserName === "default") setlayoutUserName("shift");
      let text = `${transactionId}${button}`
      setTransactionId(text.trim());
    }
    if (button === "{shift}" || button === "{lock}") handleShift()
    if (button === "{numbers}" || button === "{caps}") handleNumbers();
    if (button === "{number}" || button === "{small}") handleNumber();
    if (button === "{space}") handleSpace();
    if (button === "{back}") setTransactionId(transactionId);
    if (button === "{mic}") handleMic();
  };

  const onClickUserName = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(1)
    setShiftDoubleClick(false)
  }

  const onClickPassword = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(2)
    setShiftDoubleClick(false)
  }

  const onClickTransactionId = (event) => {
    const newCursorPosition = event.target.selectionStart;
    setCursorPosition(newCursorPosition)
    setKeyboard(3)
    setShiftDoubleClick(false)
  }

  const onKeyPress = (button) => {
    setUserNameError({ flag: false, msg: "" });
    if (keyboard === 1) {
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setUsername(username.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setUsername(username)
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" & button !== "{tab}" && button !== "{enter}" & button !== "{space}") {
        if (!shiftDoubleClick) {
          if (layoutUserName === "default") setlayoutUserName("shift");
        }
        let text = `${username}${button}`
        setUsername(text.trim());
      }
    } else if (keyboard === 2) {
      if (button === "{backspace}") {
        if (cursorPosition !== 0) {
          setPassword(password.slice(0, -1));
          setCursorPosition(cursorPosition - 1)
        }
        else {
          setPassword(password)
        }
      };
      if (button === "{shift}" || button === "{lock}") handleShift();
      if (button !== "{shift}" && button !== "{lock}" && button !== "{backspace}" & button !== "{tab}" && button !== "{enter}" & button !== "{space}") {
        if (!shiftDoubleClick) {
          if (layoutUserName === "default") setlayoutUserName("shift");
        }        let text = `${password}${button}`
        setPassword(text.trim());
      }
    }
    if (button === "{numbers}" || button === "{caps}") handleNumbers();
    if (button === "{number}" || button === "{small}") handleNumber();
    if (button === "{back}") handleReturn();
    if (button === "{space}") handleSpace();
    if (button === "{mic}") handleMic();
  };

  const handleMic = () => {
    setUsername(username);
    setPassword(password);
    setTransactionId(transactionId);
    setlayoutUserName(layoutUserName)
  }

  const handleSpace = () => {
    if (keyboard === 1) {
      setUsername(username + ' ');
    } else if (keyboard === 2) {
      setPassword(password + ' ');
    }
    setTransactionId(transactionId + ' ');
  }

  const handleReturn = () => {
    if (keyboard === 1) {
      keyboardRef2.current.focus();
      setKeyboard(2)
    }
    else if (keyboard === 2) {
      keyboardRef1.current.focus();
      setKeyboard(1)
    }
    setUsername(username);
    setPassword(password);
    setTransactionId(transactionId);
  }

  const handleShift = () => {
    const layout1 = layoutUserName;
    setlayoutUserName(layout1 === "default" ? "shift" : "default");
    setUsername(username);
    setPassword(password);
    setTransactionId(transactionId);
    if (isShiftDoubleClick) {
      setlayoutUserName('default')
      setShiftDoubleClick(true)
    } else {
      setIsShiftDoubleClick(true);

      setTimeout(() => {
        setIsShiftDoubleClick(false);
      }, 500);
    }

  };

  const handleNumber = () => {
    const layout1 = layoutUserName;
    setlayoutUserName(layout1 === "shift" ? "number" : "shift");
    setUsername(username);
    setPassword(password);
    setTransactionId(transactionId);
  };

  const handleNumbers = () => {
    const layout1 = layoutUserName;
    setlayoutUserName(layout1 === "default" ? "numbers" : "default");
    setUsername(username);
    setPassword(password);
    setTransactionId(transactionId);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  return (
    <div>
      <Spin indicator={antIcon} spinning={loading} >
        <Row align='middle' style={{ backgroundColor: "#0072AF", padding: "5px 0 5px 50px", height: "10vh" }} >
          <Col span={6}>
            <img src={Logo} alt="Logo" style={{ height: "50px" }} />
          </Col>
          <Col span={12}>
          </Col>
          <Col span={6} >
            <div style={{ display: "flex", float: "right", alignItems: "center" }}>
              <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
              </span>
              <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
                <LeftCircleOutlined style={{ fontSize: "24px" }} onClick={() => setIsModalOpen(true)} />
                <span style={{ fontSize: "15px" }}>Back</span>
              </span>
            </div>
          </Col>
        </Row>
        {!loginFlag ?
          (blankPage !== true ? <Row justify="space-around" style={{ height: "90vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Col className="qr-card-block" style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              <>
                {paymentCancel ?
                  (time !== 0 ?
                    <>
                      <span style={{ color: "rgba(0, 0, 0, 0.66)", fontSize: "2.7vh", fontWeight: 700 }}>
                        Please insert the card in the machine to make the payment
                      </span>
                      {multilingual === "true" && (
                        <span style={{ color: "rgba(0, 0, 0, 0.66)", fontSize: "2.7vh", fontWeight: 700 }}>{t('please insert the card in the machine to make the payment')}</span>
                      )}
                      <Card className="billmode-card" style={{ borderRadius: "6px", marginTop: "4vh", height: "auto", width: "35vw" }}>
                        <img width="225" height="235" src={CardSwipe} alt="" />
                      </Card>
                      <div style={{ marginTop: "2vh" }}>
                        <h2 style={{ color: "#FA0404", marginBottom: "0rem" }}>{time}</h2>
                      </div>
                      <p />
                      <Button className="back-btn" style={{ fontWeight: "600" }} onClick={() => setIsModalOpen(true)}>
                        Cancel
                        {multilingual === "true" && (
                          <>
                            <span style={{ marginLeft: "0.5em" }} >{"/"}</span>
                            <span style={{ marginLeft: "0.5em" }}>{t('cancel')}</span>

                          </>)}
                      </Button>
                    </>
                    :
                    <>
                      <div style={{ marginTop: "2vh" }}>
                        <h2 className="payment-status-text">Payment Incomplete</h2>
                      </div>
                      <div style={{ paddingBottom: "1rem", paddingTop: "1rem", display: manualFlag ? "" : "flex", alignItems: manualFlag ? "" : "center", justifyContent: manualFlag ? "center" : "center" }}>
                        {manualFlag ? <Button style={{ cursor: "pointer", fontSize: "20px", backgroundColor: "#0083c6", borderRadius: "4px", color: "white", height: "6vh", width: "15vw" }} onClick={() => { setLoginFlag(true); }}>Manual Card</Button> : ""}
                      </div>
                      <p style={{ fontFamily: "Roboto Condensed", color: "#767677", fontSize: "18px", width: "80vw", position: "relative" }}>Click on the BACK button to choose different payment method or go to SUPPORT to manual verify</p>
                      <Button className="back-btn" onClick={() => setIsModalOpen(true)}>
                        Cancel
                        {multilingual === "true" && (
                          <>
                            <span style={{ marginLeft: "0.5em" }} >{"/"}</span>
                            <span style={{ marginLeft: "0.5em" }}>{t('cancel')}</span>

                          </>)}
                      </Button>
                    </>
                  )
                  :
                  ""
                }
              </>
            </Col>
          </Row> : <div></div>)
          :
          !transactionFlag ?
            <div>
              <Row>
                <Col span={1} />
                <Col span={22} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <p style={{ fontSize: "3vh", fontWeight: "600" }}>Enter Admin Credentials</p>
                  <div style={{ marginBottom: "6vh" }}>
                    <Input inputMode="none" className="cp-input-field" ref={keyboardRef1} onFocus={() => setKeyboard(1)} onClick={onClickUserName} value={username} onChange={handleUsername} placeholder="User Name" />
                    <div className="cp-field-lable" >
                      User Name
                      {multilingual === "true" && (
                        <>
                          <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                          <span style={{ marginLeft: "0.3rem" }}>{t('user name')}</span>
                        </>                      )}
                    </div>
                  </div>
                  <div style={{ marginBottom: "6vh" }}>
                    <Input inputMode="none" className="cp-input-field" type={"password"} ref={keyboardRef2} onFocus={() => setKeyboard(2)} onClick={onClickPassword} value={password} onChange={handlePassword} placeholder="Password" />
                    <div style={{ position: "absolute", top: "23%", fontSize: "1.1rem", left: "30%", backgroundColor: "white", padding: "0 2%", color: "#A0A8B2" }} >
                      Password
                      {multilingual === "true" && (
                        <>
                          <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                          <span style={{ marginLeft: "0.3rem" }}>{t('password')}</span>
                        </>                      )}
                    </div>
                  </div>
                  <div style={{ height: "1vh", fontSize: "2.5vh", color: userNameError.flag === false ? "#fff" : "red", position: "relative", top: "-6%" }}>{userNameError.flag === false ? "" : userNameError.msg}</div>
                  <Button onClick={handleLogin} className="cp-continue-btn">CONTINUE
                  {multilingual === "true" && (
                <>
                  <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                  <span style={{ marginLeft: "0.3rem" }}>{t('continue')}</span>
                </>              )}
                  </Button>
                  <div style={{ height: "20rem", width: "100%", position: "relative" }}>
                    <Keyboard
                      layoutName={layoutUserName}
                      inputName={keyboard === 1 ? "un" : "psd"}
                      onChange={(val) => { keyboard === 1 ? setUsername(val) : setPassword(val) }}
                      onKeyPress={onKeyPress}
                      theme={"hg-theme-default login"}
                      buttonTheme={[
                        {
                          class: "hg-yellow",
                          buttons: "{space}"
                        },
                        {
                          class: "hg-mail",
                          buttons: "@yahoo.com @gmail.com"
                        },
                      ]}
                      display={{
                        "{numbers}": "123",
                        "{smileys}": "\uD83D\uDE03",
                        "{number}": "123",
                        '{bksp}': 'backspace',
                        "{backspace}": "⌫",
                        "{shift}": "⇧",
                        '{tab}': 'tab',
                        '{lock}': 'lock',
                        '{enter}': 'enter',
                        '{space}': "Space",
                        "{caps}": "ABC",
                        "{small}": "abc",
                        "{back}": "Enter"
                      }}
                      layout={{
                        shift: [
                          "q w e r t y u i o p",
                          "a s d f g h j k l",
                          "{shift} z x c v b n m {backspace}",
                          "{number} {space} @yahoo.com @gmail.com"
                        ],
                        default: [
                          "Q W E R T Y U I O P",
                          "A S D F G H J K L",
                          "{shift} Z X C V B N M {backspace}",
                          "{numbers} {space} @yahoo.com @gmail.com"
                        ],
                        numbers: [
                          "1 2 3 4 5 6 7 8 9 0",
                          '- / : ; ( ) $ & @ "',
                          '# . , _ ! ` {backspace}',
                          "{caps} {space} {back}"
                        ],
                        number: [
                          "1 2 3 4 5 6 7 8 9 0",
                          '- / : ; ( ) $ & @ "',
                          '# . , _ ! ` {backspace}',
                          "{small} {space} {back}"
                        ],
                      }}
                    />
                  </div>
                </Col>
                <Col span={1} />
              </Row>
            </div>
            :
            <div>
              <Row>
                <Col span={1} />
                <Col span={22} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div style={{ position: "relative", top: "8%" }}>
                    <Input inputMode="none" className="cp-tId-field" value={transactionId} onChange={handleTransactionId} onPressEnter={handleVerify} onFocus={() => { setKeyboard(3) }} onClick={onClickTransactionId} />
                    <div className="cp-tId-lable">Transaction ID</div>
                    <div style={{ left: "24%", height: "1vh", fontSize: "2.5vh", color: tIdError.flag === false ? "#fff" : "red", position: "absolute", top: "120%" }}>{tIdError.flag === false ? "" : tIdError.msg}</div>
                  </div>
                  <Button className="cp-final-continue-btn" onClick={handleVerify} >CONTINUE
                  {multilingual === "true" && (
                <>
                  <span style={{ marginLeft: "0.3rem" }}>{"/"}</span>
                  <span style={{ marginLeft: "0.3rem" }}>{t('continue')}</span>
                </>              )}
                  </Button>
                  <div style={{ width: "100%", position: "relative" }}>
                    <Keyboard
                      buttonTheme={[
                        {
                          class: "hg-yellow",
                          buttons: "{space}"
                        },
                        {
                          class: "hg-space",
                          buttons: "{space}"
                        },
                        {
                          class: "hg-mail",
                          buttons: "@yahoo.com @gmail.com"
                        },
                      ]}
                      layoutName={layoutUserName}
                      onChange={(val) => { setTransactionId(val) }}
                      onKeyPress={onKeyPressNumber}
                      theme={"hg-theme-default login"}
                      display={{
                        "{abc}": "ABC",
                        "{smileys}": "\uD83D\uDE03",
                        "{back}": "Enter",
                        "{numbers}": "123",
                        "{number}": "123",
                        '{bksp}': 'backspace',
                        "{backspace}": "⌫",
                        "{shift}": "⇧",
                        '{tab}': 'tab',
                        '{lock}': 'lock',
                        '{enter}': 'enter',
                        '{space}': "Space",
                        "{caps}": "ABC",
                        "{small}": "abc",
                      }}
                      layout={{
                        shift: [
                          "q w e r t y u i o p",
                          "a s d f g h j k l",
                          "{shift} z x c v b n m {backspace}",
                          "{number} {space} @yahoo.com @gmail.com"
                        ],
                        numbers: [
                          "1 2 3 4 5 6 7 8 9 0",
                          '- / : ; ( ) $ & @ "',
                          '# . , _ ! ` {backspace}',
                          "{caps} {space} {back}"
                        ],
                        number: [
                          "1 2 3 4 5 6 7 8 9 0",
                          '- / : ; ( ) $ & @ "',
                          '# . , _ ! ` {backspace}',
                          "{small} {space} {back}"
                        ],
                        default: [
                          "Q W E R T Y U I O P",
                          "A S D F G H J K L",
                          "{shift} Z X C V B N M {backspace}",
                          "{numbers} {space} @yahoo.com @gmail.com"
                        ],
                      }}
                    />
                  </div>
                </Col>
                <Col span={1} />
              </Row>
            </div>
        }
        <Modal okText="Yes" cancelText="No" open={isModalOpen} onOk={cancelPayment} onCancel={() => setIsModalOpen(false)}>
          <p style={{ fontWeight: 600, fontSize: "3vh", paddingTop: "1em" }}>Are you sure you want to cancel the transaction ?</p>
        </Modal>
      </Spin>
    </div>
  );
};

export default CardPayment;
