import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "antd";
import "antd/dist/antd.css";
import "../../antd.css";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png"
import NsatOthers from "./NSatOthers";
import Axios from "axios";
import URL from "../../app/server.json";
import { useNavigate } from "react-router-dom";


const NsatReasons = (props) => {
    const navigate = useNavigate();
    const [nSatOthersVisible, setNSatOthersVisible] = useState(false);
    const [shuffledReasons, setShuffledReasons] = useState([]);
    const apiKey = URL.GRAVITEE_API_KEY;
    const NsatReasons = JSON.parse(localStorage.getItem("NSatReasons"))

    const shuffleArray = (array) => {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };
    useEffect(() => {
        // Make a copy of NsatReasons, shuffle it, and filter out "Others"
        const shuffledAndFiltered = shuffleArray([...NsatReasons]).filter(item => item.reason !== "Others");
        // Insert "Others" at a random position in the shuffled array
        const randomIndex = Math.floor(Math.random() * (shuffledAndFiltered.length + 1));
        shuffledAndFiltered.splice(randomIndex, 0, ...NsatReasons.filter(item => item.reason === "Others"));
        setShuffledReasons(shuffledAndFiltered);
    }, []);
    const onClickReason = (selectedReason) => {
        if (selectedReason === "Others") {
            setNSatOthersVisible(true)
        }
        else {
            props.setNSatReason(selectedReason)
            // navigate('/thankyou')
        }
    }
    return (
        <div style={{ background: "#F7F8FA", width: "100%", minHeight: "100vh" }}>
            <Row align='middle' style={{ backgroundColor: "#0072AF", padding: "5px 50px 5px 50px", height: "10vh" }}>
                <Col span={6}>
                    <img src={Logo} alt="Logo" style={{ height: "50px" }} />
                </Col>
                <Col offset={14} span={4}>
                </Col>
            </Row>
            <div style={{ textAlign: "center" }} >
                <Row gutter={[0, 16]} style={{ marginTop: "3vh" }}>
                    <Col span={24} style={{ fontSize: "24px", fontFamily: "Roboto Condensed", fontWeight: 400, }}>
                        We value your opinion. Please choose a reason for your not satisfied experience
                    </Col>
                </Row >
                {
                    nSatOthersVisible ? (<NsatOthers setNSatReason={props.setNSatReason} />) : (
                        <Row gutter={[0, 16]} justify="space-evenly" style={{ marginTop: "3vh" }}>
                            {shuffledReasons.map((list, index) => (
                                <Col onClick={() => { onClickReason(list.reason) }} key={index} style={{ background: '#FFFFFF', borderRadius: '10px', padding: '1em', paddingTop: "1em", display: 'flex', flexDirection: 'column', alignItems: 'center', width: "16vw", margin: '0 8px' }}>
                                    <Card style={{ background: 'rgba(0, 121, 186, 0.14)', borderRadius: '10px', height: '120px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={list.reasonImage} alt="Reason img" />
                                    </Card>
                                    <span style={{ fontFamily: 'Roboto Condensed', fontWeight: 500, fontSize: '17px', margin: '2.9vh' }}>{list.reason}</span>
                                </Col>
                            ))}

                        </Row>
                    )
                }

            </div>
        </div>
    );

}
export default NsatReasons;