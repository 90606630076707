import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, message, Spin } from "antd";
import { LoadingOutlined, LeftCircleOutlined } from "@ant-design/icons";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import select from "../../assets/SCO_asset/payment/select.png";
import CardPayment from "../../assets/SCO_asset/payment/card-swipe.gif";
import UpiPayment from "../../assets/SCO_asset/payment/upi.gif";
import GiftCard from "../../assets/SCO_asset/payment/gicard.gif";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import Axios from "axios";
import moment from "moment";
import URL from "../../app/server.json";
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import GiftCardPayment from "./giftCard-payment";

const PaymentSelection = () => {
  localStorage.setItem("pinelab", false);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");
  const [showGiftcardDetails, setShowGiftcardDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finalArray, setFinalArray] = useState([]);
  const [selectedItems, setSelectedItems] = useState(() => {
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    if (selectedProducts.productsData !== undefined || selectedProducts.productData !== null) {
      return selectedProducts.productsData;
    } else {
      return [];
    }
  });
  const [summaryObj, setSummaryObj] = useState({ items: 0, count: 0, discount: 0, total: 0 });
  const [socket, setSocket] = useState("");
  const [modalFlag, setModalFlag] = useState(false);
  const [tokenDetails, setTokenDetails] = useState();
  const [cardNumber, setCardNumber] = useState('');
  const [giftCardAmount, setGiftCardAmount] = useState(false);
  const [giftCardPayment, setGiftCardPayment] = useState({});
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual")

  let token = localStorage.getItem("token");
  const cashierData = JSON.parse(localStorage.getItem("cashierData"));
  let cashierUsername = CryptoJS.AES.decrypt(cashierData.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
  let cashierPassword = CryptoJS.AES.decrypt(cashierData.Password, 'secret-key').toString(CryptoJS.enc.Utf8);
  let basicAuth = `${cashierUsername}:${cashierPassword}`;
  const apiKey = URL.GRAVITEE_API_KEY;

  let index = 0;

  useEffect(() => {
    if (index === 0) {
      setSocket(new WebSocket(URL.PINE_LAB_URL));
      index++;
    };
    let amount = JSON.parse(localStorage.getItem("giftCardPayment"))?.amountPaid > 0 ? JSON.parse(localStorage.getItem("giftCardPayment"))?.amountPaid : 0
    setGiftCardAmount(amount)
  }, []);

  useEffect(() => {
    let data = localStorage.getItem("giftCardPayment") ? JSON.parse(localStorage.getItem("giftCardPayment")) : {}
    setGiftCardPayment(data)
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  useEffect(() => {
    let items = 0;
    let count = 0;
    let discount = 0;
    let total = 0;
    let arr = selectedItems?.length > 0 ? [...selectedItems] : [];
    arr.map((item, ind) => {
      items = items + ind + 1;
      count = count + item.count;
      discount = discount + (Number(item.sale_price) * item.percentage) / 100;
      total = total + Number(item.sale_price);
    });
    setSummaryObj({ items: items, count: count, discount: discount, total: total });
    let counts = {};
    selectedItems.forEach(item => {
      let id = item.id;
      counts[id] = (counts[id] || 0) + 1;
    });
    const newArray = [];
    const seenIds = new Set();
    selectedItems.forEach(obj => {
      if (!seenIds.has(obj.id)) {
        obj[`discountSalePrice`] = Number((Number(obj.sale_price) - (Number(obj.sale_price) * obj.percentage / 100)).toFixed(2))
        newArray.push(obj);
        seenIds.add(obj.id);
      } else {
        newArray.map((item, ind) => {
          if (obj.id === item.id) {
            item[`discountSalePrice`] = item[`discountSalePrice`] + Number((Number(obj.sale_price) - (Number(obj.sale_price) * obj.percentage / 100)).toFixed(2))
          }
        })
      }
    });
    Object.entries(counts).map((key) => {
      newArray.map((item) => {
        if (key[0] === item.id) {
          if (key[1] > 0) {
            item.count = key[1];
          }
        }
      })
    });
    setFinalArray(newArray);
  }, [selectedItems]);

  const paymentSelectionList = [
    {
      title: "Card",
      img: CardPayment,
      description:
        "This will enable the payment machine and you can insert your card to proceed",
    },
    {
      title: "UPI",
      img: UpiPayment,
      description: "This will enable the QR code and you can scan to pay",
    },
    {
      title: "Gift Card",
      img: GiftCard,
      description: "",
    },
  ];

  const sendMessage = () => {
    if (socket.readyState === 1) {
      return `Sent to server ${message}`;
    } else {
      return 'Message was not sent - the socket is closed';
    }
  };

  const handlePaymentSelection = (value, cardIndex) => {
    const selectedBillModeIndex = _.findIndex(paymentSelectionList, [
      "title",
      value,
    ]);
    if (selectedBillModeIndex === cardIndex) {
      localStorage.setItem("paymentMode", "card");
      setLoading(true);
      if (value === "Card") {
        let total = 0;
        let discount = 0;
        let storePhone;
        let storeManagerMailId;
        let locationStore;
        let store;
        let posterminal;
        let organization;
        let customerData = JSON.parse(localStorage.getItem('customerData'));
        let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
        let orderId = localStorage.getItem("order_lastassignednum");
        let terminalId = localStorage.getItem("terminalNumber");
        let data = localStorage.getItem("giftCardPayment") ? JSON.parse(localStorage.getItem("giftCardPayment")) : {}
        selectedProducts = selectedProducts === null ? {} : selectedProducts;
        if (selectedProducts.productsData !== undefined || selectedProducts.productsData !== null) {
          selectedProducts.productsData.map((item, ind) => {
            total = total + Number(item.sale_price);
            discount = discount + (Number(item.sale_price) * item.percentage) / 100;
          });
          storePhone = JSON.parse(selectedProducts.tillData[0].storeDetail).storePhoneNo;
          storeManagerMailId = JSON.parse(selectedProducts.tillData[0].storeDetail).storeManagerMailId;
          locationStore = JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeCityName;
          store = JSON.parse(selectedProducts.tillData[0].storeDetail).storeName;
          posterminal = JSON.parse(selectedProducts.tillData[0].storeDetail).touchpoint;
          organization = JSON.parse(selectedProducts.tillData[0].storeDetail).organization;
        };
        if (JSON.parse(selectedProducts.tillData[0].storeDetail).ezetap === "Y") {
          let headers = {
            "Content-Type": "application/json",
            token: `Basic ${window.btoa(basicAuth)},${token}`,
          }
          headers[URL.key_NAME] = apiKey;
          Axios({
            url: `${URL.GRAVITEE_BASE_URL}/start`,
            method: "GET",
            data: {
              "amount": CryptoJS.AES.encrypt((data.giftCard === true ? total - discount - data.amountPaid : total - discount).toFixed(2), 'secret-key').toString(),
              "documentNo": `${terminalId}/${Number(orderId) + 1}-${moment(new Date()).format("DD-MM-YYYY HH:mm:ss")}`,
              "mobNumber": CryptoJS.AES.encrypt(customerData.phoneNo, 'secret-key').toString(),
              "syncEmail": `${customerData.email}`,
              "storePhone": CryptoJS.AES.encrypt(storePhone, 'secret-key').toString(),
              "storeManagerMail": CryptoJS.AES.encrypt(storeManagerMailId, 'secret-key').toString(),
              "locationStore": `${locationStore}`,
              "store": `${store}`,
              "terminalName": `${terminalId}`,
              "paymentMode": "CARD",
              "posterminal": `${posterminal}`,
              "organization": `${organization}`
            },
            headers: headers,
            params: {
              "amount": CryptoJS.AES.encrypt((data.giftCard === true ? total - discount - data.amountPaid : total - discount).toFixed(2), 'secret-key').toString(),
              "documentNo": `${terminalId}/${Number(orderId) + 1}-${moment(new Date()).format("DD-MM-YYYY HH:mm:ss")}`,
              "mobNumber": CryptoJS.AES.encrypt(customerData.phoneNo, 'secret-key').toString(),
              "syncEmail": `${customerData.email}`,
              "storePhone": CryptoJS.AES.encrypt(storePhone, 'secret-key').toString(),
              "storeManagerMail": CryptoJS.AES.encrypt(storeManagerMailId, 'secret-key').toString(),
              "locationStore": `${locationStore}`,
              "store": `${store}`,
              "terminalName": `${terminalId}`,
              "paymentMode": "CARD",
              "posterminal": `${posterminal}`,
              "organization": `${organization}`
            },
          }).then((response) => {
            if (response.data.statusCode === 200) {
              localStorage.setItem("transactionId", response.data.data.data?.p2pRequestId);
              if (response.data.data.status === 0) {
                setLoading(false);
                navigate('/card-payment');
              } else {
                setLoading(false);
                message.error("Card Payment Device is not Connected");
              }
            } else {
              setLoading(false);
              message.error("Card Payment Device is not Connected");
            }
          }).catch(err => {
            setLoading(false);
            message.error(err.message);
          });
        } else {
          const txnType = '4001';
          const price = data.giftCard === true ? (Number((total - discount - data.amountPaid).toFixed(2)) * 100).toFixed() : (Number((total - discount).toFixed(2)) * 100).toFixed();
          let object = {
            txnType: txnType,
            price: CryptoJS.AES.encrypt(price, 'secret-key').toString(),
            orderId: terminalId / Number(orderId) + 1
          }
          if (sendMessage().search('closed') < 0) {
            localStorage.setItem("pinelab", true);
            localStorage.setItem("socket", JSON.stringify(object));
            setLoading(false);
            socket.close();
            navigate('/card-payment');
          } else {
            setLoading(false);
            message.error("Card Payment Device is not Connected");
          };
        };
      } else if (value === "UPI") {
        setLoading(false);
        navigate('/qr-payment');
        localStorage.setItem("paymentMode", "PhonePe");
      } else if (value === "Gift Card") {
        setShowGiftcardDetails(true)
        giftcardToken();
      }
      setSelectedValue(value);
    };
  };

  const giftcardToken = async () => {
    // setModalFlag(true)
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    if (selectedProducts.tillData !== undefined || selectedProducts.tillData !== null) {
      let orderId = localStorage.getItem("order_lastassignednum");
      let terminalId = localStorage.getItem("terminalNumber");
      const formattedDateTime = `d_ikey_${moment().format('YYYY-MM-DD')}T${moment().format('HH:mm:ss')}`;
      let body = {
        "UserName": `${URL.Gift_Card_token_UserName}`,
        "Password":  `${URL.Gift_Card_token_Password}`,
        "ForwardingEntityId":  `${URL.Gift_Card_token_ForwardingEntityId}`,
        "ForwardingEntityPassword":  `${URL.Gift_Card_token_ForwardingEntityPassword}`,
        "TransactionId":  `${URL.Gift_Card_token_TransactionId}`,
        "TerminalId":  `${URL.Gift_Card_token_TerminalId}`,
        "idempotencyKey": formattedDateTime
      };
      const config = {
        headers: {
          'X-Gravitee-Api-Key': apiKey,
        },
      };
      try {
        const res = await Axios.post(`${URL.GRAVITEE_BASE_URL}/giftcardToken`, body, config);
        if (res.data.statusCode === 200) {
          setTokenDetails(res?.data?.data);
          localStorage.setItem("giftCardToken", JSON.stringify(res?.data?.data))
          let obj =
          {
            "store": JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.value,
            "terminal": terminalId,
            "apiType": "Authentication",
            "documentno": `${terminalId}/${Number(orderId)}`,
            "amount": 0,
            "cardNumber": "",
            "idempotencyKey": null,
            "transactionId": "43093",
            "approvalCode": "",
            "qwikcilverRequest": body,
            "qwikcilverResponse": res.data.data
          }

          let bodyData = {
            "logs": obj,
            "remainingUrl": `/ws/com.openbravo.decathlon.retail.giftcards.qwikCilverLog`,
            "Authorization": `Basic ${window.btoa(basicAuth)}, ${token}`
          };

          await Axios.post(`${URL.GRAVITEE_BASE_URL}/qwikCilverLog`, bodyData, config);
          setLoading(false);
          // navigate("/giftcard-payment")
        };
      } catch (error) {
        setLoading(false);
        message.error("Something is wrong in get token for discounts service");
      };
    };
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  return (
    <>
      {
        showGiftcardDetails ? (<GiftCardPayment handlePaymentSelection={handlePaymentSelection} setShowGiftcardDetails={setShowGiftcardDetails} setSelectedValue={setSelectedValue}/>) : (
          <div>
            <Spin indicator={antIcon} spinning={loading}>
              <Row align='middle' style={{ backgroundColor: "#0072AF", height: "10vh" }}>
                <Col span={6}>
                  <img src={Logo} alt="Logo" style={{ position: "relative", left: "11%", height: "50px" }} />
                </Col>
                <Col span={12}>
                </Col>
                <Col span={6}>
                  <div style={{ display: "flex", float: "right", alignItems: "center" }}>
                    <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
                    </span>
                    <span style={{ display: "grid", color: "#FFFFFF", marginRight: "37px" }}>
                      <LeftCircleOutlined style={{ fontSize: "24px" }} onClick={()=>{navigate("/home")}} />
                      <span style={{ fontSize: "15px" }}>Back</span>
                    </span>
                  </div>
                </Col>
              </Row>
              <Row style={{ backgroundColor: "#F7F8FA", padding: "7.5vh 4.1vh 4.6vh 4.1vh" }}>
                <Col span={16} className="ps-left-col">
                  <h2 className="ps-main-heading">How would you like to pay?</h2>
                  {multilingual === "true" && (
                    <h2 className="ps-main-heading">{t('how would you like to pay?')}</h2>
                  )}
                  <Row style={{ width: "53.4vw" }}>
                    {paymentSelectionList.map((item, index) => (
                      <>
                        <Col span={8} onClick={() => handlePaymentSelection(item.title, index)} style={{ paddingRight: "1vw" }}>
                          <img src={select} alt="" style={{ position: "absolute", zIndex: "1", right: "0", backgroundColor: "yellow", borderRadius: "50%", padding: "4%", right: "-7%", top: "-4%", visibility: selectedValue === item.title ? "visible" : "hidden" }} />
                          <Card style={{ height: "50vh", width: "17vw", border: "1px solid rgba(0, 0, 0, 0.06)", borderRadius: "1vh", boxShadow: selectedValue === "card" ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "" }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                              <span style={{ fontSize: "2.6vh", fontWeight: "600", paddingTop: "10%" }}>{item.title}</span>
                              {multilingual === "true" && (
                                <span style={{ fontSize: "2.6vh", fontWeight: "600", }}>
                                  {item.title === "Card" ? t('with-card') : t('with-upi')}
                                </span>
                              )}
                              <img src={item.img} alt="" style={{ height: "20vh", width: "100%", paddingLeft: "15px" }} />
                              {index === 0 ?
                                <p style={{ fontSize: "1.8vh", textAlign: "center", paddingTop: "30px", fontWeight: 600, color: "#000000 63%" }}>This will enable the payment machine. Insert card to proceed with your payment.</p> : index === 1 ?
                                  <p style={{ fontSize: "1.8vh", textAlign: "center", paddingTop: "30px", fontWeight: 600, color: "#000000 63%" }}>This will enable QR CODE. Scan to proceed with your paymemt</p> : <div> <p style={{ fontSize: "2.5vh", textAlign: "center", paddingTop: "30px", fontWeight: 600, color: "#000000 63%", marginBottom: 0 }}>Redeem Amount</p> <p style={{ fontSize: "3.5vh", textAlign: "center", paddingTop: "0vh", fontWeight: 600, color: "#000000 63%" }}>{JSON.parse(localStorage.getItem("giftCardPayment"))?.amountPaid > 0 ? JSON.parse(localStorage.getItem("giftCardPayment"))?.amountPaid : ""}</p></div>
                              }
                            </div>
                          </Card>
                        </Col>
                      </>
                    ))}
                    <Col span={3} />
                    <Col span={1} />
                  </Row>
                </Col>
                <Col span={8}>
                  <Card bodyStyle={{ padding: 15, height: "77vh" }} style={{ width: "100%" }} className="totalCard-home">
                    <Scrollbars autoHide style={{ height: '76vh' }}>
                      <span className="current-summary" style={{ fontSize: "2vh" }}>
                        Order Summary
                        {multilingual === "true" && (
                          <span style={{ marginLeft: "1rem" }}>{t('order-summary')}</span>
                        )}
                      </span>
                      <Row >
                        <Col span={12} style={{ textAlign: "left" }}>
                          <p className="subtotal" style={{ fontSize: "2vh", lineHeight: "3.8vh" }}>Items: {summaryObj?.count}</p>
                        </Col>
                        <Col span={12}>
                        </Col>
                      </Row>
                      <div className="subtotal-details-div-home" style={{ backgroundColor: "#F7F8FA", marginBottom: "0", padding: "0", height: "39vh" }}>
                        <Scrollbars style={{ height: "40vh" }}>
                          {finalArray.map(item => (
                            <Row style={{ padding: "1vh 1vh 0 0" }}>
                              <Col span={6} style={{ textAlign: "left", padding: "1vh" }}>
                                <img src={item.imageUrl} style={{ height: "4.1vh", width: "3.5vw" }} />
                              </Col>
                              <Col span={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <span style={{ fontSize: "12px", fontWeight: "bold" }}>{item.name} </span>
                                <span style={{ fontSize: "1.5vh", textAlign: "left", fontWeight: 600 }}>QTY {item.count}</span>
                              </Col>
                              <Col span={6}>
                                <p className="subtotal-price" style={{ fontSize: "2vh", lineHeight: "3.8vh", color: "#0079BA", fontWeight: 700 }}>₹ {item?.discountSalePrice}</p>
                              </Col>
                            </Row>
                          ))}
                        </Scrollbars>
                      </div>
                      <div className="total-details-div-home" style={{ backgroundColor: "#fff", marginBottom: "0.7rem", padding: "3.4vh 0px 0 0" }}>
                        <Row>
                          <Col span={18} style={{ textAlign: "left" }}>
                            <p className="total" style={{ fontSize: "2vh", lineHeight: "3.8vh", fontWeight: 600 }}>Total products (Inc GST)</p>
                          </Col>
                          <Col span={6}>
                            <p className="total-price" style={{ fontSize: "2vh", lineHeight: "3.8vh", fontWeight: 700 }}>₹ {summaryObj.total.toFixed(2)}</p>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: "2vh" }}>
                          <Col span={18} style={{ textAlign: "left" }}>
                            <p className="discount-label" style={{ fontSize: "2vh", lineHeight: "3.8vh", fontWeight: 600 }}>Discount:</p>
                          </Col>
                          <Col span={6}>
                            <p className="discount-price-home" style={{ fontSize: "2vh", lineHeight: "3.8vh", fontWeight: 700 }}>₹ {summaryObj.discount.toFixed(2)}</p>
                          </Col>
                        </Row>
                        <div style={{ height: "1px", width: "100%", backgroundColor: "#665E5E", marginBottom: "1vh" }} />
                        <Row>
                          <Col span={12} style={{ textAlign: "left" }}>
                            <p className="total" style={{ fontSize: "3vh", lineHeight: "3.8vh", fontWeight: 600 }}>Total</p>
                          </Col>
                          <Col span={12}>
                            <p className="total-price" style={{ fontSize: "3vh", lineHeight: "3.8vh", fontWeight: 600 }}>₹ {(summaryObj.total - summaryObj.discount - giftCardAmount).toFixed(2)}</p>
                          </Col>
                        </Row>
                      </div>
                      <div style={{ fontSize: "1.7vh", backgroundColor: "#DDFAF5", height: "4.6vh", textAlign: "center", paddingTop: "0.8vh", fontWeight: 700, marginBottom: "2%", borderRadius: "0.7vh" }}>
                        You save ₹ {summaryObj.discount.toFixed(2)} in this order
                      </div>
                    </Scrollbars>
                  </Card>
                </Col>
              </Row>
            </Spin>
          </div>
        )
      }
    </>
  );
};

export default PaymentSelection;
