import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../login";
import Signup from "../signup";
import Home from "../home";
import CardPayment from "../payment/card-payment";
import QrPayment from "../payment/qr-payment";
import GiftCardPayment from "../payment/giftCard-payment";
import SuccessPayment from "../payment/payment-success";
import BillMode from "../bill-mode";
import FeedbackStepTwo from "../feedback/feedbackStepTwo";
import Thankyou from "../thank-you";
import PaymentSelection from "../payment/payment-selection";
import CustomerLogin from "../customerLogin"
import Loading from "../loading"
import CustomerSignup from "../customerSignup";
import Admin from "../admin";
import AdminLogin from "../admin/adminLogin";
import io from 'socket.io-client';
import { autoIncrement } from "../websocket/autoIncrement";
import URL from "../../app/server.json";

const RoutesApp = () => {
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [])

  useEffect(() => {
    const socket = io(URL.WEBSOCKET_URL);
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    if (selectedProducts !== undefined && selectedProducts !== null) {
      const org_id = JSON.parse(selectedProducts?.tillData[0]?.storeDetail)?.organization;
      const terminal = localStorage.getItem("terminalNumber");
      socket.emit('register', { org_id, terminal });
      socket.on('message', (message) => {
        console.log('Received message:', message);
        console.log("dynamoDB", true);
        autoIncrement();
      });
    };
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/customer-login" element={<CustomerLogin />} />
          <Route exact path="/loading" element={<Loading />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/card-payment" element={<CardPayment />} />
          <Route exact path="/giftcard-payment" element={<GiftCardPayment />} />
          <Route exact path="/qr-payment" element={<QrPayment />} />
          <Route exact path="/success-payment" element={<SuccessPayment />} />
          <Route exact path="/bill-mode" element={<BillMode />} />
          <Route exact path="/feedback-steptwo" element={<FeedbackStepTwo />} />
          <Route exact path="/thankyou" element={<Thankyou />} />
          <Route exact path="/payment-selection" element={<PaymentSelection />} />
          <Route exact path="/customer-signup" element={<CustomerSignup />} />
          <Route exact path="/admin-login" element={<AdminLogin />} />
        </Routes>
      </Router>
    </div>
  );
};

export default RoutesApp;
