import db from "../../database";
import Axios from "axios";
import URL from "../../app/server.json";
import moment from "moment";

const autoIncrement = async () => {
    const apiKey = URL.GRAVITEE_API_KEY;
    const config = {
        headers: {},
      };
      config.headers[URL.key_NAME] = apiKey;

    const tillData = async () => {
        console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "tillstart");
        let partitionKey = localStorage.getItem("terminalNumber");
        Axios.get(`${URL.GRAVITEE_BASE_URL}/tillData?partitionKey=${partitionKey}`, config)
            .then(async response => {
                if (response.status === 200) {
                    if (response.data.length > 0) {
                        const data = response.data;
                        let obj = {
                            "type": "till",
                            "data": data
                        };
                        await db.tempData.add(obj);
                        console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "tillend");
                        await getComboData();
                    }
                };
            });
    };

    const getComboData = async () => {
        console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "combostart");
        Axios.get(`${URL.GRAVITEE_BASE_URL}/comboData`, config)
            .then(async response => {
                if (response.status === 200) {
                    const data = response.data;
                    let obj = {
                        "type": "combo",
                        "data": data
                    };
                    await db.tempData.add(obj);
                    console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "comboend");
                    await getMultiBuyData();
                };
            });
    };

    const getMultiBuyData = async () => {
        console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "multistart");
        Axios.get(`${URL.GRAVITEE_BASE_URL}/multibuyData`, config)
            .then(async response => {
                if (response.status === 200) {
                    const data = response.data;
                    let obj = {
                        "type": "multibuy",
                        "data": data
                    };
                    await db.tempData.add(obj);
                    console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "multiend");
                    await incrementalProducts();
                };
            });
    };

    const incrementalProducts = async () => {
        let productsloadstarttime = localStorage.getItem("productsloadstarttime");
        localStorage.setItem("productsloadstarttime", moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"));
        console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "incrementalstart");
        let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
        let partitionKey = JSON.parse(selectedProducts.tillData[0]?.storeDetail)?.organization;
        Axios.get(`${URL.GRAVITEE_BASE_URL}/incrementalProducts?partitionKey=${partitionKey}&lastUpdatedTime=${productsloadstarttime}`, config)
            .then(async response => {
                if (response.status === 200) {
                    const data = response?.data?.data;
                    let obj = {
                        "type": "products",
                        "data": data
                    };
                    await db.tempData.add(obj);
                    console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', seconds: 'numeric' }), "incrementalend");
                    localStorage.setItem("dynamoDB", true);
                    // Trigger the custom event
                    const customEvent = new CustomEvent('customStorageChange', {
                        detail: { key: 'dynamoDB', newValue: JSON.stringify(true) }
                    });
                    window.dispatchEvent(customEvent);
                } else {

                };
            });
    };

    tillData();
};

export {
    autoIncrement
};