import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import Logo from "../../assets/SCO_asset/home/decathlon-logo.png";
import Penguine from "../../assets/SCO_asset/home/thankyou.gif";
import Barcode from 'react-barcode';
import moment from "moment";
import "./printReceipt.css";
import Qrcode from "../../assets/SCO_asset/home/QRcode.jpeg";
import { isEmpty } from "lodash";
import CryptoJS from 'crypto-js';

const Thankyou = () => {
  const navigate = useNavigate();
  const [isVisibleFlag, setIsVisibleFlag] = useState(false);
  const [isQty, setIsQty] = useState(0);
  const [isTotal, setIsTotal] = useState(0);
  const [isTaxTotal, setIsTaxTotal] = useState(0);
  const [username, setUserName] = useState("");
  const [cashierData, setCashierData] = useState();
  const [discount, setDiscount] = useState(0);
  const [finalArray, setFinalArray] = useState([]);
  const [taxArray, setTaxArray] = useState([]);

  useEffect(() => {
    let tempName = localStorage.getItem("surname");
    setUserName(tempName);
  }, []);

  let userData =  JSON.parse(localStorage.getItem('userData'));
  let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
  let terminalNumber = localStorage.getItem("terminalNumber");
  let orderId = Number(localStorage.getItem('order_lastassignednum'));
  let orderDate = localStorage.getItem("orderDate")
  let saleendtime = localStorage.getItem("saleendtime")
  let customerFlag = localStorage.getItem("customerSignupFlag");
  let customerEmail = localStorage.getItem("email");
  let customerPhoneNo = localStorage.getItem("phoneNo");
  let customerName = localStorage.getItem("surname");
  let customerPincodeNo = localStorage.getItem("pincodeNo");
  let email;
  let mobile;
  let decathlonId;
  let PaymentModeArray = JSON.parse(localStorage.getItem("orderObj"))?.payment ?? []


  useEffect(() => {
    let ebillMode = localStorage.getItem("billMode");
    let total = 0;
    let disc = 0;
    let qty = 0;
    let cashier = JSON.parse(localStorage.getItem('cashierData'));
    cashier.Username = CryptoJS.AES.decrypt(cashier.Username, 'secret-key').toString(CryptoJS.enc.Utf8);
    setCashierData(cashier);
    let totalTaxAmount = 0;
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    if (userData !== undefined ) {
      if (userData !== null) {
        email = userData.result.Email !== undefined ? userData.result.Email : customerEmail;
        mobile = userData.result.Mobile !== undefined ? userData.result.Mobile : customerPhoneNo;
        customerName = userData.result.Firstname;
        decathlonId = userData.result.CardNumber;
      };
    }
    if (selectedProducts.tillData !== undefined || selectedProducts.tillData !== null) {
      selectedProducts.productsData.map((item, ind) => {
        total = total + JSON.parse(item.sale_price);
        disc = disc + (JSON.parse(item.sale_price) * item.percentage) / 100;
        qty = qty + item.count;
        totalTaxAmount = item.taxableAmount + totalTaxAmount;
      });
      setIsTaxTotal(totalTaxAmount.toFixed(2))
      setIsQty(qty);
      setIsTotal(total);
      setDiscount(disc);
    };
    let taxData = JSON.parse(localStorage.getItem("taxData"));
    setFinalArray(taxData.newArray);
    setTaxArray(taxData.taxArray);
    if (ebillMode === "Printed Receipt") {
      setIsVisibleFlag(false);
      setTimeout(() => {
        window.print();
      }, 300)
    } else {
      // localStorage.setItem("order_lastassignednum", Number(orderId));
      let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
      selectedProducts = selectedProducts === null ? {} : selectedProducts;
      selectedProducts.productsData = [];
      setIsVisibleFlag(true);
      setTimeout(() => {
        localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
        localStorage.removeItem("tempTransactionId");
        localStorage.removeItem("tempCardTransactionId");
        localStorage.removeItem("tempQrTransactionId");
        localStorage.removeItem("isCardPayment");
        localStorage.removeItem("paymentMode");
        localStorage.removeItem("surname");
        localStorage.removeItem("response");
        localStorage.removeItem("billMode");
        localStorage.removeItem("transactionId");
        localStorage.removeItem("ismanual");
        localStorage.removeItem("isphonepe");
        localStorage.removeItem("salebegintime");
        localStorage.removeItem("saleendtime");
        localStorage.removeItem("orderDate");
        localStorage.removeItem("customerData");
        localStorage.removeItem("customerSignupFlag");
        localStorage.removeItem("socket");
        localStorage.removeItem("pinelab");
        // localStorage.removeItem("orderObj");
        localStorage.removeItem("giftCardPayment");
        navigate('/customer-login');
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
          window.history.pushState(null, document.title, window.location.href);
        });
      }, 500);
    };
  }, []);

  window.onafterprint = (event) => {
    // localStorage.setItem("order_lastassignednum", Number(orderId));
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    selectedProducts = selectedProducts === null ? {} : selectedProducts;
    selectedProducts.productsData = [];
    localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
    localStorage.removeItem("tempTransactionId");
    localStorage.removeItem("tempCardTransactionId");
    localStorage.removeItem("tempQrTransactionId");
    localStorage.removeItem("isCardPayment");
    localStorage.removeItem("paymentMode");
    localStorage.removeItem("surname");
    localStorage.removeItem("response");
    localStorage.removeItem("billMode");
    localStorage.removeItem("transactionId");
    localStorage.removeItem("ismanual");
    localStorage.removeItem("isphonepe");
    localStorage.removeItem("salebegintime");
    localStorage.removeItem("saleendtime");
    localStorage.removeItem("orderDate");
    localStorage.removeItem("customerData");
    localStorage.removeItem("customerSignupFlag");
    localStorage.removeItem("socket");
    localStorage.removeItem("pinelab");
    localStorage.removeItem("orderObj");
    localStorage.removeItem("giftCardPayment");
    navigate("/customer-login");
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  };

  const PrintReceipt = () => {
    return (
      <>
        <div className="parent-container" >
          <div className="container">
            <div className="item">
              <img src={Logo} />
            </div>
            <div className="item">
              ORIGINAL
            </div>
            <div className="item">
              Store Email
            </div>
            <div className="item">
              {JSON.parse(selectedProducts.tillData[0].storeDetail).storeEmailId}
            </div>
            <div className="item">
              Store Manager Email
            </div>
            <div className="item">
              {JSON.parse(selectedProducts.tillData[0].storeDetail).storeManagerMailId}
            </div>
            <div className="item">
              {JSON.parse(selectedProducts.tillData[0].storeDetail).storePhoneNo}
            </div>
            <div className="item">
              {JSON.parse(selectedProducts.tillData[0].storeDetail).storeTime}
            </div>
            <div className="item">
              {terminalNumber}
            </div>
            <div className="item">
              Place of Supply:
            </div>
            <div className="item">
              {JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeAddressline1}
              {JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeAddressline2}
              {JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeCityName}
              {JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeRegion}
              {JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storePostalCode}
              {JSON.parse(selectedProducts.tillData[0].storeDetail).storeAddress.storeCountry}
            </div>
            <div className="item">
              GSTIN: {JSON.parse(selectedProducts.tillData[0].storeDetail).storeGstin}
            </div>
            <div className="item">
              FSSAI No: {JSON.parse(selectedProducts.tillData[0].storeDetail).fssaiNo}
            </div>
            <div className="item-container">
              <div className="sub-item">
                Date: {moment(orderDate).format("DD-MM-YYYY")}
              </div>
              <div className="sub-item-second">
                POS: {terminalNumber}
              </div>
            </div>
            <div className="item-container">
              <div className="sub-item">
                Cashier: {cashierData?.Username}
              </div>
              <div className="sub-item-second">
                Time: {moment(saleendtime).format("HH:mm:ss")}
              </div>
            </div>
            <div className="item">
              Bill: {terminalNumber}/{Number(orderId)}
            </div>
            <div className="item">
              DecathlonId: {!isEmpty(userData?.result?.CardNumber) ? userData?.result?.CardNumber : !isEmpty(selectedProducts?.customerData?.result?.CardNumber) ? selectedProducts?.customerData?.result?.CardNumber : ""}
            </div>
            <div className="item">
              Customer Name: {customerName}
            </div>
            <div className="item">
              Mobile No.: {!isEmpty(userData?.result?.Mobile)? userData?.result?.Mobile: customerPhoneNo}
            </div>
            <div className="item">
              Email: {!isEmpty(userData?.result?.Email)? userData?.result?.Email : customerEmail}
            </div>
            <div className="item-container">
              <div className="sub-item.content">
                Item-code-Description
              </div>
            </div>
            <div className="item-container">
              <div className="item-title">
                HSN
              </div>
              <div className="item-title">
                Qty
              </div>
              <div className="item-title">
                Price
              </div>
              <div className="item-title">
                Total
              </div>
            </div>
            <hr className="dash" />
            <div >
              {finalArray.map((item) => {
                if (!item.name.includes("Bag")) {
                  return <>
                    <div className="item-container">
                      <div className="sub-item.content">
                        {item.id} - {item.name}
                      </div>
                    </div>
                    <div className="item-container">
                      <div className="item-title">
                        {item.HSN_value}
                      </div>
                      <div className="item-title">
                        {item?.count}
                      </div>
                      <div className="item-title">
                        {Number(item.sale_price)}
                      </div>
                      <div className="item-title">
                        {(Number(item.sale_price) * item.count).toFixed(2)}
                      </div>
                    </div>
                    <div className="item-container" >
                      <div className="offername">
                        --{item?.discountName}
                      </div>
                      <div className="offeramount" >
                        &nbsp;{item.percentage !== 0 ? -((Number(item.sale_price) * item.count) - item.discountSalePrice).toFixed(2) : ""}
                      </div>
                    </div>
                    <br />
                  </>
                }
              })}
            </div>
            <hr className="dash" />
            <div className="item-container">
              <div className="item-total">
                Total Qty
              </div>
              <div className="item-total"></div>
              <div className="item-total">
                Grand Total
              </div>
            </div>
            <hr className="dash" />
            <div className="item-container">
              <div className="item-total">
                {isQty}
              </div>
              <div className="item-total"></div>
              <div className="item-total">
                INR {(isTotal - discount).toFixed(2)}
              </div>
            </div>
            <hr className="dash" />
            <div className="item-container">
              <div className="item-total">
                Mode of payment
              </div>
            </div>
            <hr className="dash" />
            {PaymentModeArray?.map((PaymentModeArrayData, index) => (
              <div key={index}>
                <div className="item-container">
                  <div className="item-total">
                    {PaymentModeArrayData.paymentMethod}
                  </div>
                  <div className="item-total"></div>
                </div>
                <div className="item-container">
                  <div className="item-name">
                    {PaymentModeArrayData.paymentRefId}
                  </div>
                  <div className="item-price">
                    {PaymentModeArrayData.totalamountpaid}
                  </div>
                </div>
              </div>
            ))}
            <div className="item-container">
              <div className="item-name">
                Change
              </div>
              <div className="item-price">
                INR 0.00
              </div>
            </div>
            <div className="item-container">
              <div className="item-title">
                Category
              </div>
              <div className="item-title">
                Rate
              </div>
              <div className="item-title">
                Base
              </div>
              <div className="item-title">
                Amt
              </div>
            </div>
            <br />
            <hr className="dash" />
            {taxArray.map((item) => {
              const gstArray = JSON.parse(item.gst);
              const sgst = gstArray.find((e) => e.name.includes('SGST'));
              const cgst = gstArray.find((e) => e.name.includes('CGST'));
              return (
                <>
                <div className="item-container">
                  <div className="item-title">
                    {sgst.name}
                  </div>
                  <div className="item-title">
                    {(sgst.rate).toFixed(2)}%
                  </div>
                  <div className="item-title">
                    {item.basePrice.toFixed(2)}
                  </div>
                  <div className="item-title">
                    {(Math.floor(item.icgst * 100) / 100).toFixed(2)}
                  </div>
                </div>
                <div className="item-container"> 
                <div className="item-title">
                   {cgst.name}
                 </div>
                 <div className="item-title">
                   {(cgst.rate).toFixed(2)}%
                 </div>
                 <div className="item-title">
                   {item.basePrice.toFixed(2)}
                 </div>
                 <div className="item-title">
                   {(Math.floor(item.isgst * 100) / 100).toFixed(2)}
                 </div>
                </div>
                </>
              );
            })}
            <hr className="dash" />
            <div className="item-container">
              <div className="item-total">
                Total
              </div>
              <div className="item-total"></div>
              <div className="item-total">
                {isTotal}
              </div>
            </div>

            <div className="barcode-container" >
              <div className="bar-code-item" >
                <Barcode value={`${terminalNumber}/${orderId}`} height={"60px"} width={1.3} font={"14px"} />
              </div>
            </div>
            <div className="item">
              This sale is subjected to the Terms and Conditions, Return Policies, Warranty Policies as mentioned on www.decathlon.in. Scan the QR code below for more information.
            </div>
            <div className="item">
              <img src={Qrcode} alt="" width={225} height={200} />
            </div>
            <div className="item">
              Decathlon Sports India Pvt Ltd
            </div>
            <div className="item">
              Address: Survey No. 78/10 A20-Chikkajala Village, Bellary Road,
              Bengaluru-562157 C:N: U72200KA2004PTC033858
              +917676798989/ Fax: 08028467111
            </div>
            <div className="item">
              The products purchased are not intended for resale,
              therefore the customer will not be eligible for GST
              credit.
            </div>
            <div className="item">
              Visit us at wwww.decathlon.in to know more
              about us and terms of this sale
            </div>
            <div className="item">
              Kindly note that it is system generated document
              and hence does not require any signature.
            </div>

          </div>
        </div>
      </>
    )
  };

  return (
    <div>
      {isVisibleFlag ?
        <>
          <div className="header-block">
            <Row>
              <Col span={1} >
              </Col>
              <Col span={6} style={{ alignSelf: "center", position: "relative", left: "-2%" }}>
                <p className="user-name">Hey {username}</p>
              </Col>
              <Col span={9} style={{ position: "relative", left: "2.6%" }}>
              </Col>
              <Col span={8} className="user-details">
                <img src={Logo} alt="" style={{ height: "50px" }} />
              </Col>
            </Row>
          </div>
          <div className="payment-method-block">
            <img src={Penguine} alt="" width={290} height={290} />
            <p style={{ fontSize: "30px", lineHeight: '35px', fontWeight: 600 }}>
              Thank you for choosing Decathlon to be a part of your sports journey. We look forward to seeing you again soon !
            </p>
          </div>
        </>
        :
        <PrintReceipt />
      }
    </div>
  );
};

export default Thankyou;
